<!-- 电力运行数据 -->
<template>
  <div id="energy-report">
    <div class="tree">
      <div class="tree-title">组织结构</div>
      <el-input placeholder="输入检索内容" suffix-icon="el-icon-search" v-model.trim="filterText"></el-input>
      <el-tree ref="tree" v-loading="loading" :default-expand-all="false" :show-checkbox="false" :data="treeData"
               :check-on-click-node="false" :props="defaultProps" node-key="id" :filter-node-method="filterNode"
               :default-expanded-keys="treeChildren"
               @node-click="handleCheck"></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt=""/>
        <span>电力运行数据</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="日期" class="query-date" label-width="40px">
              <el-date-picker
                  value-format="yyyy-MM-dd"
                  @change="timeChange()"
                  :picker-options="pickerOptions"
                  :append-to-body="false"
                  v-model="formData.time"
                  type="daterange"
                  :clearable="false"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="配电室" label-width="50px">
              <el-select filterable v-model="stationNumberList" :popper-append-to-body="false"
                         value-key="stationNumber"
                         @change="selectStation($event)"
                         placeholder="请选择配电室">
                <el-option v-for="item in stationData" :key="item.id" :label="item.stationName"
                           :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="回路" label-width="40px">
              <el-select v-model="formData.loopName" filterable :popper-append-to-body="false" placeholder="请选择">
                <el-option
                    v-for="item in optionsH"
                    :key="item.id"
                    :label="item.loopName"
                    :value="item.id">
                </el-option>
              </el-select>
<!--              <el-input v-model.trim="formData.loopName" placeholder="请输入回路名称"></el-input>-->
            </el-form-item>
            <el-form-item label="间隔" label-width="40px">
              <el-select v-model="formData.interval" filterable :popper-append-to-body="false" placeholder="请选择">
                <el-option
                    v-for="item in optionsInterval"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getEnergyList" icon="el-icon-search">查询</el-button>
              <el-button type="primary" @click="getExport">导出</el-button>
            </el-form-item>
          </el-form>
        </div>

        <el-table class="table" :data="tableData" ref="unbb" height="566px" v-loading='dataLoading'
                  element-loading-text="加载中"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.3)"
                  @selection-change="handleSelectionChange">
<!--          <el-table-column type="selection" width="50"></el-table-column>-->

          <el-table-column prop="ts" label="时间" min-width="250" align="center"></el-table-column>
          <el-table-column prop="loopName" label="回路名称" min-width="250" align="center"></el-table-column>
          <el-table-column prop="ua" label="A相电压" min-width="250" align="center"></el-table-column>
          <el-table-column prop="ub" label="B相电压" min-width="250" align="center"></el-table-column>
          <el-table-column prop="uc" label="C相电压" min-width="250" align="center"></el-table-column>
          <el-table-column prop="ia" label="A相电流" min-width="250" align="center"></el-table-column>
          <el-table-column prop="ib" label="B相电流" min-width="250" align="center"></el-table-column>
          <el-table-column prop="ic" label="C相电流" min-width="250" align="center"></el-table-column>
          <el-table-column prop="pe" label="总有功功率" min-width="250" align="center"></el-table-column>
          <el-table-column prop="qe" label="总无功功率" min-width="250" align="center"></el-table-column>
          <el-table-column prop="cosqE" label="总功率因数" min-width="250" align="center"></el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 折线图 -->
    <el-dialog :title="operation === 0 ? '用能折线图' : '用能饼图'" :visible.sync="chartDialog" width="45%"
               destroy-on-close>
      <div id="chart"></div>
    </el-dialog>
    <el-dialog title="导出" :visible.sync="exportDialog" width="20%">
      <span class="dialog-content">确定要导出数据吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialog = false">取 消</el-button>
        <el-button type="primary" @click="exportDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";
import {emissionsCarbonApi} from '@/api/emissionsCarbon.js'
import {nextTick} from "vue";
import {deviceApi} from "@/api/device";
import moment from "moment";
import {energyStationApi} from "@/api/energyStation";
import download from "@/utils/download";

export default {
  name: "",

  data() {
    return {
      treeChildren: [],
      loading: false,
      defaultProps: {
        children: "companyInfoList",
        id: "id",
        label: "companyName",
      },
      filterText: '',
      treeData: [],
      checkedNodes: [],
      valueP: '',
      stationData: [],
      valueH: '',
      optionsH: [],
      optionsInterval: [
        {label: '全部',value: -1},
        {label: '1小时',value: 1},
        {label: '2小时',value: 2},
      ],
      tableSelectData: [],
      pickerOptions: {
        onPick: obj => {
          this.pickTimeSave = new Date(obj.minDate).getTime()
          const start = obj.minDate
          const end = obj.maxDate
          obj.maxDate && (this.formData.time = [start, end])
        },
        disabledDate: time => {
          if (this.pickTimeSave) {
            let min = moment(this.pickTimeSave).subtract(1, 'months').format('x')
            let max = moment(this.pickTimeSave).clone().add(1, 'months').format('x')
            return Number(min) > Number(time.getTime()) || Number(time.getTime()) > Number(max)
          }
        }
      },
      pickTimeSave: '',
      formData: {
        time: "",
        loopName: '',
        companyInfoId: '',
        stationNumber: '',
        id: '',
        interval: 1
      },
      stationNumberList: [],
      dataLoading: false,
      operation: 1,
      chartDialog: false,
      exportDialog: false,
      tableData: [],
      select: 0,
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      timeType: 'date',
      staTime: '',
      endTime: '',
      stationNumber: '',
      loopIds: []
    };
  },
  created() {
    this.$store.commit('increment', '统计分析')
    this.$store.commit('selectChild', '电力运行数据')
    this.$store.commit("selectChildren", "");
    let start = moment().startOf('month').format('YYYY-MM-DD');
    let end = moment().endOf('month').format('YYYY-MM-DD');
    this.formData.time = [start, end];

  },
// updated(){
//   this.$refs['unbb'].doLayout()
// },
  mounted() {
    this.queryUnitName()
  },

  methods: {
    selectStation(val) {
      if (val) {
        this.formData.stationNumber = val.stationNumber
        this.formData.stationName = val.stationName
        this.getQueryHL(val.id)
      } else {
        this.formData.stationNumber = ''
        this.formData.id = ''
        this.formData.stationName = ''
        this.getQueryHL(this.formData.id)
      }
    },
    getExport() {
      if (this.formData.time == null || this.formData.time == '' || this.formData.time == undefined) {
        var obj = {
          stationNumber: this.formData.stationNumber,
          companyInfoId: this.formData.companyInfoId,
          loopId: this.formData.loopName,
          interval: this.formData.interval,
          actionDate: '',
          endDate: '',
          loopIds: this.loopIds.join(',')
        }
      } else {
        var obj = {
          stationNumber: this.formData.stationNumber,
          companyInfoId: this.formData.companyInfoId,
          loopId: this.formData.loopName,
          actionDate: this.formData.time[0],
          interval: this.formData.interval,
          endDate: this.formData.time[1],
          loopIds: this.loopIds.join(',')
        }
      }
      emissionsCarbonApi.powerRunExport(obj).then(res => {
        let name = '电力运行数据' + moment().format('YYYY-MM-DD') + this.formData.stationName
        download.excel(res, name)
        this.$message({
          message: '导出成功',
          type: 'success',
          duration: 3000,
          customClass: "messageText",
        });
      })
    },
    timeChange() {
      console.log(this.formData.time, 'aaaaa')
    },
    queryStation(companyId) {
      energyStationApi.queryStation({companyId: companyId}).then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
          if (this.stationData.length != 0) {
            this.stationNumberList = this.stationData[0];
            this.formData.stationNumber = this.stationNumberList.stationNumber
            this.formData.stationName = this.stationNumberList.stationName
            this.getQueryHL(this.stationNumberList.id)
          } else {
            this.stationNumberList = {}
            this.formData.stationNumber = ''
            this.formData.id = ''
            this.formData.stationName = ''
            this.getQueryHL(this.formData.id)
          }
        }
      });
    },
    getQueryHL(id) {
      this.optionsH = []
      const param = {
        stationId: id
      }
      energyStationApi.queryHL(param).then(res => {
        this.optionsH = res.data
        if (this.optionsH.length != 0) {
          this.formData.loopName = this.optionsH[0].id
          this.getEnergyList()
        }else {
          this.formData.loopName = ''
          this.getEnergyList()
        }
      })
    },
    queryUnitName() {
      this.loading = true;
      deviceApi.queryUnitName().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.treeData = res.data;
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.treeData[0].companyInfoList[0].id); // 默认选中节点第一个
            this.treeChildren = [this.treeData[0].id]
            this.formData.companyInfoId = this.treeData[0].companyInfoList[0].id
            this.queryStation(this.formData.companyInfoId)
          });
        }
      });
    },
    handleCheck(checkedNodes, event) {
      this.formData.stationNumber = ''
      if (this.checkedNodes.id == checkedNodes.id) {
        this.checkedNodes = ''
        this.formData.loopName = ''
        this.formData.companyInfoId = checkedNodes.id
        this.queryStation(this.formData.companyInfoId)
      } else {
        this.formData.loopName = ''
        this.formData.companyInfoId = checkedNodes.id
        this.checkedNodes = checkedNodes;
        this.queryStation(this.formData.companyInfoId)
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },
    selectChange(val) {
      console.log(val)
      this.valueH = ''
      this.optionsH = []
      if (val == '1') {
        this.optionsH = [
          {value: '1', label: '真的'},
          {value: '2', label: '假的'}
        ]
      } else if (val == '2') {
        this.optionsH = [
          {value: '1', label: '不是真的'},
          {value: '2', label: '不是假的'}
        ]
      }
    },
    handleSelectionChange(val) {
      this.loopIds = []
      this.tableSelectData = []
      this.tableSelectData = val;
      this.tableSelectData.forEach(item => {
        this.loopIds.push(item.loopId);
      })
    },
    search() {
      console.log(this.formData);
    },
    getEnergyList() {
      if (this.formData.time == null || this.formData.time == '' || this.formData.time == undefined) {
        var obj = {
          stationNumber: this.formData.stationNumber,
          companyInfoId: this.formData.companyInfoId,
          loopId: this.formData.loopName,
          interval: this.formData.interval,
          actionDate: '',
          endDate: '',
        }
      } else {
        var obj = {
          stationNumber: this.formData.stationNumber,
          companyInfoId: this.formData.companyInfoId,
          loopId: this.formData.loopName,
          interval: this.formData.interval,
          actionDate: this.formData.time[0],
          endDate: this.formData.time[1],
        }
      }
      this.dataLoading = true
      emissionsCarbonApi.selectPowerRunList(obj).then(res => {
        if (res.code == 200) {
          this.tableData = res.data
        }
        this.$nextTick(() => {
          // 在这里执行渲染完成后的代码
          this.dataLoading = false
        });
      })
    },
    viewBar() {
      this.operation = 0;
      this.chartDialog = true;
      this.$nextTick(() => {
        this.barChart();
      });
    },
    viewPie() {
      this.operation = 1;
      this.chartDialog = true;
      this.$nextTick(() => {
        this.pieChart();
      });
    },
    barChart() {
      var chart = echarts.init(document.getElementById("chart"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {},
        legend: {
          show: true,
          bottom: "1%",
          left: "center",
        },
        color: ["#14bf9b", "#e0ab34", "#176ad2", "#9875e3"],
        xAxis: {
          data: [
            "1时",
            "2时",
            "3时",
            "4时",
            "5时",
            "6时",
            "7时",
            "8时",
            "9时",
            "10时",
            "11时",
            "12时",
          ],
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
              fontSize: 12,
            },
          },
        },
        yAxis: {
          name: "单位:km/h",
          nameTextStyle: {
            color: "#aed6ff",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "分类1",
            type: "bar",
            barWidth: 10,
            data: [10, 52, 200, 334, 390, 330, 220],
          },
          {
            name: "分类2",
            type: "bar",
            barWidth: 10,
            data: [10, 52, 200, 334, 390, 330, 220],
          },
          {
            name: "分类3",
            type: "bar",
            barWidth: 10,
            data: [10, 52, 200, 334, 390, 330, 220],
          },
          {
            name: "分类4",
            type: "bar",
            barWidth: 10,
            data: [10, 52, 200, 334, 390, 330, 220],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      chart.setOption(option);
    },
    pieChart() {
      var chart = echarts.init(document.getElementById("chart"));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: "饼图",
          left: "center",
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "50%",
            data: [
              {value: 1048, name: "分类1"},
              {value: 735, name: "分类2"},
              {value: 580, name: "分类3"},
              {value: 484, name: "分类4"},
            ],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      chart.setOption(option);
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 240px;
  background-color: #061b45;
  color: #aed6ff;
  border: 1px solid #09295b;
}

::v-deep .el-date-editor .el-range-input {
  background-color: #061b45;
}

::v-deep .el-date-editor .el-range-separator {
  color: #aed6ff;
  width: 10%;
}

::v-deep .el-date-table td.in-range div, .el-date-table td.in-range div:hover, .el-date-table.is-week-mode .el-date-table__row.current div, .el-date-table.is-week-mode .el-date-table__row:hover div {
  background-color: transparent;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#energy-report {
  width: 100%;
  min-height: 760px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
  height: calc(100vh - 170px);
}

/* tree */
.tree :deep() .el-tree {
  //width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  //padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #03183f;
}

.main {
  width: 84%;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* tabs */
.tabs {
  /* width: 1336px; */
  background-color: rgb(4, 25, 66);
  display: flex;
}

.tabs li {
  width: 85px;
  height: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  line-height: 40px;
}

.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}

.tip {
  color: #e8f4ff;
  font-size: 12px;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  margin: 18px 0 20px 0;
}

.query-form :deep() .el-form {
  height: 40px;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0;
  margin-right: 8px;
}

.hour :deep() .el-select {
  width: 70px;
  padding: 0;
}

.hour span {
  color: #aed6ff;
  margin: 0 5px 0 8px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
  width: 100%;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

#chart {
  width: 768px;
  height: 450px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 100px;
  height: 35px;
  background: #2142a1;
  border: 0;
  color: #fff;
}

.dialog-content {
  font-size: 16px;
  color: #ebedf1;
}

/* 更改 el-table 横向滚动条的样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

</style>

