<!--环境监测-->
<template>
  <div id="monitor">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt=""/>
        <span>环境监测</span>
      </div>
      <div class="content">
        <div class="flex-horizontal-between">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <img src="../../assets/images/environment/icon.png" alt=""/>
                <span>水浸</span>
              </div>
              <!-- <div class="card-actions" @click="viewDialog = true">更多</div> -->
            </div>
            <div class="card-content">
              <ul class="info">
                <li v-if="Object.keys(waterImmersion).length === 0">
                  <p>暂无数据</p>
                </li>
                <div v-else>
                  <li v-for="(key, value) in waterImmersion" :key="value">
                    <img src="../../assets/images/environment/water-icon.png" alt=""/>
                    <p>{{ value }}:{{ key }}</p>
                  </li>
                </div>
              </ul>
              <img src="../../assets/images/environment/house.png" alt="" class="picture"/>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <img src="../../assets/images/environment/icon.png" alt=""/>
                <span>烟感</span>
              </div>
              <!-- <div class="card-actions">更多</div> -->
            </div>
            <div class="card-content">
              <ul class="info">
                <li v-if="Object.keys(smokeDetector).length === 0">
                  <p>暂无数据</p>
                </li>
                <div v-else>
                  <li v-for="(key, value) in smokeDetector" :key="value">
                    <img src="../../assets/images/environment/fire-icon.png" alt=""/>
                    <p>{{ value }}:{{ key }}</p>
                  </li>
                </div>
              </ul>
              <img src="../../assets/images/environment/smoke.png" alt="" class="picture"/>
            </div>
          </div>
          <div style="margin-right: 0" class="card">
            <div class="card-header">
              <div class="card-title">
                <img src="../../assets/images/environment/icon.png" alt=""/>
                <span>门禁</span>
              </div>
              <!-- <div class="card-actions">更多</div> -->
            </div>
            <div class="card-content">
              <ul class="info">
                <li v-if="Object.keys(entranceGuard).length === 0">
                  <p>暂无数据</p>
                </li>
                <div v-else>
                  <li v-for="(key, value) in entranceGuard" :key="value">
                    <img src="../../assets/images/environment/water-icon.png" alt=""/>
                    <p>{{ value }}:{{ key }}</p>
                  </li>
                </div>
              </ul>
              <img src="../../assets/images/environment/door.png" alt="" class="picture"/>
            </div>
          </div>
        </div>
        <div class="humidity-card">
          <div class="card-header">
            <div class="card-title">
              <img src="../../assets/images/environment/icon.png" alt=""/>
              <span>温湿度</span>
            </div>
          </div>
          <div class="humidity-content">
            <div class="dashboard">
              <div class="dashboard-header">
                <p>温湿度</p>
                <div class="dashboard-actions">
                  <span class="tip">（当日实时数据）</span>
                  <!-- <div class="card-actions">更多</div> -->
                </div>
              </div>
              <div class="dashboard-chart">
                <div id="temperature"></div>
                <div id="humidity"></div>
              </div>
            </div>
            <div class="interval"></div>
            <div class="line">
              <div class="line-header">
                <el-form class="form" ref="form" :inline="true" :model="formData">
                  <!-- <el-form-item label="仪表名称:">
                    <el-select v-model="formData.region">
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-form-item> -->
                  <el-form-item label="时间" class="query-date">
                    <el-date-picker :append-to-body="false" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
                                    v-model="formData.humidityDate" :clearable="false"
                                    @change="handleDateChange"></el-date-picker>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" icon="el-icon-arrow-left" @click="yesterdayHumidity">上一日</el-button>
                    <el-button type="primary" @click="tomorrowHumidity">下一日<i
                        class="el-icon-arrow-right el-icon--right"></i></el-button>
                  </el-form-item>
                </el-form>
                <span class="tip">（当日实时数据）</span>
              </div>
              <div class="line-chart">
                <div id="temperatureLine"></div>
                <div id="humidityLine"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="noise-card">
          <ul class="tabs">
            <li v-for="(item, index) in tabsData" :key="index" :class="select === item.id ? 'select' : ''"
                @click="switchTabs(item)">
              {{ item.name }}
            </li>
          </ul>
          <div class="noise-content">
            <div>
              <div class="noise-header">
                <p>
                  {{
                    select == 1
                        ? "噪声"
                        : select == 2
                            ? "局部放电"
                            : select == 3
                                ? "红外"
                                : "SF6气体监测"
                  }}
                </p>
                <span class="tip">（当日实时数据）</span>
              </div>
              <div class="noise-picture">
                <img v-show="select == 1" src="../../assets/images/environment/noise.png" alt=""/>
                <img v-show="select == 2" src="../../assets/images/environment/electric.png" alt=""/>
                <img v-show="select == 3" src="../../assets/images/environment/red.png" alt=""/>
                <img v-show="select == 4" src="../../assets/images/environment/SF6.png" alt=""/>
                <span v-show="select == 1">噪声:{{ realTime.noise }}dB</span>
                <span v-show="select == 2">局部放电{{ realTime.localAmplifier }}C</span>
                <span v-show="select == 3">红外{{ realTime.infrared }}THz</span>
                <span v-show="select == 4">SF6气体监测 {{ realTime.sf6GasDetection }}ppm</span>
              </div>
            </div>
            <div class="interval"></div>
            <div class="line">
              <div class="line-header">
                <el-form class="form" ref="form" :inline="true" :model="formData">
                  <!-- <el-form-item label="仪表名称:">
                    <el-select v-model="formData.region">
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-form-item> -->
                  <el-form-item label="时间" class="query-date">
                    <el-date-picker :append-to-body="false" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
                                    v-model="formData.noiseDate" :clearable="false"
                                    @change="noiseDateChange"></el-date-picker>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" icon="el-icon-arrow-left" @click="yesterdayNoise">上一日</el-button>
                    <el-button type="primary" @click="tomorrowNoise">下一日<i
                        class="el-icon-arrow-right el-icon--right"></i></el-button>
                  </el-form-item>
                </el-form>
                <span class="tip">（历史数据）</span>
              </div>
              <div id="lineChart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="viewDialog" width="751px">
      <el-table class="table" :data="tableData">
        <el-table-column align="center" prop="id" label="序号">
        </el-table-column>
        <el-table-column align="center" prop="name" label="编号">
        </el-table-column>
        <el-table-column align="center" prop="zero" label="名称">
        </el-table-column>
        <el-table-column align="center" prop="one" label="状态">
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">共4条</div>
    </el-dialog>
  </div>
</template>
<script>
import {environmentMonitorApi} from "@/api/environmentMonitor.js";
import * as echarts from "echarts";
import {popup} from "leaflet";

export default {
  name: "",

  data() {
    return {
      formData: {
        humidityDate: "",
        noiseDate: "",
      },
      tabsData: [
        {
          id: 1,
          name: "噪声",
        },
        {
          id: 2,
          name: "局部放电",
        },
        {
          id: 3,
          name: "红外",
        },
        {
          id: 4,
          name: "SF6气体监测",
        },
      ],
      select: 1,
      viewDialog: false,
      tableData: [
        {
          id: 1,
          name: "仪表名称",
          zero: "越线",
          one: "95.5",
        },
      ],
      stationNumber: "",
      waterImmersion: {},
      smokeDetector: {},
      entranceGuard: {},
      realTime: {},
      selectName: '噪声'
    };
  },
  created() {
    this.$store.commit("increment", "运行环境");
    this.$store.commit("selectChild", "环境监测");
    this.$store.commit("selectChildren", "");
    this.stationNumber = sessionStorage.getItem("stationNumber");
    this.getDate();
  },
  mounted() {
    this.queryEnvironmentHumidity();
    this.queryEnvironmentTemperature();
    this.queryTemperatureHumidity();
    this.queryEnvironmentMonitor();
    this.queryEnvironmentNoise();
    this.timeData()
    // this.humidityChart();
    // this.temperatureLine();
    // this.drawChart();
  },

  methods: {
    switchTabs(item) {
      this.select = item.id;
      this.selectName = item.name;
      this.queryEnvironmentNoise();
      this.timeData()
    },
    timeData() {
      let obj = {
        stationNumber: this.stationNumber,
        type: this.select
      }
      // obj.stationNumber='ZD1150-01'
      environmentMonitorApi.timeData(obj).then(res => {
        console.log(res);
        this.realTime = res.data
        console.log(this.realTime);
      })
    },
    handleDateChange(data) {
      // console.log(data,this.formData.humidityDate);
      this.queryEnvironmentHumidity();
      this.queryEnvironmentTemperature();
    },
    tomorrowHumidity() {
      const currentDay = new Date(this.formData.humidityDate);
      // 将日期向后推一天
      currentDay.setDate(currentDay.getDate() + 1);
      // 更新选中日期并格式化为 yyyy-mm-dd 格式
      this.formData.humidityDate = this.formatDate(currentDay);
      this.queryEnvironmentHumidity();
      this.queryEnvironmentTemperature();
    },
    yesterdayHumidity() {
      const currentDay = new Date(this.formData.humidityDate);
      // 将日期向前推一天
      currentDay.setDate(currentDay.getDate() - 1);
      this.formData.humidityDate = this.formatDate(currentDay);
      this.queryEnvironmentHumidity();
      this.queryEnvironmentTemperature();
    },
    noiseDateChange(data) {
      // console.log(data,this.formData.noiseDate);
      this.queryEnvironmentNoise();
    },

    tomorrowNoise() {

      const currentDay = new Date(this.formData.noiseDate);
      // 将日期向后推一天
      currentDay.setDate(currentDay.getDate() + 1);
      // 更新选中日期并格式化为 yyyy-mm-dd 格式
      this.formData.noiseDate = this.formatDate(currentDay);
      this.queryEnvironmentNoise();
    },
    yesterdayNoise() {

      const currentDay = new Date(this.formData.noiseDate);
      // 将日期向后推一天
      currentDay.setDate(currentDay.getDate() - 1);
      // 更新选中日期并格式化为 yyyy-mm-dd 格式
      this.formData.noiseDate = this.formatDate(currentDay);
      this.queryEnvironmentNoise();
    },
    formatDate(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;

      return year + '-' + month + '-' + day;
    },
    // 获取当年
    getDate() {
      let date = new Date(); // 获取当前时间
      const year = date.getFullYear(); // 获取当前年份
      const currentMonth = date.getMonth() + 1; // 获取当前月份
      const currentDay = date.getDate(); //获取当前日
      let month = currentMonth < 10 ? `0${currentMonth}` : currentMonth; // 获取当前月份
      let day = currentDay < 10 ? `0${currentDay}` : currentDay; //获取当前日

      this.formData.humidityDate = `${year}-${month}-${day}`;
      this.formData.noiseDate = `${year}-${month}-${day}`;
    },
    //温湿度
    queryTemperatureHumidity() {
      let params = {
        stationNumber: this.stationNumber,
      };
      environmentMonitorApi.queryTemperatureHumidity(params).then((res) => {
        if (res.code === 200 && res.data) {
          let object = {};
          object.humidity = res.data.humidity ? res.data.humidity : 0;
          object.temp = res.data.temp ? res.data.temp : 0;
          this.temperatureChart(object.temp);
          this.humidityChart(object.humidity);
        } else {
          this.temperatureChart(0);
          this.humidityChart(0);
        }
      });
    },
    //温度
    queryEnvironmentHumidity() {
      let params = {
        stationNumber: this.stationNumber,
        time: this.formData.humidityDate,
      };
      environmentMonitorApi.queryEnvironmentHumidity(params).then((res) => {
        if (res.code === 200 && res.data != '暂无数据') {
          const xAxisData = res.data.map((item) => {
            let time = item.nowTime.slice(11, 16);

            return time;
          });
          const seriesData = res.data.map((item) => {
            return item.humidity;
          });
          let max = []
          res.data.forEach((item) => {
            this.$set(item, 'max', '50')
            max.push(item.max)
          })
          this.humidityLine(xAxisData, seriesData, max);
        }
      });
    },
    //温度
    queryEnvironmentTemperature() {
      let params = {
        stationNumber: this.stationNumber,
        time: this.formData.humidityDate,
      };
      environmentMonitorApi.queryEnvironmentTemperature(params).then((res) => {
        if (res.code === 200 && res.data != '暂无数据') {
          const xAxisData = res.data.map((item) => {
            let time = item.nowTime.slice(11, 16);
            return time;
          });
          const seriesData = res.data.map((item) => {
            return item.temp;
          });
          let max = []
          res.data.forEach((item) => {
            this.$set(item, 'max', '40')
            max.push(item.max)
          })
          this.temperatureLine(xAxisData, seriesData, max);
        }
      });
    },
    //环境检测
    queryEnvironmentMonitor() {
      let params = {
        stationNumber: this.stationNumber,
      };
      environmentMonitorApi.queryEnvironmentMonitor(params).then((res) => {
        if (res.code === 200) {
          this.waterImmersion = res.data[0];
          this.smokeDetector = res.data[1];
          this.entranceGuard = res.data[2];
        }
      });
    },
    //噪声、局放、红外、sf6气体检测
    queryEnvironmentNoise() {
      let params = {
        stationNumber: this.stationNumber,
        time: this.formData.noiseDate,
        type: this.select,
      };
      environmentMonitorApi.queryEnvironmentNoise(params).then((res) => {
        if (res.code === 200 && res.data) {
          let xAxisData = res.data.map((item) => {
            let time = item.nowTime.slice(11, 16);

            return time;
          });
          let seriesData = res.data.map((item) => {
            let value;
            if (this.select === 1) {
              value = item.noise;
            }
            if (this.select === 2) {
              value = item.localAmplifier;
            }
            if (this.select === 3) {
              value = item.infrared;
            }
            if (this.select === 4) {
              value = item.sf6GasDetection;
            }
            return value;
          });
          let max = []
          res.data.forEach((item) => {
            if (this.select === 1) {
              this.$set(item, 'max', '50')
              max.push(item.max)
            }else if (this.select === 4) {
              this.$set(item, 'max', '800')
              max.push(item.max)
            }
          })

          this.drawChart(xAxisData, seriesData,max);
        }
      });
    },
    temperatureChart(value) {
      var temperature = echarts.init(document.getElementById("temperature"));
      // 指定图表的配置项和数据
      var option = {
        series: [
          {
            name: "Pressure",
            type: "gauge",
            title: {
              offsetCenter: [0, "95%"],
              fontSize: 14,
              color: "#4b90e6",
            },
            detail: {
              formatter: function (value) {
                // 返回需要显示的内容，可根据需要自行调整
                return `${value}℃`;
              },
              offsetCenter: ["0%", "70%"],
              textStyle: {
                color: "#ffc74a", // 文字颜色
                fontSize: 14, // 文字大小
              },
            },
            axisLabel: {
              textStyle: {
                color: "#fff", // 文字颜色
              },
            },
            data: [
              {
                value: value,
                name: "温度",
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      temperature.setOption(option);
    },
    humidityChart(value) {
      var humidity = echarts.init(document.getElementById("humidity"));
      // 指定图表的配置项和数据
      var option = {
        series: [
          {
            name: "Pressure",
            type: "gauge",
            title: {
              offsetCenter: [0, "95%"],
              fontSize: 14,
              color: "#4b90e6",
            },
            detail: {
              formatter: function (value) {
                // 返回需要显示的内容，可根据需要自行调整
                return `${value}%`;
              },
              offsetCenter: ["0%", "70%"],
              textStyle: {
                color: "#ffc74a", // 文字颜色
                fontSize: 14, // 文字大小
              },
            },
            axisLabel: {
              textStyle: {
                color: "#fff", // 文字颜色
              },
            },
            data: [
              {
                value: value,
                name: "湿度",
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      humidity.setOption(option);
    },
    // 温度
    temperatureLine(xAxisData, seriesData, max) {
      var temperatureLine = echarts.init(
          document.getElementById("temperatureLine")
      );
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: "温度",
          textStyle: {
            fontSize: 16,
            color: "#aed6ff",
          },
          left: "center",
          top: "5%",
        },
        grid: {
          bottom: 35,
        },
        legend: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
        },
        color: ["#2fc9cb"],
        xAxis: {
          data: xAxisData,
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
              fontSize: 10,
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false, //是否显示轴线
          },
        },
        yAxis: {
          name: "单位:℃",
          nameTextStyle: {
            color: "#aed6ff",
            fontSize: 12,
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
              fontSize: 10,
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "温度",
            type: "line",
            data: seriesData,
            showSymbol: true,
            symbol: "circle", // 设置为圆形空心
            symbolSize: 6,
            itemStyle: {
              borderColor: "#2fc9cb", // 设置空心圆边框颜色
              color: "#071a40", // 可选，设置空心圆的填充颜色
            },
            lineStyle: {
              width: 1,
              color: "#2fc9cb",
            },
            markLine: {
              data: [
                {
                  type: "min",
                  name: "min",
                  lineStyle: {
                    color: "#2fc9cb",
                    type: "dashed",
                  },
                  label: {
                    position: "insideEndTop",
                    formatter: "{b}: {c}",
                    color: "#aed6ff",
                    fontSize: 10,
                  },
                },
              ],
            },
          },
          {
            name: '上限',
            type: "line",
            data: max,
          }
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      temperatureLine.setOption(option);
    },
    // 湿度
    humidityLine(xAxisData, seriesData, max) {
      var humidityLine = echarts.init(document.getElementById("humidityLine"));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: "湿度",
          textStyle: {
            fontSize: 16,
            color: "#aed6ff",
          },
          left: "center",
          top: "5%",
        },
        legend: {
          show: false,
        },

        tooltip: {
          trigger: "axis",
        },
        grid: {
          bottom: 35,
        },
        color: ["#2fc9cb"],
        xAxis: {
          data: xAxisData,
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
              fontSize: 10,
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false, //是否显示轴线
          },
        },
        yAxis: {
          name: "单位:%",
          nameTextStyle: {
            color: "#aed6ff",
            fontSize: 12,
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
              fontSize: 10,
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "湿度",
            type: "line",
            data: seriesData,
            showSymbol: true,
            symbol: "circle", // 设置为圆形空心
            symbolSize: 6,
            itemStyle: {
              borderColor: "#176ad2", // 设置空心圆边框颜色
              color: "#071a40", // 可选，设置空心圆的填充颜色
            },
            lineStyle: {
              width: 1,
              color: "#176ad2",
            },
            markLine: {
              data: [
                {
                  type: "max",
                  name: "max",
                  lineStyle: {
                    color: "#176ad2",
                    type: "dashed",
                  },
                  label: {
                    position: "insideEndTop",
                    formatter: "{b}: {c}",
                    color: "#aed6ff",
                    fontSize: 10,
                  },
                },
              ],
            },
          },
          {
            name: "上限",
            type: "line",
            data: max,
          }
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      humidityLine.setOption(option);
    },
    drawChart(xAxisData, seriesData,max) {
      var lineChart = echarts.init(document.getElementById("lineChart"));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text:
              this.select == 1
                  ? "噪声"
                  : this.select == 2
                      ? "局部放电"
                      : this.select == 3
                          ? "红外"
                          : "SF6气体监测",
          textStyle: {
            fontSize: 16,
            color: "#aed6ff",
          },
          left: "center",
          top: "5%",
        },
        grid: {
          bottom: 35,
        },
        legend: {
          show: false,
          '上限': this.select != 2 || this.select != 2
        },
        tooltip: {
          trigger: "axis",
        },
        color: ["#2fc9cb"],
        xAxis: {
          data: xAxisData,
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
              fontSize: 10,
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false, //是否显示轴线
          },
        },
        yAxis: {
          // name: "单位:dB",
          name: this.select == 1 ? '单位:dB' : this.select == 2 ? '单位:C' : this.select == 3 ? '单位:THz' : '单位:ppm',
          nameTextStyle: {
            color: "#aed6ff",
            fontSize: 12,
          },
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
              fontSize: 10,
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: this.selectName,
            type: "line",
            data: seriesData,
            showSymbol: true,
            symbol: "circle", // 设置为圆形空心
            symbolSize: 6,
            itemStyle: {
              borderColor: "#2fc9cb", // 设置空心圆边框颜色
              color: "#071a40", // 可选，设置空心圆的填充颜色
            },
            lineStyle: {
              width: 1,
              color: "#2fc9cb",
            },
            markLine: {
              data: [
                {
                  type: "max",
                  name: "max",
                  lineStyle: {
                    color: "#2fc9cb",
                    type: "dashed",
                  },
                  label: {
                    position: "insideEndTop",
                    formatter: "{b}: {c}",
                    color: "#aed6ff",
                    fontSize: 10,
                  },
                },
              ],
            },
          },
          {
            name: '上限',
            type: "line",
            data: max,
          }
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      lineChart.setOption(option);
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

#monitor {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 24px 25px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

.card {
  width: 32%;
  border-radius: 3px;
  border: 1px solid #0d2247;
  background-color: rgba(6, 28, 69, 0.75);
  margin-right: 20px;
}

/* 卡片头部 */
.card-header {
  width: 100%;
  height: 45px;
  border-radius: 3px;
  border: 1px solid #0d2247;
  background-color: #032e6f;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-title {
  display: flex;
  align-items: center;
}

.card-title img {
  width: 12px;
  height: 12px;
}

.card-title span {
  font-size: 14px;
  color: #e8f4ff;
  margin-left: 8px;
}

.card-actions {
  width: 45px;
  height: 25px;
  border-radius: 2px;
  border: 1px solid #aed6ff;
  background-color: #032e6f;
  color: #aed6ff;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
}

/* 卡片内容 */

.card-content {
  display: flex;
  justify-content: space-between;
  padding: 19px 61px 24px 36px;
  box-sizing: border-box;
}

.card-content .picture {
  width: 65px;
  height: 65px;
}

.info {
  width: 279px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.info li {
  width: 116px;
  height: 28px;
  display: flex;
  align-items: center;
}

.info li:first-child {
  margin-bottom: 16px;
}

.info li:nth-child(2) {
  margin-bottom: 16px;
}

.info img {
  width: 28px;
  height: 28px;
}

.info p {
  color: #aed6ff;
  font-size: 14px;
  margin-left: 11px;
}

/*  */
.humidity-content {
  height: 265px;
  border-radius: 3px;
  border: 1px solid #0d2247;
  background-color: rgba(6, 28, 69, 0.75);
  display: flex;
  align-items: center;
}

.humidity-card {
  width: 100%;
  margin: 20px 0;
}

.dashboard {
  width: 520px;
}

.dashboard-header {
  width: 518px;
  padding: 16px 31px 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-header p {
  color: #aed6ff;
  font-size: 14px;
}

.dashboard-actions {
  display: flex;
  align-items: center;
}

.tip {
  color: #4b90e6;
  font-size: 14px;
}

.dashboard-chart {
  width: 100%;
  height: 224px;
  display: flex;
}

.interval {
  width: 1px;
  height: 220px;
  background-color: #032e6f;
}

.line {
  width: 1046px;
}

.line-header {
  width: 1050px;
  padding: 11px 0 0 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
  padding-right: 8px;
}

.form :deep() .el-form-item {
  margin: 0 15px 0 0;
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #041c4c;
  border: 1px solid #09295b;
  color: #aed6ff;
  width: 100%;
}

.form :deep() .el-date-editor {
  width: 160px;
}

.form :deep() .el-select .el-input .el-input__inner {
  width: 160px;
  padding: 0 25px 0 8px;
}

.line-chart {
  height: 224px;
  width: 100%;
  display: flex;
}

/* tabs */
.tabs {
  width: 100%;
  height: 45px;
  display: flex;
  border-radius: 3px;
  border: 1px solid #0d2247;
  background-color: #051b47;
}

.tabs li {
  height: 45px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  line-height: 45px;
  padding: 0 16px;
}

.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}

.noise-content {
  height: 265px;
  border-radius: 3px;
  border: 1px solid #0d2247;
  background-color: rgba(6, 28, 69, 0.75);
  display: flex;
  align-items: center;
}

.noise-header {
  width: 518px;
  height: 26px;
  padding: 13px 18px 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noise-picture {
  height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noise-picture img {
  width: 86px;
  height: 86px;
  margin-bottom: 36px;
}

.noise-picture span {
  color: #aed6ff;
  font-size: 16px;
}

#lineChart {
  width: 1049px;
  height: 224px;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}

::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}

::v-deep .el-dialog__body {
  padding: 25px;
}

::v-deep .el-dialog__footer {
  padding-top: 0;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}

.dialog-footer {
  color: #aed6ff;
  font-size: 13px;
}

#temperature,
#humidity {
  width: 260px;
  height: 224px;
}

#temperatureLine,
#humidityLine {
  width: 523px;
  height: 224px;
}
</style>
