<!-- 实时监控 -->
<template>
  <div id="now-monitor">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>视频监控</span>
      </div>
      <div class="content">
        <!-- <div :class="total>4?'width48':'width33'" class="video-card" v-for="(item,index) in videoData">
          <div class="card-header">
            <div class="card-title">
              <div class="circle"></div>
              <p>{{ item.remark }}</p>
            </div>
            <div v-if="item.firstStatus" class="playback" @click="openVideo">
              <img src="../../assets/images/environment/open.svg" alt="" />
              <p>打开</p>
            </div>
            <div v-else class="playback" @click="closeVideo">
              <img src="../../assets/images/environment/close.svg" alt="" />
              <p>关闭</p>
            </div>
          </div>
          <div class="card-content">
            <div v-show="!item.url" class="video">
              视频画面关闭中.....
            </div>
            <video
              v-show="item.url"
              :id="index"
              controls
              width="100%"
              height="262px"
              muted
            ></video>
          </div>
        </div> -->
        <div
          v-show="firstVideo.resp"
          :style="{ width: total <= 4 ? '48%' : '33%' }"
          class="video-card"
        >
          <div class="card-header">
            <div class="card-title">
              <div :style="{backgroundColor:firstVideo.state===0?'#1dd292':'#c12e2e'}" class="circle"></div>
              <span>{{ firstVideo.cameraName }}</span>
            </div>
            <div
              v-if="videoStatus.firstPlayer"
              class="playback"
              @click="openVideo('firstPlayer', firstVideo)"
            >
              <img src="../../assets/images/environment/open.svg" alt="" />
              <p>打开</p>
            </div>
            <div
              v-else
              class="playback"
              @click="closeVideo('firstPlayer', 'close')"
            >
              <img src="../../assets/images/environment/close.svg" alt="" />
              <p>关闭</p>
            </div>
          </div>
          <div class="card-content">
            <div v-show="videoStatus.firstPlayer" class="video">
              视频画面关闭中.....
            </div>
            <div
              v-show="!videoStatus.firstPlayer"
              class="video-container"
              id="firstVideo"
            ></div>
          </div>
        </div>
        <div
          v-show="secondVideo.resp"
          :style="{ width: total <= 4 ? '48%' : '33%' }"
          class="video-card"
        >
          <div class="card-header">
            <div class="card-title">
              <div :style="{backgroundColor:secondVideo.state===0?'#1dd292':'#c12e2e'}"  class="circle"></div>
              <span>{{ secondVideo.cameraName }}</span>
            </div>
            <div
              v-if="videoStatus.secondPlayer"
              class="playback"
              @click="openVideo('secondPlayer', secondVideo)"
            >
              <img src="../../assets/images/environment/open.svg" alt="" />
              <p>打开</p>
            </div>
            <div
              v-else
              class="playback"
              @click="closeVideo('secondPlayer', 'close')"
            >
              <img src="../../assets/images/environment/close.svg" alt="" />
              <p>关闭</p>
            </div>
          </div>
          <div class="card-content">
            <div v-show="videoStatus.secondPlayer" class="video">
              视频画面关闭中.....
            </div>
            <div
              v-show="!videoStatus.secondPlayer"
              id="secondVideo"
              class="video-container"
            ></div>
          </div>
        </div>
        <div
          v-show="thirdVideo.resp"
          :style="{ width: total <= 4 ? '48%' : '33%' }"
          class="video-card"
        >
          <div class="card-header">
            <div class="card-title">
              <div :style="{backgroundColor:thirdVideo.state===0?'#1dd292':'#c12e2e'}" class="circle"></div>
              <span>{{ thirdVideo.cameraName }}</span>
            </div>
            <div
              v-if="videoStatus.thirdPlayer"
              class="playback"
              @click="openVideo('thirdPlayer', thirdVideo)"
            >
              <img src="../../assets/images/environment/open.svg" alt="" />
              <p>打开</p>
            </div>
            <div
              v-else
              class="playback"
              @click="closeVideo('thirdPlayer', 'close')"
            >
              <img src="../../assets/images/environment/close.svg" alt="" />
              <p>关闭</p>
            </div>
          </div>
          <div class="card-content">
            <div v-show="videoStatus.thirdPlayer" class="video">
              视频画面关闭中.....
            </div>
            <div
              v-show="!videoStatus.thirdPlayer"
              id="thirdVideo"
              class="video-container"
            ></div>
          </div>
        </div>
        <div
          v-show="fourthVideo.resp"
          :style="{ width: total <= 4 ? '48%' : '33%' }"
          class="video-card"
        >
          <div class="card-header">
            <div class="card-title">
              <div :style="{backgroundColor:fourthVideo.state===0?'#1dd292':'#c12e2e'}" class="circle"></div>
              <span>{{ fourthVideo.cameraName }}</span>
            </div>
            <div
              v-if="videoStatus.fourthPlayer"
              class="playback"
              @click="openVideo('fourthPlayer', fourthVideo)"
            >
              <img src="../../assets/images/environment/open.svg" alt="" />
              <p>打开</p>
            </div>
            <div
              v-else
              class="playback"
              @click="closeVideo('fourthPlayer', 'close')"
            >
              <img src="../../assets/images/environment/close.svg" alt="" />
              <p>关闭</p>
            </div>
          </div>
          <div class="card-content">
            <div v-show="videoStatus.fourthPlayer" class="video">
              视频画面关闭中.....
            </div>
            <div
              v-show="!videoStatus.fourthPlayer"
              id="fourthVideo"
              class="video-container"
            ></div>
          </div>
        </div>
        <div
          v-show="fifthVideo.resp"
          :style="{ width: total <= 4 ? '48%' : '33%' }"
          class="video-card"
        >
          <div class="card-header">
            <div class="card-title">
              <div :style="{backgroundColor:fifthVideo.state===0?'#1dd292':'#c12e2e'}" class="circle"></div>
              <span>{{ fifthVideo.cameraName }}</span>
            </div>
            <div
              v-if="videoStatus.fifthPlayer"
              class="playback"
              @click="openVideo('fifthPlayer', fifthVideo)"
            >
              <img src="../../assets/images/environment/open.svg" alt="" />
              <p>打开</p>
            </div>
            <div
              v-else
              class="playback"
              @click="closeVideo('fifthPlayer', 'close')"
            >
              <img src="../../assets/images/environment/close.svg" alt="" />
              <p>关闭</p>
            </div>
          </div>
          <div class="card-content">
            <div v-show="videoStatus.fifthPlayer" class="video">
              视频画面关闭中.....
            </div>
            <div
              v-show="!videoStatus.fifthPlayer"
              id="fifthVideo"
              class="video-container"
            ></div>
          </div>
        </div>
        <div
          v-show="sixthVideo.resp"
          :style="{ width: total <= 4 ? '48%' : '33%' }"
          class="video-card"
        >
          <div class="card-header">
            <div class="card-title">
              <div :style="{backgroundColor:sixthVideo.state===0?'#1dd292':'#c12e2e'}" class="circle"></div>
              <span>{{ sixthVideo.cameraName }}</span>
            </div>
            <div
              v-if="videoStatus.sixthPlayer"
              class="playback"
              @click="openVideo('sixthPlayer', sixthVideo)"
            >
              <img src="../../assets/images/environment/open.svg" alt="" />
              <p>打开</p>
            </div>
            <div
              v-else
              class="playback"
              @click="closeVideo('sixthPlayer', 'close')"
            >
              <img src="../../assets/images/environment/close.svg" alt="" />
              <p>关闭</p>
            </div>
          </div>
          <div class="card-content">
            <div v-show="videoStatus.sixthPlayer" class="video">
              视频画面关闭中.....
            </div>
            <div
              v-show="!videoStatus.sixthPlayer"
              id="sixthVideo"
              class="video-container"
            ></div>
          </div>
        </div>
      </div>
      <div v-if="noList" class="noList">暂无数据</div>
    </div>
    <el-dialog title="提示" :visible.sync="videoVisible" width="436px">
      <div class="dialog-text">
        最多只能同时查看两路视频，请关闭其他视频
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="videoVisible = false">取 消</el-button> -->
        <!-- <el-button type="primary" @click="videoVisible = false">确 定</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { environmentMonitorApi } from "@/api/environmentMonitor.js";
import { color } from "echarts";
export default {
  name: "",
  data() {
    return {
      firstVideo: {},
      secondVideo: {},
      thirdVideo: {},
      fourthVideo: {},
      fifthVideo: {},
      sixthVideo: {},
      stationNumber: "",
      firstPlayer: null,
      secondPlayer: null,
      thirdPlayer: null,
      fourthPlayer: null,
      fifthPlayer: null,
      sixthPlayer: null,
      videoStatus: {
        firstPlayer: true,
        secondPlayer: true,
        thirdPlayer: true,
        fourthPlayer: true,
        fifthPlayer: true,
        sixthPlayer: true,
      },
      videoData: [],
      total:0,
      videoVisible: false,
      noList:false,
    };
  },
  created() {
    this.$store.commit("increment", "运行环境");
    this.$store.commit("selectChild", "视频监控");
    this.$store.commit("selectChildren", "");
    this.stationNumber = sessionStorage.getItem("stationNumber");
  },
  mounted() {
    this.queryEnvironmentVideo();
  },

  methods: {
    openVideo(item, video) {
      let number = 0;
      for (let key in this.videoStatus) {
        if (!this.videoStatus[key]) {
          number += 1;
        }
      }
      if (number === 2) {
        this.videoVisible = true;
      } else {
        this.videoStatus[item] = false;
        if (video.resp) {
          if (item === "firstPlayer") {
            this.firstPlayer.play(video.resp); //播放流
          }
          if (item === "secondPlayer") {
            this.secondPlayer.play(video.resp); //播放流
          }
          if (item === "thirdPlayer") {
            this.thirdPlayer.play(video.resp); //播放流
          }
          if (item === "fourthPlayer") {
            this.fourthPlayer.play(video.resp); //播放流
          }
          if (item === "fifthPlayer") {
            this.fifthPlayer.play(video.resp); //播放流
          }
          if (item === "sixthPlayer") {
            this.sixthPlayer.play(video.resp); //播放流
          }
        }
      }

      // const array = [
      //   "firstPlayer",
      //   "secondPlayer",
      //   "thirdPlayer",
      //   "fourthPlayer",
      //   "fifthPlayer",
      //   "sixthPlayer",
      // ];
      // const index = array.indexOf(item);
      // if(index===this.total-1){
      //     this.closeVideo('firstPlayer')
      // }else{
      //   this.closeVideo(array[index + 1]);
      // }
    },
    closeVideo(item) {
      this.videoStatus[item] = true;
      if (item === "firstPlayer") {
        this.firstPlayer.pause();
        // this.firstPlayer.close();
      }
      if (item === "secondPlayer") {
        this.secondPlayer.pause();
        // this.secondPlayer.close();
      }
      if (item === "thirdPlayer") {
        this.thirdPlayer.pause();
        // this.thirdPlayer.close();
      }
      if (item === "fourthPlayer") {
        this.fourthPlayer.pause();
        // this.fourthPlayer.close();
      }
      if (item === "fifthPlayer") {
        this.fifthPlayer.pause();
        // this.fifthPlayer.close();
      }
      if (item === "sixthPlayer") {
        this.sixthPlayer.pause();
        // this.sixthPlayer.close();
      }
    },
    //视频
    queryEnvironmentVideo() {
      let params = {
        stationNumber: this.stationNumber,
      };
      environmentMonitorApi.queryEnvironmentVideo(params).then((res) => {
        if (res.code === 200) {
          console.log("111111",res);
          if(res.data.length==0){
            this.noList=true
          }else{
          this.noList=false
          }
          this.videoData = res.data.filter(item=>{
            return item.resp
          });
          this.total=this.videoData.length;
          if (this.videoData[0]) {
            this.firstVideo = this.videoData[0];
            this.firstVideoPlayer(this.firstVideo.resp);
            this.videoStatus.firstPlayer = true;
          }
          if (this.videoData[1]) {
            this.secondVideo = this.videoData[1];
            this.secondVideoPlayer(this.secondVideo.resp);
            this.videoStatus.secondPlayer = true;
          }
          if (this.videoData[2]) {
            this.thirdVideo = this.videoData[2];
            this.thirdVideoPlayer(this.thirdVideo.resp);
            this.videoStatus.thirdPlayer = true;
          }
          if (this.videoData[3]) {
            this.fourthVideo = this.videoData[3];
            this.fourthVideoPlayer(this.fourthVideo.resp);
            this.videoStatus.fourthPlayer = true;
          }
          if (this.videoData[4]) {
            this.fifthVideo = this.videoData[4];
            this.fifthVideoPlayer(this.fifthVideo.resp);
            this.videoStatus.fifthPlayer = true;
          }
          if (this.videoData[5]) {
            this.sixthVideo = this.videoData[5];
            this.sixthVideoPlayer(this.sixthVideo.resp);
            this.videoStatus.sixthPlayer = true;
          }
        }
      });
    },
    firstVideoPlayer(url) {
      this.firstPlayer = new window.Jessibuca({
        container: document.getElementById("firstVideo"), //jessibuca需要容器
        videoBuffer: 1,
        isResize: false,
        loadingText: "疯狂加载中...",
        hasAudio: false,
        useMSE: true,
        useWCS: true,
        debug: false,
        supportDblclickFullscreen: true,
        showBandwidth: false, // 显示网速
        operateBtns: {
          fullscreen: true,
          screenshot: true,
          play: false,
          audio: false,
        },
        forceNoOffscreen: true,
        isNotMute: false,
        timeout: 10,
      });
      // this.firstPlayer.play(url);
    },
    secondVideoPlayer(url) {
      this.secondPlayer = new window.Jessibuca({
        container: document.getElementById("secondVideo"), //jessibuca需要容器
        videoBuffer: 1,
        isResize: false,
        loadingText: "疯狂加载中...",
        hasAudio: false,
        useMSE: true,
        useWCS: true,
        debug: false,
        supportDblclickFullscreen: true,
        showBandwidth: false, // 显示网速
        operateBtns: {
          fullscreen: true,
          screenshot: true,
          play: false,
          audio: false,
        },
        forceNoOffscreen: true,
        isNotMute: false,
        timeout: 10,
      });
      // this.secondPlayer.play(url);
    },
    thirdVideoPlayer(url) {
      this.thirdPlayer = new window.Jessibuca({
        container: document.getElementById("thirdVideo"), //jessibuca需要容器
        videoBuffer: 1,
        isResize: false,
        loadingText: "疯狂加载中...",
        hasAudio: false,
        useMSE: true,
        useWCS: true,
        debug: false,
        supportDblclickFullscreen: true,
        showBandwidth: false, // 显示网速
        operateBtns: {
          fullscreen: true,
          screenshot: true,
          play: false,
          audio: false,
        },
        forceNoOffscreen: true,
        isNotMute: false,
        timeout: 10,
      });
    },
    fourthVideoPlayer(url) {
      this.fourthPlayer = new window.Jessibuca({
        container: document.getElementById("fourthVideo"), //jessibuca需要容器
        videoBuffer: 1,
        isResize: false,
        loadingText: "疯狂加载中...",
        hasAudio: false,
        useMSE: true,
        useWCS: true,
        debug: false,
        supportDblclickFullscreen: true,
        showBandwidth: false, // 显示网速
        operateBtns: {
          fullscreen: true,
          screenshot: true,
          play: false,
          audio: false,
        },
        forceNoOffscreen: true,
        isNotMute: false,
        timeout: 10,
      });
    },
    fifthVideoPlayer(url) {
      this.fifthPlayer = new window.Jessibuca({
        container: document.getElementById("fifthVideo"), //jessibuca需要容器
        videoBuffer: 1,
        isResize: false,
        loadingText: "疯狂加载中...",
        hasAudio: false,
        useMSE: true,
        useWCS: true,
        debug: false,
        supportDblclickFullscreen: true,
        showBandwidth: false, // 显示网速
        operateBtns: {
          fullscreen: true,
          screenshot: true,
          play: false,
          audio: false,
        },
        forceNoOffscreen: true,
        isNotMute: false,
        timeout: 10,
      });
    },
    sixthVideoPlayer(url) {
      this.sixthPlayer = new window.Jessibuca({
        container: document.getElementById("sixthVideo"), //jessibuca需要容器
        videoBuffer: 1,
        isResize: false,
        loadingText: "疯狂加载中...",
        hasAudio: false,
        useMSE: true,
        useWCS: true,
        debug: false,
        supportDblclickFullscreen: true,
        showBandwidth: false, // 显示网速
        operateBtns: {
          fullscreen: true,
          screenshot: true,
          play: false,
          audio: false,
        },
        forceNoOffscreen: true,
        isNotMute: false,
        timeout: 10,
      });
    },
    beforeDestroy() {
      this.firstPlayer.destroy(); //销毁实例
      this.secondPlayer.destroy(); //销毁实例
      this.thirdPlayer.destroy(); //销毁实例
      this.fourthPlayer.destroy(); //销毁实例
      this.fifthPlayer.destroy(); //销毁实例
      this.sixthPlayer.destroy(); //销毁实例
    },
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
p {
  margin: 0;
}
.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}
#now-monitor {
  width: 100%;
  /* min-height: 781px; */
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}
.main {
  width: 100%;
  padding: 26px 26px 0 29px;
  box-sizing: border-box;
}
.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.width33 {
  width: 33%;
}
.width48 {
  width: 48%;
}
.video-card {
  /* height: 332px; */
  border-radius: 3px;
  padding-top: 11px;
  background-color: rgba(6, 29, 74, 0.79);
  box-sizing: border-box;
  margin-bottom: 28px;
}
.card-header {
  /* height: 32px; */
  margin: 0 0 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-title {
  display: flex;
  align-items: center;
}
.circle {
  width: 14px;
  height: 14px;
  background-color: #1dd292;
  border-radius: 50%;
}
.card-title span {
  color: #ffffff;
  font-size: 14px;
  margin: 0 16px 0 8px;
}
.playback {
  width: 60px;
  height: 32px;
  position: relative;
  cursor: pointer;
}
.playback img {
  width: 60px;
  height: 32px;
  position: absolute;
  right: 0;
  top: 0;
}
.playback p {
  color: #ffffff;
  font-size: 12px;
  line-height: 32px;
  position: absolute;
  right: 16px;
}
/* .card-content {
  padding: 0 12px 15px 11px;
  box-sizing: border-box;
} */
.video {
  width: 100%;
  height: 262px;
  background-color: rgba(6, 29, 74, 0.79);
  color: #ffffff;
  font-size: 13px;
  text-align: center;
  line-height: 262px;
}
.video-container {
  width: 100%;
  height: 333px;
}
/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}

::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
  /* position: relative; */
}
/* ::v-deep .el-dialog__title::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -8px;
  width: 3px;
  background-color: #176ad2;
  border-radius: 5px;
} */

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  padding-top: 0;
}

/* delete dialog */
.dialog-text {
  font-size: 16px;
  font-weight: 700;
  color: #c9d4f1;
  margin: 42px 0 64px 39px;
}

/* footer  */
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}
.noList{
  width: 100%;
  height: 80vh;
display: flex;
justify-content: center;
align-items: center;  
}
</style>
