import requests from "@/utils/request";
import qs from 'qs'
window.qs = qs;
export function getWeatherHourData(data) {
    return requests({
        url: `http://10.56.3.8:17003/user_hycpd/weather/getWeatherHourData`,
        headers: {
            'Content-Type': 'application/json',
            'apikey':'vQIjyz7mQRhcjQhYJQ4Tz1u0CQJgMqqW',
            'appKey':'LmMbkpU',
            'appSecret':'3298fe81b8e95123e9f3a76927b18fe0b4a288a3'
        },
        method: "post",
        data: data
    });
}