<!-- 设备管理 -->
<template>
  <div id="device">
    <div class="tree">
      <div class="tree-title">组织结构</div>
      <el-input placeholder="输入检索内容" suffix-icon="el-icon-search" v-model.trim="filterText"></el-input>
      <!-- <el-checkbox v-model="allSelect">全选</el-checkbox> -->
      <el-tree ref="tree" v-loading="loading" :default-expand-all="false" :show-checkbox="false" :data="treeData"
               :check-on-click-node="false" :props="defaultProps" node-key="id" :filter-node-method="filterNode"
               :default-expanded-keys="treeChildren"
               @node-click="handleCheck"></el-tree>
    </div>
    <div class="main">
      <div class="query-form">
        <div class="towChart">
          <div id="chart1" style="width: 60%;height: 100%;"></div>
          <div id="chart2" style="width: 40%;height: 100%;"></div>
        </div>
      </div>
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt=""/>
        <span>设备管理</span>
      </div>
      <div class="query-form">
        <el-form class="form" ref="form" :inline="true" :model="requestParam">
          <el-form-item label="场站名称">
            <el-select filterable v-model="requestParam.stationId" :popper-append-to-body="false" clearable
                       placeholder="请选择场站名称" @clear="clearSelect">
              <el-option v-for="item in stationData" :key="item.id" :label="item.stationName +' '+ item.stationNumber"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称">
            <el-input v-model.trim="requestParam.deviceName" placeholder="请输入设备名称" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="设备编号">
            <el-input v-model.trim="requestParam.deviceNumber" placeholder="请输入设备编号" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="设备类型">
            <!-- <el-input v-model.trim="requestParam.deviceModel" placeholder="请输入设备型号" clearable> -->
            <el-select v-model="requestParam.deviceModel" :popper-append-to-body="false" clearable
                       placeholder="请选择设备类型">
              <el-option v-for="item in sblxData" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
            <!-- </el-input> -->
          </el-form-item>
          <el-form-item label="柜子名称">
            <el-input v-model.trim="requestParam.cabinetName" placeholder="请输入柜子名称" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="安装位置">
            <el-select v-model="requestParam.position" :popper-append-to-body="false" clearable
                       placeholder="请选择安装位置">
              <el-option v-for="item in positionData" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="配电室">-->
          <!--            <el-select v-model="valueP" :popper-append-to-body="false" placeholder="请选择" clearable-->
          <!--                       @change="selectChange($event)">-->
          <!--              <el-option-->
          <!--                  v-for="item in optionsP"-->
          <!--                  :key="item.value"-->
          <!--                  :label="item.label"-->
          <!--                  :value="item.value">-->
          <!--              </el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <el-form-item label="回路">
            <!--            <el-select v-model="valueH" filterable :popper-append-to-body="false" clearable placeholder="请选择">-->
            <!--              <el-option-->
            <!--                  v-for="item in optionsH"-->
            <!--                  :key="item.value"-->
            <!--                  :label="item.label"-->
            <!--                  :value="item.value">-->
            <!--              </el-option>-->
            <!--            </el-select>-->
            <el-input v-model.trim="requestParam.loopName" placeholder="请输入回路名称"></el-input>
          </el-form-item>
        </el-form>
        <div class="query-button">
          <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
          <el-button type="primary" @click="getExport()">导出</el-button>
        </div>
      </div>

      <el-table class="table" :data="tableData">
        <el-table-column align="center" type="index" label="序号" width="75">
          <template slot-scope="scope">
            <span>{{
                (requestParam.pageNum - 1) * requestParam.pageSize +
                scope.$index +
                1
              }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="stationIdDesc" label="场站名称">
        </el-table-column>
        <el-table-column align="center" prop="stationNumber" label="场站编码">
        </el-table-column>
        <el-table-column align="center" prop="deviceName" label="设备名称">
        </el-table-column>
        <el-table-column align="center" prop="deviceNumber" label="设备编号">
        </el-table-column>
        <el-table-column align="center" prop="deviceModelName" label="设备类型">
        </el-table-column>
        <el-table-column align="center" prop="cabinetName" label="柜子名称">
        </el-table-column>
        <el-table-column align="center" prop="cabinetNumber" label="柜子编号">
        </el-table-column>
        <el-table-column align="center" prop="positionDict" label="安装位置">
        </el-table-column>
        <el-table-column align="center" prop="loopName" label="回路名称">
        </el-table-column>
        <el-table-column align="center" prop="loopNumber" label="回路编号">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <button class="home-page" @click="homePage">首页</button>
        <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
                       layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total"
                       background
                       @size-change="changeSize" @current-change="changeCurrent">
        </el-pagination>
        <button class="tail-page" @click="tailPage">尾页</button>
        <div class="jump-pagination">
          <span class="text">跳至</span>
          <el-input v-model.number="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
                    @change="inputValueChange"></el-input>
          <span class="text">页</span>
          <button class="confirm" @click="confirm">确定</button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {deviceApi} from "@/api/device.js";
import {energyStationApi} from "@/api/energyStation.js";
import stationDialog from "../operationManagement/commponents/stationDialog.vue";
import * as echarts from "echarts";
import download from "@/utils/download";
import moment from "moment";

export default {
  name: "",
  components: {stationDialog},
  data() {
    return {
      treeChildren: [],
      loading: false,
      tableData: [],
      valueP: '',
      optionsP: [
        {
          value: '1',
          label: '黄金糕'
        }, {
          value: '2',
          label: '双皮奶'
        }, {
          value: '3',
          label: '蚵仔煎'
        }, {
          value: '4',
          label: '龙须面'
        }, {
          value: '5',
          label: '北京烤鸭'
        }
      ],
      valueH: '',
      optionsH: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        deviceNumber: "",
        deviceName: "",
        cabinetName: "",
        deviceModel: "",
        position: "",
        stationId: null,
        companyInfoId: "",
        loopName: '',
      },
      total: 0,
      inputValue: "",
      id: "",
      requestType: 0,
      checkedNodes: [],
      defaultProps: {
        children: "companyInfoList",
        id: "id",
        label: "companyName",
      },
      treeData: [],
      filterText: "",
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      detailsData: {},
      stationData: [],
      positionData: [],
      sblxData: [],
      pickerOptionsStart: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        },
      },
      //选择场站
      stationName: "",
      dialogStatus: false,
      allButtonPower: [],
    };
  },
  created() {
    this.$store.commit("increment", "统计分析");
    this.$store.commit("selectChild", "设备统计");
    this.$store.commit("selectChildren", "");
  },
  mounted() {

    this.queryUnitName();
    this.queryPosition();
    this.querySblx()
    // this.queryStationName();
  },

  methods: {
    clearSelect() {
      this.requestParam.stationId = null
    },
    classifyAndCount() {
      const param = {
        companyInfoId: this.requestParam.companyInfoId
      }
      deviceApi.classifyAndCount(param).then(res => {
        if (res.code == '200') {
          this.getChart1(res.data)
          this.getChart2(res.data)
        }
      })
    },
    selectChange(val) {
      console.log(val)
      this.valueH = ''
      this.optionsH = []
      if (val == '1') {
        this.optionsH = [
          {value: '1', label: '真的'},
          {value: '2', label: '假的'}
        ]
      } else if (val == '2') {
        this.optionsH = [
          {value: '1', label: '不是真的'},
          {value: '2', label: '不是假的'}
        ]
      }
    },
    getChart1(data) {
      var myChart = echarts.init(document.getElementById('chart1'), 'shine');
      var option = {
        title: {
          text: '设备分类统计',
          left: 'left',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          top: '10%',
          height: '180',
          itemWidth: 12,
          itemHeight: 10,
          itemStyle: {
            padding: [0, 0, 10, 10]
          },
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            name: '分类',
            type: 'pie',
            radius: '80%',
            data: [
              {value: data.powerAnalysis, name: '电力分析'},
              {value: data.compProt, name: '综保'},
              {value: data.tempMeas, name: '测温'},
              {value: data.multiMeter, name: '多功能仪表'},
              {value: data.leakFire, name: '漏电火灾'},
              {value: data.partDis, name: '局放'},
              {value: data.ac, name: '空调'},
              {value: data.plc, name: 'PLC'},
              {value: data.sf6, name: 'SF6'},
              {value: data.water, name: '水浸'},
              {value: data.tempCtrl, name: '温控'},
              {value: data.tempHum, name: '温湿'},
              {value: data.dcBat, name: '直流屏蓄电池'},
              {value: data.smoke, name: '烟雾'},
              {value: data.noise, name: '噪声'},
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            // labelLine: {
            //   normal: {
            //     length: 15
            //   }
            // },
            label: {
              formatter: '{c}',
              backgroundColor: '#061b45',
              color: 'white'
            }
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getChart2(data) {
      var myChart = echarts.init(document.getElementById('chart2'), 'shine');
      var option = {
        title: {
          text: '安装位置',
          left: 'left',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          top: '10%',
          itemWidth: 12,
          itemHeight: 10,
          itemStyle: {
            padding: [0, 0, 10, 10]
          },
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            name: '分类',
            type: 'pie',
            radius: '80%',
            data: [
              {value: data.hvSide, name: '高压侧'},
              {value: data.trans, name: '变压器'},
              {value: data.lvSide, name: '低压侧'},
              {value: data.dcSide, name: '直流侧'},
              {value: data.other, name: '其他'}
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              formatter: '{c}',
              backgroundColor: '#061b45',
              color: 'white'
            }
          }
        ]
      };
      myChart.setOption(option, true);
    },
    //设备类型下拉
    querySblx() {
      energyStationApi
          .queryDictionary({dictCode: "deviceModelType"})
          .then((res) => {
            if (res.code === 200) {
              this.sblxData = res.data;
            }
          });
    },
    changeDialog(value) {
      this.dialogStatus = value;
    },
    //-----------------------
    filterNode(value, data) {
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryDevice();
      this.classifyAndCount()
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
            this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryDevice();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryDevice();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryDevice();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryDevice();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryDevice();
    },
    handleCheck(checkedNodes, event) {
      if (this.requestParam.companyInfoId == checkedNodes.id) {
        this.checkedNodes = ''
        this.requestParam.companyInfoId = checkedNodes.id
        this.queryDevice();
        this.queryStation(this.checkedNodes.id);
        this.classifyAndCount()
      } else {
        this.requestParam.companyInfoId = checkedNodes.id
        this.checkedNodes = checkedNodes;
        this.queryDevice();
        this.queryStation(this.checkedNodes.id);
        this.classifyAndCount()
      }

    },
    getExport() {
      deviceApi.getExportDevice(this.requestParam).then((res) => {
        let time = moment().format('YYYY-MM-DD');
        let name = '设备统计' + time
        download.excel(res, name)
        this.$message({
          message: '导出成功',
          type: 'success',
          duration: 3000,
          customClass: "messageText",
        });
      })
    },
    queryDevice() {
      deviceApi.queryDevice(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    queryStation(companyId) {
      energyStationApi.queryStation({companyId: companyId}).then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
        }
      });
    },
    // 获取安装位置
    queryPosition() {
      energyStationApi
          .queryDictionary({dictCode: "position_type"})
          .then((res) => {
            if (res.code === 200) {
              this.positionData = res.data;
            }
          });
    },
    // 构建
    buildTreeData(data) {
      data.forEach((item, index) => {
        if (item.companyInfoList.length !== 0) {
          this.buildTreeData(item.companyInfoList);
        } else {
          let array = [];
          item.stationInfoList.forEach((element) => {
            if (element !== null) {
              array.push({
                id: element.id,
                companyName: element.stationName,
                final: true,
              });
              item.companyInfoList = array;
            }
          });
        }
      });
      return data;
    },
    queryUnitName() {
      this.loading = true;
      deviceApi.queryUnitName().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          // this.requestParam.companyInfoId = res.data[0].id;
          this.treeData = res.data;
          this.queryStation(res.data[0].id);
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.treeData[0].companyInfoList[0].id); // 默认选中节点第一个
            this.treeChildren = [this.treeData[0].id]
            this.requestParam.companyInfoId = this.treeData[0].companyInfoList[0].id
            this.classifyAndCount()
            this.queryDevice();
            this.queryStation(this.requestParam.companyInfoId);
          });
        }
      });
    },
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {},
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
.towChart {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#device {
  width: 100%;
  min-height: 781px;
  font-size: 16px;
  display: flex;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

.main {
  width: calc(100% - 240px);
  background: url("../../assets/images/content-background.png") no-repeat center;
  background-size: cover;
  padding: 22px 0 26px 30px;
  box-sizing: border-box;
}

/* 弹窗 */
.dialog-content {
  margin: 34px 46px 0 60px;
}

/* input */
.dialog-form :deep() .el-date-editor.el-input {
  width: 260px;
}

.dialog-form :deep() .el-input {
  width: 260px;
}

.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea {
  width: 655px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.dialog-form :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.dialog-form :deep() .special {
  margin-left: 25px;
}

.dialog-form :deep() .block {
  width: 655px;
}

.dialog-form :deep() .block .el-input {
  width: 655px;
}

/* 时间选择器 */
/*时间日期选择器默认样式修改 */
.dialog-form :deep() .el-picker-panel .el-input {
  width: 100%;
}

::v-deep .el-time-panel {
  background: #041c4c;
  border: 0;
  color: #aed6ff;
}

::v-deep .el-time-panel__footer {
  border-color: #aed6ff;
}

::v-deep .el-time-spinner__item {
  color: #aed6ff;
}

::v-deep .el-time-spinner__item.active:not(.disabled) {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn.confirm {
  color: #409eff;
}

::v-deep .el-picker-panel__footer {
  color: #aed6ff;
  background: #041c4c;
}

::v-deep .is-plain {
  background: #176ad2;
  border: 0;
  color: #fff;
}

::v-deep .el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #041c4c;
}

::v-deep .is-plain:hover {
  background: #176ad2;
  border: 0;
  color: #fff;
}

/* 选择场站样式 */
.dialog-form :deep() .el-input-group__append {
  background: #143168;
  color: #fff;
  border: 0;
  cursor: pointer;
}

.dialog-form:deep() .el-form-item__label {
  width: 98px;
}
</style>
