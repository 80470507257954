import {getRequest,monitorGateway} from "@/utils/request";
// 环境监测
export const environmentMonitorApi = {
        // 湿度
        queryEnvironmentHumidity: (query) => {
          return getRequest(`${monitorGateway}/environment/humidity`, query);
      },
         // 环境检测
         queryEnvironmentMonitor: (query) => {
          return getRequest(`${monitorGateway}/environment/monitoring`, query);
      },
         // 噪声、局放、红外、sf6气体检测
         queryEnvironmentNoise : (query) => {
          return getRequest(`${monitorGateway}/environment/noise/local/infrared/sf6`, query);
      },
         // 温度
         queryEnvironmentTemperature: (query) => {
          return getRequest(`${monitorGateway}/environment/temperature`, query);
      },
            // 温湿度
            queryTemperatureHumidity: (query) => {
                return getRequest(`${monitorGateway}/environment/tmpandhumi`, query);
            },
          // 温湿度
          queryEnvironmentVideo: (query) => {
            return getRequest(`${monitorGateway}/environment/video`, query);
        },
        //实时获取数据-噪声-局放-红外-sf6
        timeData:(params)=>{
            return getRequest(`${monitorGateway}/environment/real/time/data`,params)
        },
       
};