<template>
  <v-scale-screen width="1920" height="1080" :fullScreen="true">
    <div id="monitoringLeft">
      <div class="left_header">
        <div class="header_left">
          <img @click="goHome('home')" src="../../assets/images/monitoring/header_left1.png">
          <span @click="goHome('home')">首页</span>
          <img src="../../assets/images/monitoring/header_left2.png">
          <!-- <span>2023年06月22日 星期四 15:01:34 </span> -->
          <span>{{ nowDate + " " + nowWeek + "  " + nowTime }}</span>
        </div>
        <div class="header_right">
<!--          <img src="../../assets/images/monitoring/header_left3.png">-->
<!--          <span>地区：北京  天气：{{ tq.tqxx }}</span>-->
          <img src="../../assets/images/monitoring/header_left4.png">
          <span>{{ userInfo.userName }}</span>
          <img @click="loginOut" src="../../assets/images/monitoring/header_left5.png">
          <span @click="loginOut">退出</span>
        </div>
      </div>
      <div class="left_main">
        <div class="main_one">
          <div class="one_div" style="pointer-events: auto;">
            <div class="div_main" v-for="(item,index) in List" :key="index"
                 :style="{ background: `url(${item.imgUrl}) no-repeat ` }">
              <div style="color:#0096FF;font-size:0.18rem;">{{ item.grade }}</div>
              <div style="color:#E8F4FF;font-size:0.1rem;">{{ item.name }}</div>
            </div>
          </div>
          <div class="two_div">
            <div style="pointer-events: auto;" class="div_main" v-for="(item,index) in List2" :key="index"
                 :style="{ background: `url(${item.imgUrl}) no-repeat ` }">
              <div style="color:#0096FF;font-size:0.18rem;">{{ item.grade }}</div>
              <div style="color:#E8F4FF;font-size:0.1rem;">{{ item.name }}</div>
            </div>
          </div>
          <div class="three_div">
            <div style="pointer-events: auto;" class="three_main" v-for="(item,index) in List3" :key="index">
              <img :src="item.imgUrl">
              <div style="color:#BCCAF3;font-size:0.12rem;float:left;padding-top: 0.05rem;">{{ item.name }}</div>
              <div style="color:#BCCAF3;font-size:0.12rem;">{{ item.name2 }}</div>
            </div>
          </div>
          <div class="four_div">
            <div style="pointer-events: auto;" class="four_main" v-for="(item,index) in List4" :key="index">
              <img :src="item.imgUrl">
              <div class="">
                <div style="color:#40ECC2;font-size:0.14rem;padding-top: 0.05rem;">{{ item.name }}</div>
                <div style="color:#BCCAF3;font-size:0.12rem;">{{ item.name2 }}</div>
              </div>
            </div>
          </div>
          <div class="four_div">
            <div style="pointer-events: auto;" class="four_main" v-for="(item,index) in List5" :key="index">
              <img :src="item.imgUrl">
              <div class="">
                <div style="color:#40ECC2;font-size:0.14rem;padding-top: 0.05rem;">{{ item.name }}</div>
                <div style="color:#BCCAF3; font-size:0.12rem;">{{ item.name2 }}</div>
              </div>
            </div>
          </div>
          <div class="four_div">
            <div style="pointer-events: auto;" class="four_main" v-for="(item,index) in List6" :key="index">
              <img :src="item.imgUrl">
              <div class="">
                <div style="color:#40ECC2;font-size:0.14rem;padding-top: 0.05rem;">{{ item.name }}</div>
                <div style="color:#BCCAF3;font-size:0.12rem;">{{ item.name2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="main_two" style="width: 4rem;pointer-events: auto;">
          <div class="one" style="margin-bottom: 0.1rem">
            <div class="two_div" style="pointer-events: auto;">
              <div class="div_main">
                <div style="float:left;">政策名称</div>
                <div style="float:right;">时间</div>
              </div>
            </div>
            <div style="pointer-events: auto;" class="two_div" v-for="(item,index) in List7" :key="index"
                 :style="{ background: `url(${item.imgUrl}) no-repeat ` }">
              <div class="div_main">
                <div
                    style="float:left; width: 2.35rem; white-space: nowrap;  overflow: hidden; text-overflow: ellipsis;">
                  {{ item.noticeTitle }}
                </div>
                <div style="float:right;">{{ item.releaseDate }}</div>
              </div>
            </div>
          </div>
          <div class="one">
            <div class="two_div" style="pointer-events: auto;">
              <div class="div_main">
                <div style="float:left;">公司新闻名称</div>
                <div style="float:right;">时间</div>
              </div>
            </div>
            <div style="pointer-events: auto;" class="two_div" v-for="(item,index) in List8" :key="index"
                 :style="{ background: `url(${item.imgUrl}) no-repeat ` }">
              <div class="div_main">
                <div
                    style="float:left; width: 2.35rem; white-space: nowrap;  overflow: hidden; text-overflow: ellipsis;">
                  {{ item.noticeTitle }}
                </div>
                <div style="float:right;">{{ item.releaseDate }}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="center_main" id="bmap" style="width:100%;height:100%;"></div>
      <div class="main_cz">
        <div class="" @click="sxDialogShow">
          <img src="../../assets/images/monitoring/dtcz3.png" alt="">
          <!--<img src="../../assets/images/monitoring/dtcz2.png" alt="">-->
          <!--<img src="../../assets/images/monitoring/dtcz.png" alt="">-->
        </div>
        <div class="main_czsx" v-if="sxDialog">
          <div class="tabNavBox">
            <div :class="tab == index? 'navClick':'nav'" v-for="(item,index) in tabNavList" :key="index" @click="clickNav(index)">{{item.label}}</div>
          </div>
          <div v-show="tabShow">
            <div class="czsx_title">
              <img src="../../assets/images/monitoring/sxfzx.png">&nbsp;&nbsp;
              客户
            </div>
            <div class="czsx_div">
              <div v-for="(item,index) in branchData" :key="index" @click="sourceOneClick(item)">
                <span :class="branchId==item.id? 'searchText':''"> {{ item.companyName }}</span>
              </div>
            </div>
            <div class="czsx_title">
              <img src="../../assets/images/monitoring/sxcqdw.png">&nbsp;&nbsp;
              运维单位
            </div>
            <div class="czsx_div">
              <div style="width:1.5rem;" v-for="(item,index) in propertyData" :key="index" @click="sourceTwoClick(item)">

                <span :class="propertyId==item.id? 'searchText':''">{{ item.companyName }}</span>
              </div>
            </div>
            <div class="czsx_title">
              <div class="screen-subtitle">
                <img src="../../assets/images/monitoring/sxcz.png">&nbsp;&nbsp;
                场站
              </div>
              <div class="search">
                <el-input v-model="stationName" placeholder="请根据关键词进行搜索"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="getSourceStationThree()">搜索</el-button>
              </div>
              <div class="czsx_div">
                <div style="width:2.05rem;margin-right:0.05rem;" v-for="(item,index) in stationData" :key="index"
                     @click="sourceThreeClick(item)">
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-show="tabShow1">
            <div class="czsx_title">
              <img src="../../assets/images/monitoring/sxfzx.png">&nbsp;&nbsp;
              客户
            </div>
            <div class="czsx_div">
              <div v-for="(item,index) in branchData" :key="index" @click="networkOneClick(item)">
                <span :class="branchId==item.id? 'searchText':''"> {{ item.companyName }}</span>
              </div>
            </div>
            <div class="czsx_title">
              <img src="../../assets/images/monitoring/sxcqdw.png">&nbsp;&nbsp;
              产权
            </div>
            <div class="czsx_div">
              <div style="width:1.5rem;" v-for="(item,index) in propertyData" :key="index" @click="networkTwoClick(item)">

                <span :class="propertyId==item.id? 'searchText':''">{{ item.companyName }}</span>
              </div>
            </div>
            <div class="czsx_title">
              <div class="screen-subtitle">
                <img src="../../assets/images/monitoring/sxcz.png">&nbsp;&nbsp;
                场站
              </div>
              <div class="search">
                <el-input v-model="stationName" placeholder="请根据关键词进行搜索"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="getNetworkStationsThree()">搜索</el-button>
              </div>
              <div class="czsx_div">
                <div style="width:2.05rem;margin-right:0.05rem;" v-for="(item,index) in stationData" :key="index"
                     @click="networkThreeClick(item)">
                  <span>{{ item.stationName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-show="tabShow2">
            <div class="czsx_title">
              <img src="../../assets/images/monitoring/sxfzx.png">&nbsp;&nbsp;
              客户
            </div>
            <div class="czsx_div">
              <div v-for="(item,index) in branchData" :key="index" @click="lotusOneClick(item)">
                <span :class="branchId==item.id? 'searchText':''"> {{ item.companyName }}</span>
              </div>
            </div>
            <div class="czsx_title">
              <img src="../../assets/images/monitoring/sxcqdw.png">&nbsp;&nbsp;
              产权
            </div>
            <div class="czsx_div">
              <div style="width:1.5rem;" v-for="(item,index) in propertyData" :key="index" @click="lotusTwoClick(item)">

                <span :class="propertyId==item.id? 'searchText':''">{{ item.companyName }}</span>
              </div>
            </div>
            <div class="czsx_title">
              <div class="screen-subtitle">
                <img src="../../assets/images/monitoring/sxcz.png">&nbsp;&nbsp;
                场站
              </div>
              <div class="search">
                <el-input v-model="stationName" placeholder="请根据关键词进行搜索"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="getLotusThree">搜索</el-button>
              </div>
              <div class="czsx_div">
                <div style="width:2.05rem;margin-right:0.05rem;" v-for="(item,index) in stationData" :key="index"
                     @click="lotusThreeClick(item)">
                  <span>{{ item.stationName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-show="tabShow3">
            <div class="czsx_title">
              <img src="../../assets/images/monitoring/sxfzx.png">&nbsp;&nbsp;
              客户
            </div>
            <div class="czsx_div">
              <div v-for="(item,index) in branchData" :key="index" @click="storageOneClick(item)">
                <span :class="branchId==item.id? 'searchText':''"> {{ item.companyName }}</span>
              </div>
            </div>
            <div class="czsx_title">
              <img src="../../assets/images/monitoring/sxcqdw.png">&nbsp;&nbsp;
              产权
            </div>
            <div class="czsx_div">
              <div style="width:1.5rem;" v-for="(item,index) in propertyData" :key="index" @click="storageTwoClick(item)">

                <span :class="propertyId==item.id? 'searchText':''">{{ item.companyName }}</span>
              </div>
            </div>
            <div class="czsx_title">
              <div class="screen-subtitle">
                <img src="../../assets/images/monitoring/sxcz.png">&nbsp;&nbsp;
                场站
              </div>
              <div class="search">
                <el-input v-model="stationName" placeholder="请根据关键词进行搜索"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="getStorageThree()">搜索</el-button>
              </div>
              <div class="czsx_div">
                <div style="width:2.05rem;margin-right:0.05rem;" v-for="(item,index) in stationData" :key="index"
                     @click="storageThreeClick(item)">
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="dialog-footer">
              <button>取消</button>
              <button>确定</button>
          </div> -->
          <div class="" style="position: absolute; top:20px; right: 0.2rem;width: 16px;height: 16px;">
            <i class="el-icon-close" @click="sxDialogShow" style="font-size: 16px;position: absolute"></i>
          </div>
        </div>
<!--        <div class="main_tq">-->
<!--          <div class="tq_feng" v-if="tq.tqxx=='大风'">-->
<!--            <div class="feng_name">-->
<!--              {{ '大风' }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="tq_feng" v-if="tq.tqxx=='暴雨'">-->
<!--            <div class="feng_name">-->
<!--              {{ '暴雨' }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
<!--      源详情弹窗-->
      <div class="pop-ups" v-if="mapDialog">
        <div class="dialog-title">
          <img src="../../assets/images/cockpit/mark.png" alt=""/>
          <p>{{ mapObj.name }}</p>
        </div>
        <ul class="dialog-content">
<!--          <li class="year-electricity">-->
<!--            <div>-->
<!--              <span>{{ mapObj.yearPower }}</span>-->
<!--              <p>年总用电量 (GWh)</p>-->
<!--            </div>-->
<!--          </li>-->
          <li class="dialog-form">
            <div>客户名称:</div>
            <p>&nbsp{{ mapObj.copatyInfo.companyName }}</p>
          </li>
          <li class="dialog-form">
            <div>场站形式: </div>
            <p v-if="mapObj.type == '1'">&nbsp{{'火力发电站'}}</p>
            <p v-if="mapObj.type == '2'">&nbsp{{'水力发电站'}}</p>
            <p v-if="mapObj.type == '3'">&nbsp{{'核电站'}}</p>
            <p v-if="mapObj.type == '4'">&nbsp{{'风力发电站'}}</p>
            <p v-if="mapObj.type == '5'">&nbsp{{'分布式光伏发电站'}}</p>
          </li>
          <li class="dialog-form">
            <div>装机容量:</div>
            <p>&nbsp{{ mapObj.capacity }}&nbspkva</p>
          </li>
          <li class="dialog-form">
            <div>场站地址:</div>
            <el-tooltip class="item" effect="dark" :content="mapObj.address" placement="top">
              <p>&nbsp{{ mapObj.address.length > 8 ? mapObj.address.substring(0,8) + '...' : mapObj.address.substring(0,8) }}</p>
            </el-tooltip>
          </li>
<!--          <li class="dialog-form">-->
<!--            <div>昨日用电量:</div>-->
<!--            <p>{{ mapObj.yesterdayElectricity }}kWh</p>-->
<!--          </li>-->
          <!-- <li class="dialog-form">
            <div>查看视频:</div>
            <img style="margin-left: 16px" src="../../assets/images/cockpit/camera.png" alt="" />
          </li> -->
<!--          <li>-->
<!--            <button style="cursor:pointer;" @click="goDetail('0')">查看详细 >></button>-->
<!--          </li>-->
        </ul>
        <div class="dialog-button dialog-content">
          <button style="cursor:pointer;" @click="goDetail('0')">查看详细 >></button>
        </div>
      </div>
<!--      网详情弹窗-->
      <div class="pop-ups" v-if="mapDialogNetwork">
        <div class="dialog-title">
          <img src="../../assets/images/cockpit/mark.png" alt=""/>
          <p>{{ mapObjNetwork.stationName }}</p>
        </div>
        <ul class="dialog-content">
<!--          <li class="year-electricity">-->
<!--            <div>-->
<!--              <span>{{ mapObjNetwork.yearPower }}</span>-->
<!--              <p>年总用电量 (GWh)</p>-->
<!--            </div>-->
<!--          </li>-->
          <li class="dialog-form">
            <div>客户名称:</div>
            <p>&nbsp{{ mapObjNetwork.companyName }}</p>
          </li>
          <li class="dialog-form">
            <div>场站形式:</div>
            <p v-if="mapObjNetwork.type == '1'">&nbsp{{ '配电室' }}</p>
            <p v-if="mapObjNetwork.type == '2'">&nbsp{{ '箱变' }}</p>
            <p v-if="mapObjNetwork.type == '3'">&nbsp{{ '柱变' }}</p>
            <p v-if="mapObjNetwork.type == '4'">&nbsp{{ '强电间' }}</p>
            <p v-if="mapObjNetwork.type == '5'">&nbsp{{ '35kv/10kv开关站' }}</p>
            <p v-if="mapObjNetwork.type == '6'">&nbsp{{ '220kv/110kv变电站' }}</p>
            <p v-if="mapObjNetwork.type == '7'">&nbsp{{ '10kv分界室' }}</p>
            <p v-if="mapObjNetwork.type == '8'">&nbsp{{ 'xxkv升压站' }}</p>
          </li>
          <li class="dialog-form">
            <div>装机容量:</div>
            <p>&nbsp{{ mapObjNetwork.grossRatedCapacity }}&nbsp kva</p>
          </li>
          <li class="dialog-form">
            <div>场站地址:</div>
            <el-tooltip class="item" effect="dark" :content="mapObjNetwork.address" placement="top">
              <p>&nbsp{{ mapObjNetwork.address.length > 8 ? mapObjNetwork.address.substring(0,8) + '...' : mapObjNetwork.address.substring(0,8) }}</p>
            </el-tooltip>
          </li>
<!--          <li class="dialog-form">-->
<!--            <div>昨日用电量:</div>-->
<!--            <p>{{ mapObjNetwork.yesterdayElectricity }}kWh</p>-->
<!--          </li>-->
          <!-- <li class="dialog-form">
            <div>查看视频:</div>
            <img style="margin-left: 16px" src="../../assets/images/cockpit/camera.png" alt="" />
          </li> -->
<!--          <li>-->
<!--            <button style="cursor:pointer;" @click="goDetail('1')">查看详细 >></button>-->
<!--          </li>-->
        </ul>
        <div class="dialog-button dialog-content">
          <button style="cursor:pointer;" @click="goDetail('1')">查看详细 >></button>
        </div>
      </div>
<!--      荷详情弹窗-->
      <div class="pop-ups" v-if="mapDialogLotus">
        <div class="dialog-title">
          <img src="../../assets/images/cockpit/mark.png" alt=""/>
          <p>{{ mapObjLotus.stationName }}</p>
        </div>
        <ul class="dialog-content">
<!--          <li class="year-electricity">-->
<!--            <div>-->
<!--              <span>{{ mapObjLotus.yearPower }}</span>-->
<!--              <p>年总用电量 (GWh)</p>-->
<!--            </div>-->
<!--          </li>-->
          <li class="dialog-form">
            <div>客户名称:</div>
            <p>&nbsp{{ mapObjLotus.companyInfoName }}</p>
          </li>
          <li class="dialog-form">
            <div>场站形式:</div>
            <p v-if="mapObjLotus.type == '1'">&nbsp{{ '配电室' }}</p>
            <p v-if="mapObjLotus.type == '2'">&nbsp{{ '箱变' }}</p>
            <p v-if="mapObjLotus.type == '3'">&nbsp{{ '柱变' }}</p>
            <p v-if="mapObjLotus.type == '4'">&nbsp{{ '强电间' }}</p>
            <p v-if="mapObjLotus.type == '5'">&nbsp{{ '35kv/10kv开关站' }}</p>
            <p v-if="mapObjLotus.type == '6'">&nbsp{{ '220kv/110kv变电站' }}</p>
            <p v-if="mapObjLotus.type == '7'">&nbsp{{ '10kv分界室' }}</p>
            <p v-if="mapObjLotus.type == '8'">&nbsp{{ 'xxkv升压站' }}</p>
          </li>
          <li class="dialog-form">
            <div>设备数量:</div>
            <p>&nbsp{{ mapObjLotus.loadEquipmentAmount }}</p>
          </li>
          <li class="dialog-form">
            <div>场站地址:</div>
            <el-tooltip class="item" effect="dark" :content="mapObjLotus.address" placement="top">
              <p>&nbsp{{ mapObjLotus.address.length > 8 ? mapObjLotus.address.substring(0,8) + '...' : mapObjLotus.address.substring(0,8) }}</p>
            </el-tooltip>
          </li>
<!--          <li class="dialog-form">-->
<!--            <div>昨日用电量:</div>-->
<!--            <p>{{ mapObjLotus.yesterdayElectricity }}kWh</p>-->
<!--          </li>-->
          <!-- <li class="dialog-form">
            <div>查看视频:</div>
            <img style="margin-left: 16px" src="../../assets/images/cockpit/camera.png" alt="" />
          </li> -->
<!--          <li>-->
<!--            <button style="cursor:pointer;" @click="goDetail('2')">查看详细 >></button>-->
<!--          </li>-->
        </ul>
        <div class="dialog-button dialog-content">
          <button style="cursor:pointer;" @click="goDetail('2')">查看详细 >></button>
        </div>
      </div>
<!--      储详情弹窗-->
      <div class="pop-ups" v-if="mapDialogStore">
        <div class="dialog-title">
          <img src="../../assets/images/cockpit/mark.png" alt=""/>
          <p>{{ mapObjStore.name }}</p>
        </div>
        <ul class="dialog-content">
<!--          <li class="year-electricity">-->
<!--            <div>-->
<!--              <span>{{ mapObjStore.yearPower }}</span>-->
<!--              <p>年总用电量 (GWh)</p>-->
<!--            </div>-->
<!--          </li>-->
          <li class="dialog-form">
            <div>客户名称:</div>
            <p>&nbsp{{ mapObjStore.copatyInfo.companyName }}</p>
          </li>
          <li class="dialog-form">
            <div>场站形式:</div>
            <p v-if="mapObjStore.type == '1'">&nbsp{{ '锂离子电池储能' }}</p>
            <p v-if="mapObjStore.type == '2'">&nbsp{{ '铅酸电池储能' }}</p>
            <p v-if="mapObjStore.type == '3'">&nbsp{{ '钠硫电池储能' }}</p>
          </li>
          <li class="dialog-form">
            <div>装机容量:</div>
            <p>&nbsp{{ mapObjStore.capacity }}&nbspkva</p>
          </li>
          <li class="dialog-form">
            <div>场站地址:</div>
            <el-tooltip class="item" effect="dark" :content="mapObjStore.address" placement="top">
              <p>&nbsp{{ mapObjStore.address.length > 8 ? mapObjStore.address.substring(0,8) + '...' : mapObjStore.address.substring(0,8) }}</p>
            </el-tooltip>
          </li>
<!--          <li class="dialog-form">-->
<!--            <div>昨日用电量:</div>-->
<!--            <p>{{ mapObjStore.yesterdayElectricity }}kWh</p>-->
<!--          </li>-->
          <!-- <li class="dialog-form">
            <div>查看视频:</div>
            <img style="margin-left: 16px" src="../../assets/images/cockpit/camera.png" alt="" />
          </li> -->
<!--          <li>-->
<!--            <button style="cursor:pointer;" @click="goDetail('3')">查看详细 >></button>-->
<!--          </li>-->
        </ul>
        <div class="dialog-button dialog-content">
          <button style="cursor:pointer;" @click="goDetail('3')">查看详细 >></button>
        </div>
      </div>
      <div class="right_main">
        <div class="main_one">
          <div id="one_echarts">

          </div>
          <div class="one_title" style="width:5.71rem;">
            配电形式情况统计
          </div>
          <div id="two_echarts"></div>
          <div class="boxs">
            <div class="box" v-for="(item,index) in colorList" :key="index">
              <div class="one" :style="{'background':item.color}"></div>
<!--              <div class="two" style="background:#0cbf76"></div>-->
              <div class="three">{{item.label + ':' + item.percent + '%'}}</div>
            </div>
          </div>
        </div>
        <div class="main_two" style="z-index:1990">
          <!--                <el-select  class="custom-select" clearable  v-model="dictType" :popper-append-to-body="false" style="z-index:1990;position: absolute;-->
          <!--                    right: 50px;-->
          <!--                    top:40px;-->
          <!--                    width: 0.9rem;-->
          <!--                    border:1px solid #0E447E;" placeholder="请选择" @change="dictChange">-->
          <!--                        <el-option-->
          <!--                        v-for="item in dictList"-->
          <!--                        :key="item.dictValue"-->
          <!--                        :label="item.dictName"-->
          <!--                        :value="item.dictValue">-->
          <!--                        </el-option>-->
          <!--                    </el-select>-->
          <div id="three_echarts">
          </div>
          <div class="one_title" style="width:5.71rem;margin-left:1.2rem !important;">
            储能场站投产年限统计
          </div>
          <!--                <el-select class="custom-select"  v-model="value" :popper-append-to-body="false" style="z-index:1990;position: absolute;-->
          <!--                    right: 50px;-->
          <!--                    top:240px;-->
          <!--                    width: 0.9rem;-->
          <!--                    border:1px solid #0E447E;" placeholder="请选择" @change="stationChange">-->
          <!--                        <el-option-->
          <!--                        v-for="item in options"-->
          <!--                        :key="item.value"-->
          <!--                        :label="item.label"-->
          <!--                        :value="item.value">-->
          <!--                        </el-option>-->
          <!--                    </el-select>-->
          <div id="four_echarts">

          </div>
        </div>
      </div>
      <div class="total-electricity">
        <div class="text">
          <img style="margin-right:0.1rem;" src="../../assets/images/monitoring/nyjkzts.png" alt=""/>
          <span>能源监控总天数</span>
        </div>
        <ul class="total">
          <!-- <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li> -->
          <li v-for=" (item , index ) in timeDays" :keys="index">{{ item }}</li>
        </ul>
      </div>
      <div class="right_nav" @click="jump()">

      </div>
      <div class="monitoringFooter"></div>
    </div>
  </v-scale-screen>
</template>
<script type="text/javascript" src="https://webapi.amap.com/maps?v=2.0&key=afad6a07f4f30bc114ad71ca1da43a93">
</script>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import bus from "@/utils/bus.js";
import moment from "moment";
import "moment/locale/zh-cn"; // 引入中文语言包
import {monitoringLeftApi} from '@/api/monitoringLeft.js'
import * as echarts from "echarts";
import L from 'leaflet';
import data from '@/api/china.json'
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import mapstyle from '../../assets/js/style'
import {getWeatherHourData} from "@/api/map.js";
import {loginApi} from "@/api/login.js";
import {cockpitApi} from "@/api/cockpit";
import {cockpitSecondApi} from "@/api/cockpitSecond";

export default {
  data() {
    return {
      colorList: [],
      infoWindow: null, //信息窗口
      positionData: [],
      tab: '0',
      tabNavList:[
        {label: '源',value: '0'},
        {label: '网',value: '1'},
        {label: '荷',value: '2'},
        {label: '储',value: '3'},
      ],
      AMap: null,
      map: null,
      sxDialog: false,
      mapDialog: false,
      mapDialogNetwork: false,
      mapObjNetwork: {},
      mapDialogLotus: false,
      mapObjLotus: {},
      mapDialogStore: false,
      mapObjStore: {},
      value: 1,
      center: [118.83009, 39.75619],
      zoom: 14,
      mouseTool: null,
      marker: null,
      allowCollision: false,//标注避让marker
      markers: [],
      layer: null,
      timeDays: [],
      options: [
        {label: '地区', value: 1},
        {label: '分中心', value: 2},
      ],
      dictList: [],
      dictType: '',
      mapstyle: [],
      userInfo: {},
      stationName: '',
      tabShow: true,
      tabShow1: false,
      tabShow2: false,
      tabShow3: false,
      tq: {
        feng: '',
        yu: ''
      },
      statistics: {},
      List: [
        {
          imgUrl: require('../../assets/images/monitoring/glzx.png'),
          grade: '0',
          name: '发电站数量 (个)'
        },
        {
          imgUrl: require('../../assets/images/monitoring/cz.png'),
          grade: '0',
          name: '配电站数量 (个)'
        },
        {
          imgUrl: require('../../assets/images/monitoring/cz.png'),
          grade: '0',
          name: '储能站数量 (个)'
        },
      ],
      List2: [
        {
          imgUrl: require('../../assets/images/monitoring/co2.png'),
          grade: '0',
          name: '总碳排放量 (万吨)'
        },
        {
          imgUrl: require('../../assets/images/monitoring/jsbzm.png'),
          grade: '0',
          name: '减少标准煤 (万吨)'
        },
      ],
      List3: [
        {
          imgUrl: require('../../assets/images/monitoring/zydl.png'),
          name: '总发电量',
          name2: '(Gwh)'
        },
        {
          imgUrl: require('../../assets/images/monitoring/zyql.png'),
          name: '总用电量',
          name2: '(Gwh)'
        },
        {
          imgUrl: require('../../assets/images/monitoring/zysl.png'),
          name: '储能总容量',
          name2: '(Gwh)'
        },
      ],
      List4: [
        {
          imgUrl: require('../../assets/images/monitoring/yearDian.png'),
          name: '0',
          name2: '当年'
        },
        {
          imgUrl: require('../../assets/images/monitoring/yearQi.png'),
          name: '0',
          name2: '当年'
        },
        {
          imgUrl: require('../../assets/images/monitoring/yearShui.png'),
          name: '0',
          name2: '储能实时容量'
        },
      ],
      List5: [
        {
          imgUrl: require('../../assets/images/monitoring/monthDian.png'),
          name: '354',
          name2: '当月'
        },
        {
          imgUrl: require('../../assets/images/monitoring/monthQi.png'),
          name: '13.5',
          name2: '当月'
        },
        {
          imgUrl: require('../../assets/images/monitoring/monthShui.png'),
          name: '10451',
          name2: '总充电量'
        },
      ],
      List6: [
        {
          imgUrl: require('../../assets/images/monitoring/dayDian.png'),
          name: '0',
          name2: '当日'
        },
        {
          imgUrl: require('../../assets/images/monitoring/dayQi.png'),
          name: '0',
          name2: '当日'
        },
        {
          imgUrl: require('../../assets/images/monitoring/dayShui.png'),
          name: '0',
          name2: '总放电量'
        },
      ],
      List7: [
        {
          noticeTitle: '国家能源局: 大型工商业分布式光伏必须全部自发自用',
          releaseDate: '2024-10-09',
          imgUrl: require('../../assets/images/monitoring/xwdt1.png')
        },
        {
          noticeTitle: '李强:推动传统能源和新能源多能互补，大力推动煤炭清洁高效利用',
          releaseDate: '2024-10-25',
          imgUrl: require('../../assets/images/monitoring/xwdt1.png')
        },
        {
          noticeTitle: '国家发展改革委 国家能源局 国家数据局印发《加快构建新型电力系统行动方案(2024-2027年)》',
          releaseDate: '2024-08-08',
          imgUrl: require('../../assets/images/monitoring/xwdt1.png')
        },
        {
          noticeTitle: '多地对用电峰谷时段进行调整 分时电价持续加大精准调控',
          releaseDate: '2024-06-29',
          imgUrl: require('../../assets/images/monitoring/xwdt1.png')
        }
      ],
      List8: [
        {
          noticeTitle: '乐能智脑:让每一度电都物超所值',
          releaseDate: '2024-10-19',
          imgUrl: require('../../assets/images/monitoring/xwdt1.png')
        },
        {
          noticeTitle: '乐能仕助力配网智能化升级',
          releaseDate: '2024-10-18',
          imgUrl: require('../../assets/images/monitoring/xwdt1.png')
        },
        {
          noticeTitle: '2024第七届智能建筑展览会,博雅数智化赋能建筑绿色低碳',
          releaseDate: '2024-10-17',
          imgUrl: require('../../assets/images/monitoring/xwdt1.png')
        },
        {
          noticeTitle: '乐能工场推动北京公交集团能源管理创新升级',
          releaseDate: '2024-10-16',
          imgUrl: require('../../assets/images/monitoring/xwdt1.png')
        }
      ],
      fzxList: [],
      cqdwList: [],
      branchId: '',
      propertyId: '',
      branchData: [],
      propertyData: [],
      stationData: [],
      stationId: '',
      nowDate: "", // 当前日期
      nowTime: "", // 当前时间
      nowWeek: "", // 当前星期

    }
  },
  mounted() {
    // this.setWeather()
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'));
    this.currentTime()
    // this.getTime()
    // this.initMap()
    // window.addEventListener('resize', () => {
    //     // 当浏览器缩放发生变化时，重新设置地图中心点和缩放级别
    //     this.map.updateSize();
    // });
    // this.energyStations()
    // this.queryDictionary()


  },
  created() {
    this.getSourceStationsThree()
    this.getCountByType()
    this.getWithOperationDuration()
    this.getSelectPowergStationAgeLimit()
    this.getSelectStorageStationAgeLimit()
    this.getGetAggregatedEnergyStats()
  },
  methods: {
    //配电形式情况统计
    getCountByType() {
      monitoringLeftApi.countByType().then((res) => {
        if (res.code === 200) {
          let arr = Object.values(res.data)
          arr.forEach(item => {
            if (item.type == '1'){
              this.$set(item,'color','#f1bd47')
              this.$set(item,'label','配电室')
            }else if(item.type == '2') {
              this.$set(item,'color','#176ad2')
              this.$set(item,'label','箱变')
            }else if(item.type == '3') {
              this.$set(item,'color','#0cbf76')
              this.$set(item,'label','柱变')
            }else if(item.type == '4') {
              this.$set(item,'color','#ef8040')
              this.$set(item,'label','强电间')
            }else if(item.type == '5') {
              this.$set(item,'color','#18bfe9')
              this.$set(item,'label','35kv/10kv开关站')
            }else if(item.type == '6') {
              this.$set(item,'color','#be0000')
              this.$set(item,'label','220kv/110kv变电站')
            }else if(item.type == '7') {
              this.$set(item,'color','#ec4c3b')
              this.$set(item,'label','10kv分界室')
            }else if(item.type == '8') {
              this.$set(item,'color','#b052ff')
              this.$set(item,'label','XXKV升压站')
            }
          })
          this.colorList = arr
          this.setchart3(arr)
        }
      });
    },
    setchart3(data) {
      let myChart = echarts.init(document.getElementById('two_echarts'))
      // 绘制图表
      let arrX = []
      let arrY = []
      let arrColor = []
      data.forEach(item => {
        arrY.push(item.count)
        arrColor.push(item.color)
      })
      var option = {
        color: arrColor,
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   type: 'scroll',
        //   orient: 'vertical',
        //   icon:'circle',
        //   left:200,
        //   top: 0,
        //   bottom: 20,
        //   data: distName,
        //   itemHeight:10,
        //   textStyle:{
        //       fontSize:10,
        //       color:'#BCCAF3'
        //   },
        // },
        series: [
          {
            type: 'pie',
            radius: ['60%', '72%'],
            avoidLabelOverlap: false,
            center: ['102', '85'],
            label: {
              show: false,

            },
            labelLine: {
              show: true
            },
            itemStyle: {
              borderWidth: 0,
              borderColor: '#fff',
              borderRadius: 0,
              emphasis: {
                scale: false  // 设置为 false，表示在 hover 时大小不改变
              }
            },
            // data: distValue,
            data: arrY,
          }
        ]
      };
      myChart.setOption(option);
    },

    //配电场站投产年限统计
    getWithOperationDuration() {
      monitoringLeftApi.withOperationDuration().then((res) => {
        if (res.code === 200) {
          this.setchart4(res.data)
        }
      });
    },
    setchart4(data) {
      let myChart4 = echarts.init(document.getElementById("three_echarts"));
      let arrX = []
      let arrY = []
      data.forEach(item => {
        arrX.push(item.stationName)
        arrY.push(item.operationDuration)
      })
      var option = {
        tooltip: {
          trigger: 'item'
        },
        grid: {
          left: 30,
          top: 30,
          right: 30,
          bottom: 20
        },
        legend: {
          icon: "circle",
          right: 100,
          itemWidth: 10,
          itemHeight: 10,
          top: 0,
          textStyle: {
            fontSize: 10,
            color: "#C5D8FF",
          },
        },
        xAxis: {
          type: 'category',
          data: arrX,
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          name: "单位：年",
          nameTextStyle: {
            color: "#C5D8FF",
            fontSize: 10,
          },

          minInterval: 1, // 设置刻度间隔为1，确保只显示整数刻度
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "年限",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0], // 设置柱状图圆角
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {offset: 0, color: "#0CC076"},
                    // { offset: 0.5, color: "#091b4d" },
                    {offset: 1, color: "#2AD58F"},
                  ],
                  global: false,
                },
              },
            },
            data: arrY,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart4.setOption(option);
      // })
      // 指定图表的配置项和数据
    },

    //发电站寿命情况统计
    getSelectPowergStationAgeLimit() {
      monitoringLeftApi.selectPowergStationAgeLimit().then((res) => {
        if (res.code === 200) {
          this.setchart2(res.data)
        }
      });
    },
    setchart2(data) {
      let myChart2 = echarts.init(document.getElementById("one_echarts"));
      let arrx = []
      let arrY = []
      data.forEach(item => {
        arrx.push(item.name)
        arrY.push(item.ageLimit)
      })
      var option = {
        tooltip: {
          trigger: 'item'
        },
        grid: {
          left: 160,
          top: 100,
          bottom: 20
        },
        legend: {
          icon: "circle",
          right: 50,
          itemWidth: 10,
          itemHeight: 10,
          top: 80,
          textStyle: {
            fontSize: 10,
            color: "#C5D8FF",
          },
        },
        xAxis: {
          type: 'category',
          data: arrx,
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          name: "单位：年",
          nameTextStyle: {
            color: "#C5D8FF",
            fontSize: 10,
          },

          minInterval: 1, // 设置刻度间隔为1，确保只显示整数刻度
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: '年限',
            type: "bar",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0], // 设置柱状图圆角
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {offset: 0, color: "rgb(63, 142, 214)"},
                    // { offset: 0.5, color: "#091b4d" },
                    {offset: 1, color: "rgb(18, 55, 125)"},
                  ],
                  global: false,
                },
              },
            },
            data: arrY,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart2.setOption(option);
      // })
      // 指定图表的配置项和数据
    },


    //储能场站投产年限
    getSelectStorageStationAgeLimit() {
      monitoringLeftApi.selectStorageStationAgeLimit().then((res) => {
        if (res.code === 200) {
          this.setchart5(res.data)
        }
      });
    },
    setchart5(data) {
      let myChart5 = echarts.init(document.getElementById("four_echarts"));
      let arrX = []
      let arrY = []
      data.forEach(item => {
        arrX.push(item.name)
        arrY.push(item.ageLimit)
      })
      var option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          icon: "circle",
          right: 50,
          itemWidth: 10,
          itemHeight: 10,
          top: 0,
          textStyle: {
            fontSize: 10,
            color: "#C5D8FF",
          },
        },
        grid: {
          left: 150,
          top: 30,
          bottom: 30
        },
        xAxis: {
          type: 'category',
          data: arrX,
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          name: "单位：年",
          nameTextStyle: {
            color: "#C5D8FF",
            fontSize: 10,
          },

          minInterval: 1, // 设置刻度间隔为1，确保只显示整数刻度
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "年限",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0], // 设置柱状图圆角
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {offset: 0, color: "#F2BE49"},
                    // { offset: 0.5, color: "#091b4d" },
                    {offset: 1, color: "#CB9419"},
                  ],
                  global: false,
                },
              },
            },
            data: arrY,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart5.setOption(option);
      // })
      // 指定图表的配置项和数据
    },
    //聚合能源概况统计
    getGetAggregatedEnergyStats(){
      monitoringLeftApi.getAggregatedEnergyStats().then((res) => {
        if (res.code === 200) {
          console.log(res.data)
          this.timeDays = String(res.data.totalMonitoringDays).split('').map(Number)
          this.$set(this.List[0],'grade',res.data.psCount)
          this.$set(this.List[1],'grade',res.data.ssCount)
          this.$set(this.List[2],'grade',res.data.esCount)

          this.$set(this.List2[0],'grade',Number(res.data.carbonEmission).toFixed(2))
          this.$set(this.List2[1],'grade',Number(res.data.coalEquivalent).toFixed(2))

          this.$set(this.List4[0],'name',Number(res.data.psAnnualOutput / 1000000000).toFixed(2))
          this.$set(this.List4[1],'name',Number(res.data.ssAnnualUsage / 1000000000).toFixed(2))
          this.$set(this.List4[2],'name',Number(res.data.esRtCapacity / 1000000000).toFixed(2))

          this.$set(this.List5[0],'name',Number(res.data.psMonthlyOutput / 1000000000).toFixed(2))
          this.$set(this.List5[1],'name',Number(res.data.ssMonthlyUsage / 1000000000).toFixed(2))
          this.$set(this.List5[2],'name',Number(res.data.esTotalCharge / 1000000000).toFixed(2))

          this.$set(this.List6[0],'name',Number(res.data.psDailyOutput / 1000000000).toFixed(2))
          this.$set(this.List6[1],'name',Number(res.data.ssDailyUsage / 1000000000).toFixed(2))
          this.$set(this.List6[2],'name',Number(res.data.esTotalDischarge / 1000000000).toFixed(2))
        }
      });
    },


    //源一级点击
    sourceOneClick(item) {
      if (this.branchId == item.id) {
        this.branchId = null
      } else {
        this.branchId = item.id
      }
      this.getSourceStationThree()
    },
    //源二级点击
    sourceTwoClick(item) {
      if (this.propertyId == item.id) {
        this.propertyId = null
      } else {
        this.propertyId = item.id
      }
      this.getSourceStationThree()
    },
    //源三级点击
    sourceThreeClick(item) {
      this.stationId = item.id
      this.sxDialog = false
      this.mapCenter(item)
      if (!this.sxDialog) {
        this.branchId = null
        this.propertyId = null
      }
    },
    //获取源第三级
    getSourceStationThree() {
      let params = {
        ownerCompanyId: this.branchId,
        operationCompanyId: this.propertyId,
        parameter: this.stationName
      };
      cockpitApi.powergenStationListById(JSON.stringify(params)).then((res) => {
        this.stationData = res.data;
      });
    },
    //获取源三个级别列表
    getSourceStationsThree(){
      cockpitApi.powergenStationList().then((res) => {
        this.branchData = res.data.companyInfoList;
        this.propertyData = res.data.propertyUnits;
        this.stationData = res.data.stationInfos;
        this.initMap();
      });
    },
    //获取网三个级别列表
    getNetworkStationsThree() {
      let params = {
        companyId: this.branchId,
        propertyUnit: this.propertyId,
        stationName: this.stationName,
      };
      monitoringLeftApi.stationDialog(params).then((res) => {
        this.branchData = res.data.companyInfos;
        this.propertyData = res.data.optCompanyInfos;
        this.stationData = res.data.stationInfos;
        this.initMap();
      });
    },
    //网一级点击
    networkOneClick(item) {
      if (this.branchId == item.id) {
        this.branchId = null
      } else {
        this.branchId = item.id
      }
      this.getNetworkStationsThree()
    },
    //网二级点击
    networkTwoClick(item) {
      if (this.propertyId == item.id) {
        this.propertyId = null
      } else {
        this.propertyId = item.id
      }
      this.getNetworkStationsThree()
    },
    //网三级点击
    networkThreeClick(item) {
      this.stationId = item.id
      this.sxDialog = false
      this.mapCenterNetwork(item)
      if (!this.sxDialog) {
        this.branchId = null
        this.propertyId = null
      }
    },
    //荷一级点击
    lotusOneClick(item) {
      if (this.branchId == item.id) {
        this.branchId = null
      } else {
        this.branchId = item.id
      }
      this.getLotusThree()
    },
    //荷二级点击
    lotusTwoClick(item) {
      if (this.propertyId == item.id) {
        this.propertyId = null
      } else {
        this.propertyId = item.id
      }
      this.getLotusThree()
    },
    //荷三级点击
    lotusThreeClick(item) {
      this.stationId = item.id
      this.sxDialog = false
      this.mapCenterLotus(item)
      if (!this.sxDialog) {
        this.branchId = null
        this.propertyId = null
      }
    },
    //获取荷三个级别列表
    getLotusThree(){
      let params = {
        companyId: this.branchId,
        propertyUnit: this.propertyId,
        stationName: this.stationName,
      };
      cockpitSecondApi.queryStation(params).then((res) => {
        this.branchData = res.data.companyInfos;
        this.propertyData = res.data.optCompanyInfos;
        this.stationData = res.data.stationInfos;
        this.initMap();
      });
    },
    //储一级点击
    storageOneClick(item) {
      if (this.branchId == item.id) {
        this.branchId = null
      } else {
        this.branchId = item.id
      }
      this.getStorageThree()
    },
    //储二级点击
    storageTwoClick(item) {
      if (this.propertyId == item.id) {
        this.propertyId = null
      } else {
        this.propertyId = item.id
      }
      this.getStorageThree()
    },
    //储三级点击
    storageThreeClick(item) {
      this.stationId = item.id
      this.sxDialog = false
      this.mapCenterStore(item)
      if (!this.sxDialog) {
        this.branchId = null
        this.propertyId = null
      }
    },
    //获取储三个级别列表
    getStorageStationsThree() {
      cockpitApi.stationList().then(res => {
        if (res.code == '200') {
          this.branchData = res.data.companyInfoList;
          this.propertyData = res.data.propertyUnits;
          this.stationData = res.data.stationInfos;
          this.initMap();
        }
      })
    },
    //查询第三级储
    getStorageThree(){
      const param = {
        ownerCompanyId:this.branchId,
        operationCompanyId: this.propertyId,
        parameter: this.stationName
      }
      cockpitApi.selectListById(JSON.stringify(param)).then(res => {
        if (res.code == '200') {
          this.stationData = res.data
        }
      })
    },



    clickNav(index) {
      this.branchId = ''
      this.propertyId = ''
      this.stationName = ''
      this.tab = index
      if (index == '0'){
        this.tabShow = true
        this.tabShow1 = false
        this.tabShow2 = false
        this.tabShow3 = false
        this.getSourceStationsThree()
      }else if(index == '1') {
        this.tabShow = false
        this.tabShow1 = true
        this.tabShow2 = false
        this.tabShow3 = false
        this.getNetworkStationsThree()
      }else if(index == '2') {
        this.tabShow = false
        this.tabShow1 = false
        this.tabShow2 = true
        this.tabShow3 = false
        this.getLotusThree()
      }else if(index == '3') {
        this.tabShow = false
        this.tabShow1 = false
        this.tabShow2 = false
        this.tabShow3 = true
        this.getStorageStationsThree()
      }
    },
    //天气接口
    setWeather() {
      // 创建一个 Date 对象
      var now = new Date();
      // 获取当前年份
      var currentYear = now.getFullYear();
      // 获取当前月份（注意，月份是从0开始计数的，所以需要加1）
      var currentMonth = now.getMonth() + 1;
      // 获取当前日期
      var currentDate = now.getDate();
      //获取当前小时
      var currentHour = now.getHours();
      if (currentMonth < 10) {
        currentMonth = '0' + currentMonth
      }
      if (currentDate < 10) {
        currentDate = '0' + currentDate
      }
      let param = {
        qybms: '110000',
        fPeriod: currentYear + "-" + currentMonth + "-" + currentDate + " " + currentHour + ':00',
        tPeriod: currentYear + "-" + currentMonth + "-" + currentDate + " " + currentHour + ':00',
      }
      monitoringLeftApi.getWeatherHourData(param).then(res => {
        if (res.code == 200) {
          // this.tq = res.data[0].sdtqInfos[0]
        }
      })
    },
    //跳转到第二屏幕
    jump() {
      this.$router.push('/monitoringLeft2')
    },
    //跳转到详情
    goDetail(data) {
      if (data == '0') {
        sessionStorage.setItem("stationId", this.mapObj.id);
        this.$router.push(
            {
              path:'/source',
              // query: {
              //   id: this.mapObj.id
              // }
            }
        )
      }else if(data == '1') {
        sessionStorage.setItem("stationId", this.mapObjNetwork.id);
        sessionStorage.setItem("stationNumber", this.mapObjNetwork.stationNumber);
        sessionStorage.setItem("stationName", this.mapObjNetwork.stationName);
        sessionStorage.setItem("componentStatus", 1);
        this.$store.commit("updateStatus", 1);
        this.$router.push(
            {
              path:'/cockpit/electricity',
              // query: {
              //   id: this.mapObjNetwork.id
              // }
            }
        )
      }else if(data == '2') {
        sessionStorage.setItem("stationId", this.mapObjLotus.id);
        this.$router.push(
            {
              path:'/lotusDetail',
              // query: {
              //   id: this.mapObjLotus.id
              // }
            }
        )
      }else if(data == '3') {
        sessionStorage.setItem("stationId", this.mapObjStore.id);
        this.$router.push(
            {
              path:'/energy-storage',
              // query: {
              //   id: this.mapObjStore.id
              // }
            }
        )
      }
    },
    initMap() {
      var _this = this;
      // https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png
      AMapLoader.load({
        key: "b2dbbbed005d9afd3ec0014d87fb5778",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.LineSearch'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        _this.map = new AMap.Map("bmap", {  //设置地图容器id
          viewMode: "3D",    //是否为3D地图模式
          zoom: 10,           //初始化地图级别
          expandZoomRange: true, //支持可以扩展最大缩放级别
          center: ['116.441504', '40.031386'], //初始化地图中心点位置
          // pitch: 30, //俯仰角度 2D地图下无效,自V1.4.0开始支持
          // mapStyle: 'amap://styles/normal' //地图样式，彩色
          mapStyle: "amap://styles/darkblue", //自定义地图的显示样式

        });
        _this.map.on('complete', () => {
          // if(res.state == 200 && res.data.length){
          //处理后台返回要用到的数据
          let czList = this.stationData;
          console.log("czlist", czList);
          // let czList = [
          //     {
          //         lng:116.1142,
          //         lat:39.7497,
          //         name:'北京市房山区燕山电站',
          //         stationNumber:1,
          //         propertyName:'1',
          //         id:'1'

          //     },
          //     {
          //         lng:116.4798,
          //         lat:39.8734,
          //         name:'北京市朝阳区潘家园电站',
          //         stationNumber:2,
          //         propertyName:'2',
          //         id:'2'

          //     },

          // ]
          _this.positionData = czList.map(item => ({
            "positionArr": [item.longitude, item.latitude],
            "stationName": item.name,
            // "stationNumber": item.stationNumber,
            // "propertyName": item.propertyName,
            "id": item.id,
          }));
          _this.mapPoint();
          // }
        })
        //放大/缩小的工具条----地图右下角的 + 和 - 按钮
        _this.map.plugin(["AMap.ToolBar"], function () {
          _this.map.addControl(new AMap.ToolBar());
        });
        if (location.href.indexOf('&guide=1') !== -1) {
          _this.map.setStatus({scrollWheel: false})
        }
      }).catch(e => {
        console.log(e);
      })
    },
    mapZoom() {
      this.mapDialog = false
      this.mapDialogNetwork = false
      this.mapDialogLotus = false
      this.mapDialogStore = false
    },
    mapPoint() {
      var _this = this;
      /* 1.创建 LabelMarker，
      2.创建 LabelsLayer，将已创建的 LabelMarker 添加到 LabelsLayer 上
      3. 将 LabelsLayer 添加到地图实例 */
      var labelsLayer = new AMap.LabelsLayer({
        zooms: [2, 15],
        zIndex: 1000,
        // 该层内标注是否避让
        collision: true,
        // 设置 allowCollision：true，可以让标注避让用户的标注
        allowCollision: true,
      });
      //将 LabelsLayer 添加到地图实例
      _this.map.add(labelsLayer);
      _this.infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, -4)});//信息窗口
      var position = JSON.parse(JSON.stringify(_this.positionData));
      for (var i = 0; i < position.length; i++) {
        var labelMarker = new AMap.LabelMarker({
          position: position[i].positionArr
        });

        //标注信息
        var normalMarker = new AMap.Marker({
          position: position[i].positionArr,
          map: _this.map,
          icon: require("@/assets/images/monitoring/mapMarker.png")
        });
        normalMarker.content = '';
        normalMarker.on('click', _this.onMouseClick);
        normalMarker.setExtData(position[i]); ///给标注添加信息，点击时可以获取
      }

      // 一次性将海量点添加到图层
      labelsLayer.add(labelMarker);
      this.loading = false; //地图加载完成
      _this.map.on('zoomchange', this.mapZoom())
    },
    markerClick(e) {
      this.mapDialog = false
      this.mapDialogNetwork = false
      this.mapDialogLotus = false
      this.mapDialogStore = false
      //这个只是标注的点击事件
      // this.map.setZoomAndCenter(8,e.target.getPosition()); //点击标点放大地图-缺点，不管放大/缩小多少倍，点击标点都会被缩放到8倍
      // this.infoWindow.setContent(e.target.content);
      // this.infoWindow.open(this.map, e.target.getPosition());
    },
    onMouseClick(e) {
      let extData = e.target.getExtData()
      this.mapDialog = false;
      this.mapDialogNetwork = false;
      this.mapDialogLotus = false;
      this.mapDialogStore = false;
      var lat = e.lnglat.latitude
      var lng = e.lnglat.longitude
      this.stationData.forEach(Element => {
        if (extData.id == Element.id) {
          lng = Element.longitude
          lat = Element.latitude
        }
      })
      this.map.panTo([lng, Number(lat)])
      if (extData.id) {
        if (this.tab == '0'){
          cockpitApi.powergenSelectInfoById({id: extData.id}).then(res => {
            if (extData.id) {
              this.mapDialog = true
              this.mapObj = res.data;
              this.mapObj.id = extData.id
            }
          })
        }else if(this.tab == '1') {
          monitoringLeftApi.getStationDetail({ stationId: extData.id }).then(res => {
            if (extData.id) {
              this.mapDialogNetwork = true
              this.mapObjNetwork = res.data;
              this.mapObjNetwork.id = extData.id
            }
          })
        }else if(this.tab == '2'){
          cockpitSecondApi.stationDetail(extData.id).then(res => {
            if (extData.id) {
              this.mapDialogLotus = true
              this.mapObjLotus = res.data;
              this.mapObjLotus.id = extData.id
            }
          })
        }else if(this.tab == '3'){
          cockpitApi.selectInfoById({ id: extData.id }).then(res => {
            if (extData.id) {
              this.mapDialogStore = true
              this.mapObjStore = res.data;
              this.mapObjStore.id = extData.id
            }
          })
        }

      }
    },
    setchart1() {
      monitoringLeftApi.getNoticeList().then(res => {
        this.List7 = res.data.pop().list.splice(0, 4)
        let noticePie = []
        var noticeText = []
        res.data.forEach(item => {
          if (item.total > 0) {

            let obj = {
              value: item.total,
              name: item.noticeTypeName
            }
            noticePie.push(obj)
            noticeText.push(item.noticeTypeName)
          }
        })
        let myChart = echarts.init(document.getElementById('two_footer'))
        // 绘制图表
        var option = {
          color: ['#0CC076', '#17D2C7', '#F4BF49', '#F0803F', '#09A52A', '#929090', '#F15665', '#176AD2', '#17A8D2', '#AE56F1', '#E02629'],
          legend: {
            type: 'scroll',
            orient: 'vertical',
            icon: 'circle',
            left: 240,
            top: 0,
            bottom: 20,
            data: noticeText,
            itemHeight: 10,
            textStyle: {
              fontSize: 10,
              color: '#BCCAF3'
            }
          },
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              type: 'pie',
              radius: ['60%', '75%'],
              avoidLabelOverlap: false,
              center: ['100', '85'],
              label: {
                show: false,

              },
              labelLine: {
                show: true
              },
              itemStyle: {
                borderWidth: 0,
                borderColor: '#fff',
                borderRadius: 0,
                emphasis: {
                  scale: false  // 设置为 false，表示在 hover 时大小不改变
                }
              },
              data: noticePie
              // data: [
              // { value: 8, name: '乘车通告' },
              // { value: 12, name: '企业新闻' },
              // { value: 10, name: '重要信息公式' },
              // { value: 7, name: '大事件' },
              // { value: 7, name: '统计资料' },
              // { value: 6, name: '失物招领站' },
              // { value: 6, name: '招标信息' },
              // { value: 13, name: '乘客专区' },
              // { value: 6, name: '员工风采' },
              // { value: 5, name: '旅游北京' },
              // ]
            }
          ]
        };
        myChart.setOption(option);
      })

    },
    //设备寿命统计切换
    dictChange() {
      this.setchart4()
    },
    //分地区场站统计
    stationChange(value) {
    },
    //tiaozhuan
    goHome(path) {
      this.$router.push({
        name: path
      })
    },
    //tuichu
    loginOut() {
      // window.location.href='http://10.56.3.11:11001/cas/logout?service=http://10.170.8.27:8080/casLoginCallbac'
      // window.location.replace('http://10.56.3.11:11001/cas/logout?service=http://10.170.8.27:8080/casLoginCallbac')
      sessionStorage.clear();
      localStorage.clear();
      // loginApi.exit().then((res) => {
      // if (res.code === 200) {
      // this.$message({
      //         message: res.message,
      //         type: "success",
      //         duration: 3000,
      //         customClass: "messageText",
      //     });
      //     sessionStorage.clear()
      //     this.$router.push('/login')
      //     }
      // });
    },
    //获取能源监控总天数
    getTime() {
      const specifiedDate = new Date('2024-06-01');
      const currentDate = new Date();
      const specifiedTimestamp = specifiedDate.getTime();
      const currentTimestamp = currentDate.getTime();
      let millisecondsDiff = currentTimestamp - specifiedTimestamp;
      let daysDiff = Math.max(0, Math.floor(millisecondsDiff / (1000 * 60 * 60 * 24)));
      daysDiff = String(daysDiff).padStart(5, '0');
      this.timeDays = daysDiff.split('').map(Number)
    },
    //地图定位事件
    mapCenter(e) {
      this.mapDialog = false
      let lat = e.latitude
      let lng = e.longitude
      this.map.panTo([lng, Number(lat)])
      let mapObj = {
        name: e.name,
        id: e.id
      }
      if (e.id) {
        cockpitApi.powergenSelectInfoById({id: e.id}).then(res => {
          if (e.id) {
            this.mapDialog = true
            this.mapObj = res.data
            this.mapObj.id = e.id
          }
        })
      }
    },
    mapCenterNetwork(e) {
      this.mapDialogNetwork = false
      let lat = e.latitude
      let lng = e.longitude
      this.map.panTo([lng, Number(lat)])
      let mapObjNetwork = {
        name: e.name,
        id: e.id
      }
      if (e.id) {
        monitoringLeftApi.getStationDetail({ stationId: e.id }).then(res => {
          if (e.id) {
            this.mapDialogNetwork = true
            this.mapObjNetwork = res.data
            this.mapObjNetwork.id = e.id
          }
        })
      }
    },
    mapCenterLotus(e) {
      this.mapDialogLotus = false
      let lat = e.latitude
      let lng = e.longitude
      this.map.panTo([lng, Number(lat)])
      let mapObjLotus = {
        name: e.name,
        id: e.id
      }
      if (e.id) {
        cockpitSecondApi.stationDetail(e.id).then(res => {
          if (e.id) {
            this.mapDialogLotus = true
            this.mapObjLotus = res.data
            this.mapObjLotus.id = e.id
          }
        })
      }
    },
    mapCenterStore(e) {
      this.mapDialogStore = false
      let lat = e.latitude
      let lng = e.longitude
      this.map.panTo([lng, Number(lat)])
      let mapObjStore = {
        name: e.name,
        id: e.id
      }
      if (e.id) {
        cockpitApi.selectInfoById({ id: e.id }).then(res => {
          if (e.id) {
            this.mapDialogStore = true
            this.mapObjStore = res.data
            this.mapObjStore.id = e.id
          }
        })
      }
    },
    //打开场站弹窗
    sxDialogShow() {
      this.sxDialog = !this.sxDialog
      if (!this.sxDialog) {
        this.branchId = null
        this.propertyId = null
      } else {
        this.mapDialog = false
        this.mapDialogNetwork = false
        this.mapDialogLotus = false
        this.mapDialogStore = false
      }
    },
    // 搜索场站
    searchStation() {
      this.stationDialog()
    },
    //聚合能源概况统计
    energyStations() {
      monitoringLeftApi.energyStations().then(res => {
        this.statistics = res.data
        // if (this.List) {
        //   // 修改第一个元素的 grade
        //   if (this.List[0]) {
        //     this.$set(this.List[0], 'grade', this.statistics.companyAmount);
        //   }
        //   // 修改第二个元素的 grade
        //   if (this.List[1]) {
        //     this.$set(this.List[1], 'grade', this.statistics.stationAmount);
        //   }
        // }
        if (this.List2) {
          // 修改第一个元素的 grade
          if (this.List2[0]) {
            this.$set(this.List2[0], 'grade', parseFloat(this.statistics.co2.toFixed(3)));
          }
          // 修改第二个元素的 grade
          if (this.List2[1]) {
            this.$set(this.List2[1], 'grade', parseFloat(this.statistics.equivalentTreePlanting.toFixed(3)));
          }
        }
        if (this.List4) {
          // 修改第一个元素的 grade
          if (this.List4[0]) {
            this.$set(this.List4[0], 'name', this.statistics.yearTotalPower.toFixed(2));
          }
        }
        if (this.List5) {
          // 修改第一个元素的 grade
          if (this.List5[0]) {
            this.$set(this.List5[0], 'name', this.statistics.monthTotalPower.toFixed(2));
          }
        }
        if (this.List6) {
          // 修改第一个元素的 grade
          if (this.List6[0]) {
            this.$set(this.List6[0], 'name', this.statistics.dayTotalPower.toFixed(2));
          }
        }
        // 确保页面已经更新完毕后再进行操作
      })
    },
    //使用字典获取设备寿命情况统计
    queryDictionary() {
      monitoringLeftApi.queryDictionary({dictCode: "position_type"}).then(res => {
        this.dictList = res.data
      })
    },
    currentTime() {
      setInterval(this.getDate, 500)
    },
    getDate() {
      var _this = this
      let yy = new Date().getFullYear()
      let mm = new Date().getMonth() + 1
      let dd = new Date().getDate()
      let week = new Date().getDay()
      let hh = new Date().getHours()
      let ms = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds()
      let mf = new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes()
      if (week == 1) {
        this.nowWeek = "星期一"
      } else if (week == 2) {
        this.nowWeek = "星期二"
      } else if (week == 3) {
        this.nowWeek = "星期三"
      } else if (week == 4) {
        this.nowWeek = "星期四"
      } else if (week == 5) {
        this.nowWeek = "星期五"
      } else if (week == 6) {
        this.nowWeek = "星期六"
      } else {
        this.nowWeek = "星期日"
      }
      _this.nowTime = this.fillZero(hh) + ":" + mf + ":" + ms
      _this.nowDate = this.fillZero(yy) + "年" + this.fillZero(mm) + "月" + this.fillZero(dd) + "日"
    },
    // 给时间补零
    fillZero(str) {
      var realNum
      if (str < 10) {
        realNum = "0" + str
      } else {
        realNum = str
      }
      return realNum
    },

  },
  beforeDestroy() {
    if (this.getDate) {
      clearInterval(this.getDate) // 在Vue实例销毁前，清除时间定时器
    }
  },
}
</script>
<style scoped>
.tabNavBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.1rem;
}
.tabNavBox .nav {
  height: 30px;
  line-height: 30px;
  font-size: 0.18rem;
  flex: 24%;
  max-width: 24%;
  text-align: center;
  color: #AED6FF;
  background-color: rgba(14, 100, 217, 0.11);
}
.tabNavBox .navClick {
  height: 30px;
  line-height: 30px;
  font-size: 0.18rem;
  flex: 24%;
  max-width: 24%;
  text-align: center;
  color: #ffc74a;
  background-color: rgba(14, 100, 217, 0.11);
}
.el-select {
  pointer-events: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pop-ups {
  width: 265px;
  height: 386px;
  background: url("../../assets/images/monitoring/maptc.png") no-repeat center;
  background-size: cover;
  padding-top: 25px;
  box-sizing: border-box;
  position: fixed;
  top: 300px;
  right: 660px;
  z-index: 99999999;
}

.pop-ups p {
  display: inline-block;
}

.dialog-title {
  height: 24px;
  display: flex;
  align-items: center;
  margin: 0 0 0 28px;
}

.dialog-title img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.dialog-title p {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffc74b;
}

.dialog-form {
  display: flex;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-bottom: 9px;
  box-sizing: border-box;
}

.dialog-form p {
  margin: 0;
}

.dialog-form div::after {
  /* content: ":"; */
  margin: 0 3px;
}

.year-electricity {
  width: 212px;
  height: 70px;
  background: url("../../assets/images/electricity/year-background.png") no-repeat center;
  background-size: cover;
  margin: 0 29px 13px 0px;
  position: relative;
}

.year-electricity div {
  position: absolute;
  top: 12px;
  left: 69px;
}

.year-electricity span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #40ecc2;
}

.year-electricity p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
}

.dialog-content {
  height: 220px;
  margin: 21px 0 0 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.dialog-content li {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-bottom: 12px;
}

.dialog-content div::after {
  /* content: ":"; */
  margin: 0 3px;
}

.dialog-content button {
  width: 206px;
  height: 38px;
  background: #3357c4;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  text-align: center;
  line-height: 38px;
  border: 0;
}

.right_nav {
  width: 30px;
  height: 30px;
  background: url('../../assets/images/monitoring/rightNav.png') no-repeat;
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 5.5rem;
  cursor: pointer;
}

.total-electricity {
  position: fixed;
  top: 1.5rem;
  left: 8.2rem;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
}

.total-electricity .text {
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text span {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-left: 11px;
}

.total-electricity img {
  vertical-align: middle;
}

.total {
  display: flex;
  margin-top: 16px;
}

.total li {
  width: 41px;
  height: 72px;
  background: url("../../assets/images/electricity/number-background.png") no-repeat center;
  background-size: cover;
  margin-right: 13px;
  font-size: 50px;
  font-family: DIN;
  font-weight: 400;
  color: #e9f1fa;
  text-align: center;
  line-height: 72px;
}

.total li:last-child {
  margin-right: 0;
}

::v-deep .el-input--suffix .el-input__inner {
  background: transparent !important;
  height: 0.25rem;
  border: none;
  color: #AED6FF;
}

::v-deep .el-input__icon {
  height: 40px;
  position: relative;
  top: -7px;
}

#monitoringLeft {
  /* width: 150vw; */

  background: url('../../assets/images/monitoring/monitoringLeftbg.png') no-repeat;
  height: 1080px;
  background-size: cover;
  position: relative;
}

.left_header {
  width: 100%;
  height: 1.08rem;
  background: url('../../assets/images/monitoring/monitoringLefttop.png') no-repeat;
}

.left_main {
  width: 5.71rem;
  height: 9.36rem;
  background: url('../../assets/images/monitoring/leftMain2.png') no-repeat;
  float: left;
  position: absolute;
  z-index: 9999;
  pointer-events: none;
}

.center_main {
  z-index: 0;
  /* float: left; */
  /* width: 7.5rem; */
  /* height: 6rem; */
  /* width:100%; */
  /* width: 1920px; */
  position: absolute;
  //left: 5.5rem;
  //top: 2.5rem;
  /* height: 1080px; */
  /* border-radius: 6rem; */
}

.main_cz {
  width: 0.52rem;
  height: 0.67rem;
  /* background: url('../../assets/images/monitoring/dtcz.png') no-repeat; */
  position: fixed;
  left: 5.9rem;
  top: 1.58rem;
  cursor: pointer;
  z-index: 9999;
}

.center_main .main_tq {

}

.main_czsx {
  width: 6.9rem;
  height: 7.14rem;
  background: url('../../assets/images/monitoring/czsx.png') no-repeat;
  position: fixed;
  left: 6.6rem;
  top: 1.58rem;
  padding: 0.6rem 0.25rem 0 0.25rem;
  box-sizing: border-box;
  z-index: 9999;
}

.main_czsx .czsx_title {
  margin-bottom: 0.1rem;
  font-size: 14px;
  color: #AED6FF;
}

.main_czsx .czsx_div {
  height: 1.5rem;
  margin-bottom: 0.1rem;
  overflow: auto;
  width: 6.5rem;
}

.czsx_div::-webkit-scrollbar {
  width: 4px;
  background-color: #0A1857;
}

.czsx_div::-webkit-scrollbar-thumb {
  background-color: #003d74;
  border-radius: 5px;
}

.czsx_div::-webkit-scrollbar {
  width: 4px;
  background-color: #0A1857;
}

.czsx_div div {
  width: 1.5rem;
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
  height: 0.3rem;
  line-height: 0.3rem;
  border-radius: 2px;
  font-size: 12px;
  float: left;
  text-align: center;
  color: #AED6FF;
  background-color: rgba(14, 100, 217, 0.11);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* background-color: #0E64D9; */
}

.czsx_title img {
  vertical-align: bottom;
}

.right_main {
  pointer-events: none;
  width: 5.71rem;
  height: 9.36rem;
  background: url('../../assets/images/monitoring/rightMain2.png') no-repeat;
  /* float:right; */
  right: 0;
  position: absolute;
  z-index: 99999;
}

.monitoringFooter {
  height: 1.38rem;
  margin-top: -1rem;
  background: url('../../assets/images/monitoring/monitoringLeftfooter.png') no-repeat;
  position: absolute;
  bottom: 0;
  width: 100%;
  pointer-events: none;
}

.header_left {
  font-size: 12px;
  color: #BCCAF3;
  position: absolute;
  left: 0.5rem;
  top: 0.4rem;
}

.header_right {
  font-size: 12px;
  color: #BCCAF3;
  position: absolute;
  right: 0.5rem;
  top: 0.4rem;
}

.header_left img, .header_right img {
  cursor: pointer;
  vertical-align: bottom;
  margin-right: 0.1rem;
}

.header_left span, .header_right span {
  cursor: pointer;
  margin-right: 0.1rem;
}

.main_one {
  width: 5.71rem;
  height: 4.58rem;
  margin-bottom: 0.17rem;
  overflow: hidden;
  position: relative;
}

.one_div {
  padding-top: 0.73rem;
  padding-left: 0.14rem;
  height: 0.61rem;
  margin-bottom: 0.13rem;
}

.main_one .two_div {
  height: 0.61rem;
  padding-left: 0.14rem;
  margin-bottom: 0.14rem;
}

.main_one .div_main {
  float: left;
  margin-right: 0.25rem;
  width: 1.55rem;
  height: 0.61rem;
  padding-left: 0.7rem;
  padding-top: 0.13rem;
  box-sizing: border-box;
}

.main_one .two_div .div_main {
  width: 1.6rem;
}

.main_two {
  /* pointer-events: auto; */
}

.main_two .div_main {
  font-size: 0.12rem;
  color: #BCCAF3;
}

.main_two .two_div {
  height: 0.32rem;
  width: 3.54rem;
  /* background-color: rgba(12,62,146,.11); */
  padding: 0.06rem 0.25rem 0.13rem 0.22rem;
  box-sizing: border-box;
  //margin-bottom:0.1rem;
}

.three_div {
  height: 0.41rem;
  padding-left: 0.14rem;
  margin-bottom: 0.1rem;
}

.four_div {
  height: 0.35rem;
  padding-left: 0.14rem;
  margin-bottom: 0.2rem;
}

.three_main {
  float: left;
  margin-right: 0.24rem;
}

.four_main {
  float: left;
  margin-right: 0.2rem;
  display: flex;
}

.three_main img, .four_main img {
  float: left;
  margin-right: 0.1rem;
}

.three_main span {
  font-size: 0.12rem;
  color: #D9E3FF;
}

.main_two {
  height: 4.63rem;
  padding-left: 0.14rem;
  padding-top: 0.69rem;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

#two_footer {
  height: 2.04rem;
  width: 5.52rem;
  background: url('../../assets/images/monitoring/xwzxbg.png') no-repeat;
}

#one_echarts {
  width: 5.71rem;
  height: 2.5rem;
  /* width: 390px; */
  /* height: 150px; */
  pointer-events: auto;
}

#two_echarts {
  width: 2.01rem;
  height: 2.04rem;
  margin-left: 1.6rem;
  background: url('../../assets/images/monitoring/pdxsbg.png') no-repeat;
  pointer-events: auto;
}

#three_echarts {
  width: 4.51rem;
  height: 1.7rem;
  margin-left: 1.8rem;

  pointer-events: auto;
}

.backg {
  background: url('../../assets/images/monitoring/byqnxbg.png') no-repeat;
}

.backg1 {
  background: url('../../assets/images/monitoring/GYC.png') no-repeat;
}

.backg2 {
  background: url('../../assets/images/monitoring/BYQ.png') no-repeat;
}

.backg3 {
  background: url('../../assets/images/monitoring/DYC.png') no-repeat;
}

.backg4 {
  background: url('../../assets/images/monitoring/ZLP.png') no-repeat;
}

.backg5 {
  background: url('../../assets/images/monitoring/QT.png') no-repeat;
}

#four_echarts {
  pointer-events: auto;
  width: 5.71rem;
  height: 2rem;
}

.one_title {
  width: 5.71rem;
  margin-left: 1.6rem;
  padding-left: 0.42rem;
  padding-top: 0.08rem;
  font-size: 14px;
  color: #D9E3FF;
  height: 0.32rem;
  background: url('../../assets/images/monitoring/pdxsqktj.png') no-repeat;
  box-sizing: border-box;
}

.search {
  float: right;
  display: flex;
  margin: 7px 0 13px 0;
}

.search :deep() input {
  width: 480px;
  height: 30px;
  background-color: rgba(14, 100, 217, 0.11);
  color: #aed6ff;
  font-family: Microsoft YaHei;
  font-size: 12px;
  border: 0;
  box-sizing: border-box;
}

.search :deep() button {
  width: 75px;
  height: 30px;
  background-color: #176ad2;
  color: #aed6ff;
  font-family: Microsoft YaHei;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  padding: 0;
  border: 0;
  border-radius: 0;
  margin-left: -10px;
}

.screen-subtitle {
  display: flex;
  float: left;
  align-items: center;
  margin: 12px 0 13px 0;
}

.screen-subtitle img {
  width: 20px;
  height: 20px;
}

.main_tq {
  position: fixed;
  right: 5.9rem;
  top: 1.58rem;
}

.tq_feng {
  width: 0.51rem;
  height: 0.67rem;
  background: url('../../assets/images/monitoring/feng.png') no-repeat;
  margin-bottom: 0.1rem;
  position: relative;
}

.tq_feng .feng_name {
  height: 0.14rem;
  font-size: 0.12rem;
  position: absolute;
  bottom: 11px;
  text-align: center;
  width: 100%;
}

.tq_yu {
  width: 0.51rem;
  height: 0.67rem;
  background: url('../../assets/images/monitoring/yu.png') no-repeat;
}

.custom-select >>> .el-select-dropdown {
  background: #041C4C !important;
  border: 1px solid #09295b;
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.hover {
  background: #041C4C !important;
  color: #fff;
}

::v-deep .el-select-dropdown__item {
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.selected {
  color: #fff;
}

::v-deep .el-popper[x-placement^=bottom] .popper__arrow::after {
  top: 0;
  border-bottom-color: #041C4C;
}

.searchText {
  color: #ffc74a;
}

.main_one .boxs {
  position: absolute;
  /* border: 1px solid red; */
  bottom: 2px;
  right: 5px;
  font-size: 12px;
  width: 1.8rem;
  height: 1.84rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
}

.main_one .boxs .box {
  flex: 20%;
  max-height: 20%;
  display: flex;
  align-items: center;
  //margin: 20px 0 20px 0;
}

.main_one .boxs .one {
  width: 0.3rem;
  height: 0.12rem;
  border-radius: 7px;
  margin-right: 0.1rem;
}

.main_one .boxs .two {
  width: 10px;
  height: 12px;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 20px;
  background: red;
}
</style>
