<!-- 能源站点 -->
<template>
  <div id="energy-station">
    <div class="tree">
      <div class="tree-title">组织结构</div>
      <el-input placeholder="输入检索内容" suffix-icon="el-icon-search" v-model.trim="filterText"></el-input>
      <!-- <el-checkbox v-model="allSelect">全选</el-checkbox> -->
      <el-tree ref="tree" v-loading="loading" :default-expand-all="false" :show-checkbox="false" :data="treeData"
               :check-on-click-node="false" :props="defaultProps" node-key="id" :filter-node-method="filterNode"
               @node-click="handleCheck"></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt=""/>
        <span>场站管理</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <el-form-item label="场站名称">
              <el-input placeholder="请输入场站名称" v-model.trim="requestParam.stationName" clearable></el-input>
            </el-form-item>
            <el-form-item label="场站编号">
              <el-input placeholder="请输入场站编号" v-model.trim="requestParam.stationNumber" clearable></el-input>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            <el-button v-if="isButtonPower('createButton')" type="primary" icon="el-icon-plus"
                       @click="createDialog">新建站点
            </el-button>
          </div>
        </div>

        <el-table class="table" :data="tableData">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
                  (requestParam.pageNum - 1) * requestParam.pageSize +
                  scope.$index +
                  1
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="stationName" label="变配电站" width="200">
          </el-table-column>
          <el-table-column align="center" prop="stationNumber" label="站点编号" width="100">
          </el-table-column>
          <el-table-column align="center" prop="companyInfoIdDesc" label="单位" width="200">
          </el-table-column>
          <el-table-column align="center" prop="useName" label="运维单位" width="220px">
            <template slot-scope="scope">
              <span v-for="item in scope.row.staComList" :key="item.id">{{ item.companyName }}, </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="electricPriceName" label="电价类别" width="150">
          </el-table-column>
          <el-table-column align="center" prop="typeDict" label="配电形式" width="125">
          </el-table-column>
          <el-table-column align="center" prop="natureDict" label="场站性质" width="125">
          </el-table-column>
          <el-table-column align="center" prop="landNatureDict" label="土地性质" width="125">
          </el-table-column>
          <el-table-column align="center" prop="buildingNatureDict" label="建筑物性质" width="120px">
          </el-table-column>
          <el-table-column align="center" prop="propertyName" label="产权单位名称" min-width="250px">
          </el-table-column>
          <el-table-column align="center" prop="useName" label="使用单位名称" width="120px">
          </el-table-column>
          <el-table-column align="center" prop="reportNumber" label="申报需量(kVA)" width="120px">
          </el-table-column>
          <el-table-column align="center" prop="powerSupplyVoltageLevelDict" label="供电电压等级(KV)" width="150px">
          </el-table-column>
          <el-table-column align="center" prop="grossRatedCapacity" label="总额定容量(KVA)" width="150px">
          </el-table-column>
          <el-table-column align="center" prop="longitude" label="经度" width="120px">
          </el-table-column>
          <el-table-column align="center" prop="latitude" label="纬度" width="120px">
          </el-table-column>
          <el-table-column align="center" prop="address" label="地址" width="300px">
          </el-table-column>
          <!-- <el-table-column align="center" prop="email4" label="装机容量" >
            </el-table-column> -->

          <!-- <el-table-column align="center" prop="email6" label="变压器数量" width="100" >
            </el-table-column> -->
          <el-table-column align="center" prop="responsibleUserId" label="负责人">
            <template slot-scope="scope">
              <span v-for="item in scope.row.responsibleUserList" :key="item.id">{{
                  item.appellation
                }}({{ item.user_name }}), </span>
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" prop="phone" label="手机号">
          </el-table-column> -->
          <el-table-column align="center" label="操作" prop="operate" width="200" fixed="right">
            <template slot-scope="scope">
              <div class="operate">
                <div>
                  <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
                </div>
                <div v-if="isButtonPower('editButton')">
                  <span>|</span>
                  <el-button type="text" @click="updateDialog(scope.row)">编辑</el-button>
                </div>
                <div v-if="isButtonPower('deleteButton')">
                  <span>|</span>
                  <el-button type="text" @click="deleteDialog(scope.row)">删除</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
                         layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total"
                         background
                         @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
                      @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="viewVisible" width="910px">
      <div class="dialog-content">
        <el-form class="dialog-form" :inline="true" :model="detailsData">
          <el-form-item label="企业" prop="companyInfoId">
            <el-input v-model="detailsData.companyInfoIdDesc" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="站点编号" prop="stationNumber">
            <el-input v-model="detailsData.stationNumber" :readonly="true"></el-input>
          </el-form-item>

          <el-form-item label="变配电站" prop="stationName">
            <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="配电形式" prop="type">
            <el-input v-model="detailsData.typeDict" :readonly="true">
            </el-input>
          </el-form-item>

          <el-form-item label="电价类别" prop="electricPriceId">
            <el-select :disabled="true" v-model="detailsData.electricPriceId" :popper-append-to-body="false" clearable
                       placeholder="请选择电价类别">
              <el-option v-for="item in selectStationPriceList" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="场站性质" prop="nature">
            <el-input v-model="detailsData.natureDict" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="土地性质" prop="landNature">
            <el-input v-model="detailsData.landNatureDict" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="建筑物性质" prop="buildingNature">
            <el-input v-model="detailsData.buildingNatureDict" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item class="special" label="产权单位名称" prop="buildingNature">
            <el-input v-model="detailsData.propertyName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item class="special" label="使用单位名称" prop="buildingNature">
            <el-input v-model="detailsData.useName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="运维单位" prop="staComIds">
            <el-select multiple :disabled="true" v-model="detailsData.staComIds" :popper-append-to-body="false"
                       clearable
                       placeholder="请选择运维单位">
              <el-option v-for="item in comList" :key="item.id" :label="item.companyName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <div class="interval"></div>
          <el-form-item label="区域">
            <el-input class="region" v-model="detailsData.province" :readonly="true">
            </el-input>
            <el-input class="region" v-model="detailsData.city" :readonly="true">
            </el-input>
            <el-input class="region" v-model="detailsData.area" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="地址">
            <el-input class="address" v-model="detailsData.address" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="经度">
            <el-input v-model="detailsData.longitude" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input v-model="detailsData.latitude" :readonly="true"></el-input>
          </el-form-item>

          <div class="interval"></div>
          <el-form-item label="申报需量" prop="reportNumber">
            <el-input v-model="detailsData.reportNumber" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="供电电压等级(KV)" prop="powerSupplyVoltageLevel">
            <el-select :disabled="true" v-model="detailsData.powerSupplyVoltageLevel" :popper-append-to-body="false"
                       clearable
                       placeholder="请选择供电电压等级">
              <el-option v-for="item in voltageLevel" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
            <!-- <el-input v-model.trim="stationData.powerSupplyVoltageLevel" placeholder="请输入申报需量"></el-input> -->
          </el-form-item>
          <el-form-item label="总额定容量(KVA)" prop="grossRatedCapacity">
            <el-input :readonly="true" v-model.trim="detailsData.grossRatedCapacity"
                      placeholder="请输入申报需量"></el-input>
          </el-form-item>
          <el-form-item label="总额定容量(KVA)" prop="grossRatedCapacity">
            <el-input :readonly="true" v-model.trim="detailsData.transformerModel"
                      placeholder="请输入申报需量"></el-input>
          </el-form-item>
          <div class="interval"></div>
          <el-form-item label="负责人">
            <!-- <el-input v-model.trim="stationData.responsibleUserId" placeholder="请输入负责人"></el-input> -->
            <el-select multiple :disabled="true" :popper-append-to-body="false" clearable
                       v-model="detailsData.responsibleUserId"
                       class="myFormItem" placeholder="请选择负责人">
              <el-option v-for="item in userList" :key="item.id" :label="item.userName+' '+ item.appellation"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="pictureUrl" label="图片">
            <el-image style="width: 300px; height: 198px" :src="pictureUrl" fit="cover"
                      :preview-src-list="previewList"></el-image>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="viewVisible = false">取 消</el-button>
        <el-button type="primary" @click="viewVisible = false">确 定</el-button> -->
      </div>
    </el-dialog>
    <el-dialog :title="requestType === 0 ? '新建站点' : '修改站点'" :visible.sync="dialogVisible" width="910px"
               @close="dialogClose('stationForm')">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="stationForm" :inline="true" :model="stationData" :rules="rules">
          <el-form-item label="企业" prop="companyInfoId">
            <!-- <el-select v-model="stationData.companyInfoId" :popper-append-to-body="false" clearable placeholder="请选择企业">
              <el-option v-for="item in enterpriseData" :key="item.id" :label="item.companyName"
                :value="item.id"></el-option>
            </el-select>  -->
            <el-cascader class="mycascader" popper-class="cascader-popper" clearable v-model="stationData.companyInfoId"
                         :options="treeData" :props="hierarchyProps" change-on-select expand-trigger="click"
                         @change="selectedNode"></el-cascader>
          </el-form-item>
          <el-form-item label="站点编号" prop="stationNumber">
            <el-input v-model.trim="stationData.stationNumber" placeholder="请输入站点编号"></el-input>
            <!-- <el-select  @change="stationNumberChange"   v-model="stationData.stationNumber" :popper-append-to-body="false" filterable clearable placeholder="请选择站点编号">
              <el-option v-for="item in stationList" :key="item.stationNumber" :label="item.stationName+' '+item.stationNumber"
                :value="item.stationNumber" :name="item.stationName"></el-option>
            </el-select> -->
          </el-form-item>

          <el-form-item label="变配电站" prop="stationName">
            <el-input v-model.trim="stationData.stationName" placeholder="请输入变配电站"></el-input>
          </el-form-item>
          <el-form-item label="配电形式" prop="type">
            <el-select v-model="stationData.type" :popper-append-to-body="false" clearable placeholder="请选择配电形式">
              <el-option v-for="item in distributingData" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="电价类别" prop="">
            <el-select v-model="stationData.electricPriceId" :popper-append-to-body="false" clearable
                       placeholder="请选择电价类别">
              <el-option v-for="item in selectStationPriceList" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="场站性质" prop="nature">
            <el-select v-model="stationData.nature" :popper-append-to-body="false" clearable
                       placeholder="请选择场站性质">
              <el-option v-for="item in natureData" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="土地性质" prop="landNature">
            <el-select v-model="stationData.landNature" :popper-append-to-body="false" clearable
                       placeholder="请选择土地性质">
              <el-option v-for="item in landNature" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="建筑物性质" prop="buildingNature">
            <el-select v-model="stationData.buildingNature" :popper-append-to-body="false" clearable
                       placeholder="请选择建筑物性质">
              <el-option v-for="item in buildingNature" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="special" label="产权单位名称" prop="propertyName">
            <el-input v-model.trim="stationData.propertyName" placeholder="请输入产权单位名称"></el-input>
          </el-form-item>
          <el-form-item class="special" label="使用单位名称" prop="useName">
            <el-input v-model.trim="stationData.useName" placeholder="请输入使用单位名称"></el-input>
          </el-form-item>
          <el-form-item label="运维单位" prop="staComIds">
            <el-select multiple v-model="stationData.staComIds" :popper-append-to-body="false" clearable
                       placeholder="请选择运维单位">
              <el-option v-for="item in comList" :key="item.id" :label="item.companyName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <div class="interval"></div>

          <el-form-item label="省" prop="provinceId">
            <el-select v-model="stationData.provinceId" :popper-append-to-body="false" clearable
                       placeholder="请选择省" @change="provinceChange">
              <el-option class="region" v-for="item in regionData" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市" prop="cityId">
            <el-select v-model="stationData.cityId" :popper-append-to-body="false" clearable
                       placeholder="请选择市" @change="cityChange">
              <el-option v-for="item in cityData" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区" prop="areaId">
            <el-select :popper-append-to-body="false" v-model="stationData.areaId" clearable
                       placeholder="请选择区">
              <el-option v-for="item in districtData" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!-- </el-form-item> -->
          <el-form-item label="地址" prop="address">
            <el-input class="address" v-model.trim="stationData.address" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <el-form-item label="经度" prop="longitude">
            <el-input v-model.trim="stationData.longitude" placeholder="请输入经度"></el-input>
          </el-form-item>
          <el-form-item label="纬度" prop="latitude">
            <el-input v-model.trim="stationData.latitude" placeholder="请输入纬度"></el-input>
          </el-form-item>
          <div class="interval"></div>
          <el-form-item label="申报需量(kVA)" prop="">
            <el-input v-model.trim="stationData.reportNumber" placeholder="请输入申报需量"></el-input>
          </el-form-item>
          <!--   -->
          <el-form-item label="供电电压等级(KV)" prop="powerSupplyVoltageLevel">
            <el-select v-model="stationData.powerSupplyVoltageLevel" :popper-append-to-body="false" clearable
                       placeholder="请选择供电电压等级">
              <el-option v-for="item in voltageLevel" :key="item.dictValue" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
            <!-- <el-input v-model.trim="stationData.powerSupplyVoltageLevel" placeholder="请输入申报需量"></el-input> -->
          </el-form-item>
          <el-form-item label="总额定容量(KVA)" prop="grossRatedCapacity">
            <el-input v-model.trim="stationData.grossRatedCapacity" placeholder="请输入申报需量"></el-input>
          </el-form-item>
          <el-form-item label="变压器型号">
            <el-input v-model.trim="stationData.transformerModel" placeholder="请输入申报需量"></el-input>
          </el-form-item>
          <div class="interval"></div>
          <!-- <el-form-item label="负责人">
            <el-input v-model.trim="stationData.responsibleUserId" placeholder="请输入负责人"></el-input>
          </el-form-item> -->
          <el-form-item label="负责人" prop="responsibleUserId">
            <!-- <el-input v-model.trim="stationData.responsibleUserId" placeholder="请输入负责人"></el-input> -->
            <el-select multiple :popper-append-to-body="false" clearable v-model="stationData.responsibleUserId"
                       class="myFormItem" placeholder="请选择负责人">
              <el-option v-for="item in userList" :key="item.id" :label="item.userName +' '+ item.appellation"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="联系方式" prop="phone">
            <el-input v-model.trim="stationData.phone" placeholder="请输入联系方式" :maxlength="11"></el-input>
          </el-form-item> -->
          <el-form-item label="图片">
            <el-upload action="" :auto-upload="false" accept="image/*" :limit="1" :file-list="fileList"
                       :on-change="pictureChange" :on-remove="handleRemove" list-type="picture">
              <el-button size="small" type="primary">点击上传</el-button>
              <!-- <i class="el-icon-plus"></i> -->
              <span slot="tip" class="el-upload-tip">
                只能上传jpg/png文件,且不超过5M,建议分辨率900*400
              </span>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm('stationForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该数据吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteEnergyStation">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {energyStationApi} from "@/api/energyStation.js";
import {deviceApi} from "@/api/device.js";
import {getUsersCompany} from "@/api/eios.js"

export default {
  name: "",

  data() {
    return {
      tableData: [],
      stationList: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        stationNumber: "",
        companyInfoId: "",
        stationName: "",
      },
      total: 0,
      inputValue: "",
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      requestType: 0,
      detailsData: {},
      stationData: {
        companyInfoId: "",
        stationNumber: "",
        stationName: "",
        type: "",
        provinceId: "",
        cityId: "",
        areaId: "",
        address: "",
        latitude: "",
        longitude: "",
        reportNumber: "",
        responsibleUserId: [],
        // phone: "",
        nature: "",
        landNature: "",
        buildingNature: "",
        propertyName: "",
        useName: "",
        staComIds: [],
        electricPriceId: '',
        powerSupplyVoltageLevel: '',
        grossRatedCapacity: '',
        transformerModel: '',
      },
      defaultData: {
        companyInfoId: "",
        stationNumber: "",
        stationName: "",
        type: "",
        provinceId: "",
        cityId: "",
        areaId: "",
        address: "",
        latitude: "",
        longitude: "",
        reportNumber: "",
        responsibleUserId: "",
        phone: "",
        nature: "",
        landNature: "",
        buildingNature: "",
        transformerModel: '',
      },
      enterpriseData: [],
      distributingData: [],
      natureData: [],
      landNature: [],
      id: "",
      buildingNature: [],
      regionData: [],
      cityData: [],
      districtData: [],
      voltageLevel: [],
      rules: {
        stationNumber: [
          {required: true, message: "请输入站点编号", trigger: "blur"},
        ],
        stationName: [
          {required: true, message: "请输入变配电站", trigger: "blur"},
        ],
        propertyName: [
          {required: true, message: "请输入产权单位名称", trigger: "blur"},
        ],
        useName: [
          {required: true, message: "请输入使用单位名称", trigger: "blur"},
        ],
        companyInfoId: [
          {required: true, message: "请选择企业", trigger: "change"},
        ],
        type: [
          {required: true, message: "请选择配电形式", trigger: "change"},
        ],
        staComIds: [
          {required: true, message: "请选择运维单位", trigger: "change"},
        ],
        electricPriceId: [
          {required: true, message: "请选择电价类别", trigger: "change"},
        ],
        nature: [
          {required: true, message: "请选择场站性质", trigger: "change"},
        ],
        landNature: [
          {required: true, message: "请选择土地性质", trigger: "change"},
        ],
        buildingNature: [
          {required: true, message: "请选择建筑物性质", trigger: "change"},
        ],
        powerSupplyVoltageLevel: [
          {required: true, message: "请选择供电电压等级", trigger: "change"},
        ],
        responsibleUserId: [
          {required: true, message: "请选择负责人", trigger: "blur"},
        ],
        provinceId: [
          {required: true, message: "请选择省", trigger: "change"},
        ],
        cityId: [
          {required: true, message: "请选择市", trigger: "change"},
        ],
        areaId: [
          {required: true, message: "请选择区", trigger: "change"},
        ],
        address: [
          {required: true, message: "请输入详细地址", trigger: "blur"},
        ],
        reportNumber: [
          {required: true, message: "请输入申报需量", trigger: "blur"},
          {
            pattern: /^[1-9]\d*$/,
            message: "请输入正整数",
            trigger: "blur",
          },
        ],
        grossRatedCapacity: [
          {required: true, message: "请输入总额定容量", trigger: "blur"},
          {
            pattern: /^[1-9]\d*$/,
            message: "请输入正整数",
            trigger: "blur",
          },
        ],
        longitude: [
          {
            pattern: /^-?\d+(\.\d+)?$/,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        latitude: [
          {
            pattern: /^-?\d+(\.\d+)?$/,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        phone: [
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "联系方式格式不正确",
            trigger: "blur",
          },
          // {
          //   pattern: /^\d{11}$/,
          //   message: "联系方式为11位",
          //   trigger: "blur",
          // },
        ],
      },
      fileList: [],
      file: [],
      previewList: [],
      pictureUrl: "",
      checkedNodes: [],
      defaultProps: {
        children: "companyInfoList",
        id: "id",
        label: "companyName",
      },
      treeData: [],
      filterText: "",
      loading: false,
      selectStation: [],
      hierarchyProps: {
        children: "companyInfoList",
        value: "id",
        label: "companyName",
      },
      allButtonPower: [],
      comList: [],
      userList: [],
      selectStationPriceList: [],
    };
  },

  created() {
    this.$store.commit("increment", "Eios控制面板");
    this.$store.commit("selectChild", "场站管理");
    this.$store.commit("selectChildren", "");
    this.allButtonPower = sessionStorage.getItem('roleInfo');
  },
  mounted() {
    this.queryUnitName()
    this.queryEnterprise(); //获取企业
    this.queryRegion(); //获取省市区
    this.queryDistributing(); //获取配电形式
    this.getStationNumber()
    this.queryBuildingNature(); //
    this.queryLandNature(); //
    this.queryNature(); //
    this.getOpeComList(); //获取运维单位
    // this.getUserList(); //获取用户信息
    this.selectStationElePriceList(); //获取电价列表信息
    this.getVoltageLevel()//获取供电电压等级

    // console.log("this.allButtonPower~~~~", this.allButtonPower);
  },

  methods: {
    stationNumberChange(stationName) {
      const selectedStation = this.stationList.find(station => station.stationNumber === stationName);
      console.log(selectedStation);
      this.stationData.stationName = selectedStation.stationName
    },
    //站点下拉查询
    getStationNumber() {
      energyStationApi.getStationNumber().then(res => {
        if (res.code == 200) {
          this.stationList = res.data
        }
      })
    },
    filterNode(value, data) {
      console.log(value,data,'123123')
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },
    //获取父级最后一集
    selectedNode(val) {
      this.stationData.companyInfoId = val[val.length - 1];
      this.setSelectedDetailsNode(this.stationData.companyInfoId)
    },
    //按钮权限
    isButtonPower(code) {
      if (this.allButtonPower) {
        let list = this.allButtonPower.includes(code);
        return list;
      } else {
        return false;
      }
    },
    //获取所有的运维管理
    getOpeComList() {
      energyStationApi.getOpeComList().then(res => {
        this.comList = res.data
      })
    },
    //获取用户列表
    // getUserList() {
    //   energyStationApi.getUserList().then(res => {
    //     this.userList = res.data
    //   })
    // },
    setSelectedDetailsNode(val) {
      console.log(val);
      // this.form.parentId = val[val.length - 1];
      let obj = {
        companyId: val,
        operationFlage: 1,
      }
      getUsersCompany(obj).then(res => {
        this.userList = res.data
      })

      // this.obtainUserList(val,2)

    },
    //获取电价信息列表
    selectStationElePriceList() {
      energyStationApi.selectStationElePriceList().then(res => {
        this.selectStationPriceList = res.data
      })
    },


    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      if (this.requestParam.companyInfoId == checkedNodes.id) {
        this.requestParam.companyInfoId = ''
        const currentNode = this.$refs.tree.$el.querySelector('.is-current');
        if (currentNode) {
          currentNode.classList.remove('is-current');
        }
        this.queryEnergyStation();
      } else {
        this.requestParam.companyInfoId = checkedNodes.id;
        this.queryEnergyStation();
      }
    },
    provinceChange(value) {
      this.stationData.cityId = "";
      this.stationData.areaId = "";
      this.regionData.forEach((item) => {
        if (item.id === value) {
          this.cityData = item.regionEntityList;
        }
      });
      if (!this.stationData.provinceId) {
        this.stationData.cityId = "";
        this.cityData = [];
        this.districtData = [];
        this.stationData.areaId = "";
      }
    },
    cityChange(value) {
      this.stationData.areaId = "";
      this.cityData.forEach((item) => {
        if (item.id === value) {
          this.districtData = item.regionEntityList;
        }
      });
      if (!this.stationData.cityId) {
        this.stationData.areaId = "";
        this.districtData = [];
      }
    },
    dialogClose(formName) {
      this.fileList = [];
      this.previewList = [];
      this.stationData = this.$options.data().stationData
      this.stationData.propertyName = '';
      this.stationData.useName = '';

      // this.$refs[formName].resetFields();
      this.$refs.stationForm.resetFields();
      // this.$refs[formName].clearValidate();
    },
    pictureChange(file, fileList) {
      this.file = fileList.map((item) => item.raw);
      if (this.file.length !== 0) {
        this.$message({
          message: "图片只能上传一张",
          duration: 3000,
          customClass: "messageText",
        });
      }
    },

    createDialog() {
      this.requestType = 0;
      console.log(this.requestParam.companyInfoId, this.stationData.companyInfoId);
      Object.assign(this.stationData, this.defaultData);
      this.stationData.companyInfoId = this.requestParam.companyInfoId
      this.setSelectedDetailsNode(this.stationData.companyInfoId)
      this.dialogVisible = true;
    },
    updateDialog(data) {
      this.requestType = 1;
      this.id = data.id;
      this.queryPictureUrl();
      this.regionData.forEach((item) => {
        if (data.provinceId === item.id) {
          this.cityData = item.regionEntityList;
          item.regionEntityList.forEach((element) => {
            if (element.id === data.cityId) {
              this.districtData = element.regionEntityList;
            }
          });
        }
      });
      const mergedObject = {};
      Object.keys(this.stationData).forEach(key => {
        if (data.hasOwnProperty(key)) {
          mergedObject[key] = data[key] === null ? '' : data[key];
        }
      });
      Object.assign(this.stationData, mergedObject);
      this.stationData.id = data.id
      this.stationData.areaId = data.areaId ? data.areaId : ''
      console.log("~~~~~~", this.stationData);
      this.setSelectedDetailsNode(this.stationData.companyInfoId)
      this.dialogVisible = true;
      if (data.responsibleUserId) {
        this.stationData.responsibleUserId = data.responsibleUserList.map(item => item.id)
      }
      if (data.staComList && data.staComList.length > 0) {
        this.stationData.staComIds = data.staComList.map(item => item.companyId)
      } else {
        this.stationData.staComIds = []
      }
      // delete this.stationData.createTime;
      // delete this.stationData.updateTime;
      // delete this.stationData.useDate;
      // delete this.stationData.staComList;
      // delete this.stationData.responsibleUserList;
      // console.log(object);
    },
    viewDialog(data) {
      Object.assign(this.detailsData, data);
      this.id = data.id;
      if (data.responsibleUserId) {
        this.detailsData.responsibleUserId = data.responsibleUserList.map(item => item.id)
      }
      if (data.staComList && data.staComList.length > 0) {
        this.detailsData.staComIds = data.staComList.map(item => item.companyId)
      }
      this.queryPictureUrl();
      this.regionData.forEach((item) => {
        if (data.provinceId === item.id) {
          this.detailsData.province = item.name;
          item.regionEntityList.forEach((element) => {
            if (element.id === data.cityId) {
              this.detailsData.city = element.name;
              element.regionEntityList.forEach((i) => {
                if (i.id === data.areaId) {
                  this.detailsData.area = i.name;
                }
              });
            }
          });
        }
      });
      this.setSelectedDetailsNode(this.detailsData.companyInfoId)
      this.viewVisible = true;
    },
    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    handleRemove(file, fileList) {
      if (fileList.length === 0) {
        this.file = [];
      } else {
        this.file = fileList.map((item) => item.raw);
      }
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryEnergyStation();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    dialogConfirm(formName) {
      console.log(this.stationData);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.requestType === 0) {
            console.log(this.stationData);

            this.createEnergyStation();
          } else {
            this.updateEnergyStation();
          }
        } else {
          return false;
        }
      });
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
            this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryEnergyStation();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryEnergyStation();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryEnergyStation();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryEnergyStation();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryEnergyStation();
    },
    // 获取区域
    queryRegion() {
      energyStationApi.queryRegion().then((res) => {
        if (res.code === 200) {
          this.regionData = res.data[0].regionEntityList;
        }
      });
    },
    // 构建
    buildTreeData(data) {
      data.forEach((item, index) => {
        if (item.companyInfoList.length !== 0) {
          this.buildTreeData(item.companyInfoList);
        } else {
          let array = [];
          item.stationInfoList.forEach((element) => {
            if (element !== null) {
              array.push({
                id: element.id,
                companyName: element.stationName,
                final: true,
              });
              item.companyInfoList = array;
            }
          });
        }
      });
      return data;
    },
    queryUnitName() {
      this.loading = true;
      deviceApi.queryUnitName().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.queryEnergyStation();
          this.treeData = res.data;
          // this.requestParam.companyInfoId = res.data[0].id;
          // this.$nextTick(() => {
          //   this.$refs.tree.setCurrentKey(this.treeData[0].id); // 默认选中节点第一个

          // });
        }
      });
    },
    // 获取配电形式
    queryDistributing() {
      energyStationApi
          .queryDictionary({dictCode: "station_type"})
          .then((res) => {
            if (res.code === 200) {
              this.distributingData = res.data;
            }
          });
    },
    // 获取场站性质
    queryPictureUrl() {
      energyStationApi.queryPictureUrl({id: this.id}).then((res) => {
        if (res.code === 200) {
          this.pictureUrl = res.data;
          if (this.pictureUrl) {
            this.previewList.push(this.pictureUrl);
          }
          if (this.requestType === 1 && res.data !== null) {
            this.fileList = [{url: res.data}];
          }
        }
      });
    },
    // 获取场站性质
    queryNature() {
      energyStationApi.queryDictionary({dictCode: "nature"}).then((res) => {
        if (res.code === 200) {
          this.natureData = res.data;
        }
      });
    },
    // 获取供电电压等级
    getVoltageLevel() {
      energyStationApi.queryDictionary({dictCode: "power_supply_voltage_level"}).then((res) => {
        if (res.code === 200) {
          this.voltageLevel = res.data;
        }
      });
    },
    // 获取配电形式
    queryLandNature() {
      energyStationApi
          .queryDictionary({dictCode: "land_nature"})
          .then((res) => {
            if (res.code === 200) {
              this.landNature = res.data;
            }
          });
    },
    // 获取配电形式
    queryBuildingNature() {
      energyStationApi
          .queryDictionary({dictCode: "building_nature"})
          .then((res) => {
            if (res.code === 200) {
              this.buildingNature = res.data;
            }
          });
    },
    // 获取企业
    queryEnterprise() {
      energyStationApi.queryEnterprise().then((res) => {
        if (res.code === 200) {
          this.enterpriseData = res.data;
        }
      });
    },
    queryEnergyStation() {
      energyStationApi.queryEnergyStation(this.requestParam).then((res) => {
        if (res.code === 200) {
          // res.data.forEach(element => {
          //   element.region=`${element.}`
          // });
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    createEnergyStation() {
      if (this.file.length !== 0) {
        this.stationData.multipartFiles = this.file[0];
      }
      let obj = {...this.stationData}
      obj.responsibleUserId = this.stationData.responsibleUserId.join(',')
      if (this.stationData.staComIds && this.stationData.staComIds.length > 0) {
        obj.staComIds = this.stationData.staComIds.join(',')
      } else {
        obj.staComIds = ''
      }
      console.log('~~~', obj, this.stationData);
      let formData = new FormData()
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      energyStationApi.createEnergyStation(formData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.dialogVisible = false;
          this.queryEnergyStation();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    updateEnergyStation() {
      this.stationData.id = this.id;
      if (this.fileList !== 0) {
        this.stationData.multipartFiles = this.file[0];
      } else {
        this.stationData.multipartFiles = '';

      }
      let obj = {...this.stationData}
      obj.responsibleUserId = this.stationData.responsibleUserId.join(',')
      if (this.stationData.staComIds && this.stationData.staComIds.length > 0) {
        obj.staComIds = this.stationData.staComIds.join(',')
      } else {
        obj.staComIds = ''
      }
      console.log('~~~', obj, this.stationData);
      let formData = new FormData()
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      energyStationApi.updateEnergyStation(formData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.dialogVisible = false;
          this.queryEnergyStation();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    deleteEnergyStation() {
      energyStationApi.deleteEnergyStation(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryEnergyStation();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#energy-station {
  width: 100%;
  min-height: 781px;
  font-size: 16px;
  display: flex;
}

.main {
  width: calc(100% - 240px);
  background: url("../../assets/images/content-background.png") no-repeat center;
  background-size: cover;
  padding: 22px 0 26px 30px;
  box-sizing: border-box;
}

/* 弹窗 */
.dialog-content {
  margin: 24px 46px 0 48px;
}

.dialog-form :deep() .special .el-form-item__label {
  width: 120px;
  margin-right: 10px;
}

.dialog-form :deep() .el-form-item__label {
  width: 120px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
  box-sizing: border-box;
}

/* input */
.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 277px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .region {
  width: auto;
}

.dialog-form :deep() .region .el-input__inner {
  width: 213px;
  margin-right: 8px;
}

.dialog-form :deep() .region:last-child .el-input__inner {
  margin-right: 0;
}

.dialog-form :deep() .address .el-input__inner {
  width: 655px;
}

.dialog-form :deep() .region:last-child .el-input .el-input__inner {
  margin-right: 0;
}

.interval {
  width: 100%;
  height: 1px;
  background-color: #06224e;
  margin-bottom: 16px;
}

.el-upload-tip {
  color: #e8f4ff;
  font-size: 12px;
  margin-left: 8px;
}
</style>
<style>
.tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
}
</style>
<style>
.cascader-popper {
  background-color: #071734;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.cascader-popper .el-cascader-menu {
  color: #aed6ff;
}

.cascader-popper .el-cascader-node:hover {
  background: #09224b !important;
}

.cascader-popper .el-cascader-node:focus {
  background: #09224b !important;
}

.cascader-popper .popper__arrow::after {
  border-bottom-color: #071734 !important;
}

.cascader-popper {
  width: 406px;
  overflow: auto;
}

.cascader-popper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234e;
  /* 设置滚动条背景色 */
}

.cascader-popper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

.dialog-form :deep() .province {
  display: flex;
}

</style>