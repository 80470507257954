<template>
  <div>
    <div class="content">
      <div class="prompt">
        <img src="../../assets/images/LTicon.png" alt="">
        <div class="promptText">安全管理</div>
      </div>
      <div class="itemStyle">
        <!-- 声纹   btnVoiceprint	http://47.96.191.55:28001 -->
        <div class="">
          <a v-if="authorityListBtn.includes('btnVoiceprintControl')" href="http://121.37.100.100:19101/#/"
             target="_blank">
            <img src="../../assets/images/control/bg1.png" alt="">
          </a>
          <!-- 门禁  btnDoorProhibition 	http://47.96.191.55:11002/-->
          <a v-if="authorityListBtn.includes('btnDoorProhibitionControl')" href="target_blank">
            <img src="../../assets/images/control/bg2.png" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Administration',
  data() {
    return {
      authorityListBtn: ''
    };
  },
  // components:{demo},
  created() {
    this.$store.commit('increment', '安全管理')
    this.$store.commit('selectChild', '')
    this.$store.commit('selectChildren', '')
    sessionStorage.setItem("componentStatus", 0);
    this.$store.commit("updateStatus", 0)
  },
  mounted() {
    // this.authorityListBtn=this.$store.state.control
    console.log('~~~~', sessionStorage.getItem('roleInfo'));
    //sessionStorage.getItem('roleInfo')
    console.log(JSON.parse(sessionStorage.getItem("getControl")));

    this.authorityListBtn = sessionStorage.getItem('roleInfo')
  },
  methods: {},
};
</script>
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  width: 100%;
  height: 100%;
  min-height: 1080px;
  background: url(../../assets/images/rightBG.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;

}

.prompt {
  display: flex;
  padding: 20px;
}

.prompt img {
  padding-right: 10px;
}

.itemStyle {
  display: flex;
  /* text-align: center; */
  padding: 0px 0 0 20px;
}

.itemStyle div {
  padding-right: 50px;
}


</style>