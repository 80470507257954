<template>
  <div id="login">
    <img src="../../assets/images/login/logo.png" alt="" class="logo"/>
    <div class="content">
      <span class="title">用户登录</span>
      <el-form :model="loginForm" ref="loginForm" :rules="rules" class="login-form" :show-message="false">
        <el-form-item prop="userName">
          <el-input v-model.trim="loginForm.userName" placeholder="请输入用户名"></el-input>
          <img src="../../assets/images/login/user-icon.png" alt="" class="prefix-icon"/>
        </el-form-item>
        <el-form-item prop="passWord">
          <el-input v-model.trim="loginForm.passWord" :type="status ? 'text' : 'password'" placeholder="请输入密码"
                    maxlength="12"></el-input>
          <img src="../../assets/images/login/password-icon.png" alt="" class="prefix-icon"/>
          <div class="suffix-icon" @click="switchStatus">
            <img v-show="!status" src="../../assets/images/login/eyes-close.png" alt=""/>
            <img v-show="status" src="../../assets/images/login/eyes-open.png" alt=""/>
          </div>
        </el-form-item>

        <el-form-item prop="code">
          <el-input placeholder="请输入验证码" @keydown.enter.stop="handleEnterKey" v-model="loginForm.code"
                    style="width: 223px">
          </el-input>
          <img src="../../assets/images/login/verification-icon.png" alt="" class="prefix-icon"/>
          <img :src="this.captchaBase" alt="" class="verification" @click="getCaptcha"/>
        </el-form-item>
        <el-form-item>
          <button class="login-submit" @click.prevent="loginSubmit('loginForm')">
            登录
          </button>
        </el-form-item>
      </el-form>
    </div>
    <div style="font-size: 12px;position: absolute;bottom: 0;">
      <a class="bah" href="https://beian.miit.gov.cn/">备案号:京ICP备20029225号</a>
    </div>
  </div>
</template>
<script>
import {loginApi} from "@/api/login.js";
import {v4 as uuid} from "uuid";

export default {
  data() {
    return {
      loginForm: {
        userName: "",
        passWord: "",
        code: "",
      },
      status: false,
      captchaBase: "",
      captchaInterval: null,
      uuidCode: "",
      rules: {
        userName: [
          {required: true, message: "请输入用户名", trigger: "blur"},
        ],
        passWord: [{required: true, message: "请输入密码", trigger: "blur"}],
        code: [{required: true, message: "请输入验证码", trigger: "blur"}],
      },
    };
  },
  mounted() {
    if (window.location.search.split('=')[1]) {
      let ticket = window.location.search.split('=')[1]
      console.log('ticket', ticket);
      // console.log('ticket:' +  window.location.search)
      this.ticketLogin(ticket)
    } else {
      this.getCaptcha();//获取验证码
    }

    // this.$route.query.ticket
    // if(this.$route.query.ticket){
    //   let ticket = this.$route.query.ticket
    //   console.log('ticket ~~~~',ticket);
    // }else{
    //   console.log(22222);
    // }

  },
  methods: {
    ticketLogin(ticket) {
      loginApi.ticketLogin({ticket: ticket}).then(r => {
        // if(r.code==200){
        let res = r
        res = JSON.stringify(res)
        res = JSON.parse(res)
        sessionStorage.setItem('roleInfo', JSON.stringify(res.data.permissions))
        res.data.menuList.forEach(item => {
          if (item.url == "Control") {
            //安全管理特殊处理
            // console.log('getControl~~~~~~~~~~~~~',item);
            sessionStorage.setItem("getControl", JSON.stringify(item));
            this.$store.commit("getControl", item);
          }
          if (item.url == "Document") {
            //日常办公判断是否有消息管理权限
            // /dailyWork/message
            if (item.children.length > 0) {

              let arrList = item.children.map(arr => arr.url)
              if (arrList.includes('/dailyWork/message')) {
                sessionStorage.setItem("message", 0);
              } else {
                sessionStorage.setItem("message", 1);
              }
            } else {
              sessionStorage.setItem("message", 1);
            }
          }
          //驾驶舱电判断页面顶部的按钮跳转
          if (item.url == "/electricityHome") {
            sessionStorage.setItem("electricityHome", JSON.stringify(item));
          }
        })
        sessionStorage.setItem("lc_token", res.data.token);
        let authorityBtnList = res.data.menuList
        // console.log("~~~~~~~~~!!!!!!!!!!~~~~~~~", authorityBtnList);
        sessionStorage.setItem(
            "menu_list",
            JSON.stringify(authorityBtnList)
        );
        sessionStorage.setItem(
            "user_info",
            JSON.stringify(res.data.userInfo)
        );
        this.$store.commit("updateMenuList", authorityBtnList);
        this.$store.commit("updateUserInfo", res.data.userInfo);
        this.$router.push("/");
        // }
        // else{
        //   localStorage.clear();

        // }


      })
    },
    switchStatus() {
      this.status = !this.status;
    },
    handleEnterKey() {
      this.loginSubmit('loginForm')
    },
    loginSubmit(formName) {
      this.$refs[formName].validate((valid, object) => {
        if (valid) {
          this.loginForm.uuid = this.uuidCode;
          loginApi.login(this.loginForm).then((r) => {
            if (r.code === 200) {
              this.$message({
                message: "登录成功",
                type: "success",
                duration: 3000,
                customClass: "messageText",
              });
              sessionStorage.setItem('token', r.data.token)
              let res = r
              res = JSON.stringify(res)
              res = JSON.parse(res)
              sessionStorage.setItem('roleInfo', JSON.stringify(res.data.permissions))
              res.data.menuList.forEach(item => {
                if (item.menuName == "Control") {
                  //安全管理特殊处理
                  // console.log('getControl~~~~~~~~~~~~~',item);
                  sessionStorage.setItem("getControl", JSON.stringify(item));
                  this.$store.commit("getControl", item);
                }
                console.log("222qqq", item.children);
                if (item.name == "Document") {
                  //日常办公判断是否有消息管理权限
                  // /dailyWork/message

                  if (item.children.length > 0) {
                    let arrList = item.children.map(arr => arr.url)
                    if (arrList.includes('/dailyWork/message')) {
                      sessionStorage.setItem("message", 0);
                    } else {
                      sessionStorage.setItem("message", 1);
                    }
                  } else {
                    sessionStorage.setItem("message", 1);
                  }
                }
                //驾驶舱电判断页面顶部的按钮跳转
                if (item.menuName == "驾驶舱-网") {
                  sessionStorage.setItem("electricityHome", JSON.stringify(item));
                }
              })
              sessionStorage.setItem("lc_token", res.data.token);
              let authorityBtnList = res.data.menuList
              // console.log("~~~~~~~~~!!!!!!!!!!~~~~~~~", authorityBtnList);
              sessionStorage.setItem(
                  "menu_list",
                  JSON.stringify(authorityBtnList)
              );
              sessionStorage.setItem(
                  "user_info",
                  JSON.stringify(res.data.userInfo)
              );
              this.$store.commit("updateMenuList", authorityBtnList);
              this.$store.commit("updateUserInfo", res.data.userInfo);
              this.$router.push("/");
            } else {
              this.getCaptcha()
              this.loginForm.code = ''
              this.$message({
                message: r.message,
                type: "error",
                duration: 3000,
                customClass: "messageText",
              });

            }

            //  this.stopInterval()
          });
        } else {
          if (object.userName) {
            let error = "请输入用户名";
            this.$message({
              message: error,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });
            return false;
          }
          if (object.passWord) {
            let error = "请输入密码";
            this.$message({
              message: error,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });
            return false;
          }
          if (object.code) {
            let error = "请输入验证码";
            this.$message({
              message: error,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });
            return false;
          }
        }
      });
    },
    // loginSubmit(formName) {
    //   this.$refs[formName].validate((valid, object) => {
    //     if (valid) {
    //       this.loginForm.uuid = this.uuidCode;
    //       loginApi.login(this.loginForm).then((res) => {
    //         if (res.code === 200) {
    //           this.$message({
    //             message: "登录成功",
    //             type: "success",
    //             duration: 3000,
    //             customClass: "messageText",
    //           });
    //           res.data.menuList.forEach(item=>{
    //             if(item.code=="Control"){
    //               //安全管理特殊处理
    //               // console.log('getControl~~~~~~~~~~~~~',item);
    //               sessionStorage.setItem("getControl", JSON.stringify(item));
    //               this.$store.commit("getControl", item);
    //             }
    //             if(item.code=="Document"){
    //               //日常办公判断是否有消息管理权限
    //               // /dailyWork/message

    //               if(item.children.length  > 0){
    //                 let arrList=item.children.map(arr=>arr.url)
    //                 if(arrList.includes('/dailyWork/message')){
    //                   sessionStorage.setItem("message", 0);
    //                   }else{
    //                   sessionStorage.setItem("message", 1);
    //                 }
    //               }else{
    //                 sessionStorage.setItem("message", 1);

    //               }

    //             }
    //           })
    //           sessionStorage.setItem("lc_token", res.data.token);
    //           let authorityBtnList = this.transformData(JSON.parse(JSON.stringify( res.data.menuList)))
    //           // console.log("~~~~~~~~~!!!!!!!!!!~~~~~~~", authorityBtnList);
    //           sessionStorage.setItem(
    //             "menu_list",
    //             JSON.stringify(authorityBtnList)
    //           );
    //           sessionStorage.setItem(
    //             "user_info",
    //             JSON.stringify(res.data.userInfo)
    //           );
    //           this.$store.commit("updateMenuList", authorityBtnList);
    //           this.$store.commit("updateUserInfo", res.data.userInfo);
    //           this.$router.push("/");
    //         } else {
    //           this.getCaptcha()
    //           this.loginForm.code = ''
    //           this.$message({
    //             message: res.message,
    //             type: "error",
    //             duration: 3000,
    //             customClass: "messageText",
    //           });

    //         }

    //         //  this.stopInterval()
    //       });
    //     } else {
    //       if (object.userName) {
    //         let error = "请输入用户名";
    //         this.$message({
    //           message: error,
    //           type: "error",
    //           duration: 3000,
    //           customClass: "messageText",
    //         });
    //         return false;
    //       }
    //       if (object.passWord) {
    //         let error = "请输入密码";
    //         this.$message({
    //           message: error,
    //           type: "error",
    //           duration: 3000,
    //           customClass: "messageText",
    //         });
    //         return false;
    //       }
    //       if (object.code) {
    //         let error = "请输入验证码";
    //         this.$message({
    //           message: error,
    //           type: "error",
    //           duration: 3000,
    //           customClass: "messageText",
    //         });
    //         return false;
    //       }
    //     }
    //   });
    // },
    getCaptcha() {
      this.uuidCode = uuid();
      loginApi.getCaptcha({uuid: this.uuidCode}).then((res) => {
        this.captchaBase = window.URL.createObjectURL(
            new Blob([res], {type: "image/png"})
        );
        // this.beginInterval()
      });
    },
    beginInterval() {
      if (this.captchaInterval === null) {
        this.captchaInterval = setInterval(this.getCaptcha, 2000);
      }
    },
    stopInterval() {
      if (this.captchaInterval != null) {
        clearInterval(this.captchaInterval);
        this.captchaInterval = null;
      }
    },
    transformData(data) {
      data.forEach(item => {
        if (item.children && item.children.length > 0) {
          const type2Menus = item.children.filter(child => {
            if (child.type === 2) {
              return child
            }
          });
          console.log(type2Menus);
          if (type2Menus.length > 0) {
            item.authority = []
            type2Menus.reduce((acc, cur) => {
              if (cur.children && cur.children.length > 0) {
                acc.push(...cur.filter(child => child.code).map(child => child.code));
              }
              // console.log("~~~!!@@",acc,cur);
              item.authority.push(cur.code)
              // return acc;
            }, item.authority || []);
            item.children = item.children.filter(child => child.type !== 2);
            // console.log("2222333",item.authority);
          }
        }
        if (item.children && item.children.length > 0) {
          this.transformData(item.children);
        }
      });
      // console.log("@#$#%$@$@@~~~~~~~",data);
      return data;
    }
  },
};
</script>
<style scoped>
.bah {
  color: #bbb;
  text-decoration: none;
}
.bah:hover {
  color: white;
}
#login {
  width: 100%;
  height: 100vh;
  background: url(../../assets/images/login/background.jpg) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 4.49rem;
}

.content {
  width: 10.38rem;
  height: 5.25rem;
  background: url(../../assets/images/login/form.png) no-repeat center;
  background-size: cover;
  margin-top: 0.4rem;
  position: relative;
}

.title {
  font-size: 0.26rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f1f9ff;
  position: absolute;
  right: 2.02rem;
  top: 0.72rem;
}

.login-form {
  width: 3.43rem;
  position: absolute;
  right: 0.82rem;
  top: 1.32rem;
}

.login-form :deep() .el-input__inner {
  background-color: rgba(37, 79, 184, 0.11);
  border: 0;
  height: 0.57rem;
  color: #b7c8ff;
  font-size: 0.16rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  padding: 0 0.6rem;
  position: relative;
}

.prefix-icon {
  width: 0.21rem;
  position: absolute;
  top: 0.18rem;
  left: 0.16rem;
}

.suffix-icon {
  line-height: 0;
  position: absolute;
  right: 0.23rem;
  top: 0.18rem;
}

.suffix-icon img {
  width: 0.2rem;
}

.verification {
  width: 1.04rem;
  position: absolute;
  top: 0;
  right: 0;
}

.login-submit {
  width: 100%;
  height: 0.6rem;
  background: #122b7d;
  border-radius: 0.06rem;
  border: 0;
  font-size: 0.2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #e7e9fe;
  line-height: 0.6rem;
}

.login-form :deep() .el-input__inner:-webkit-autofill {
  -webkit-box-shadow: 0px 0px 0px 30px #0e153e inset;
  -webkit-text-fill-color: #b7c8ff;
  border-radius: 0;
  /* width: 240px; */
  padding-left: 46px;
}

.login-form :deep() .el-input input::placeholder {
  font-size: 0.16rem;
  color: #6180a8;
}
</style>
