<!-- 分时段用电 -->
<template>
  <div id="day-parting">
    <div class="tree">
      <div class="tree-title">组织结构</div>
      <el-input placeholder="输入检索内容" suffix-icon="el-icon-search" v-model.trim="filterText"></el-input>
      <el-tree ref="tree" v-loading="loading" :default-expand-all="false" :show-checkbox="false" :data="treeData"
               :check-on-click-node="false" :props="defaultProps" node-key="id" :filter-node-method="filterNode"
               :default-expanded-keys="treeChildren"
               @node-click="handleCheck"></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt=""/>
        <span>国网计量统计</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="日期:" class="query-date">
              <el-date-picker
                  :clearable="false"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd"
                  @change="timeChange()"
                  :picker-options="pickerOptions"
                  :append-to-body="false"
                  v-model="formData.time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="计量点账户" label-width="70px">
              <el-select v-model="valueP" :popper-append-to-body="false" placeholder="请选择" value-key="id"
                         @change="selectChange()">
                <el-option
                    v-for="item in optionsP"
                    :key="item.id"
                    :label="item.userName"
                    :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <!--            <el-form-item label="计量点名称" label-width="70px">-->
            <!--              <el-select v-model="valueH" filterable :popper-append-to-body="false" clearable placeholder="请选择"-->
            <!--                         @change="selectChangeTwo()" value-key="id" @clear="clearSelect2">-->
            <!--                <el-option-->
            <!--                    v-for="(item,index) in optionsH"-->
            <!--                    :key="item.id"-->
            <!--                    :label="item.userName"-->
            <!--                    :value="item">-->
            <!--                </el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="searchList">搜索</el-button>
              <el-button type="primary" @click="getExport()">导出</el-button>
              <!-- <el-button type="primary" icon="el-icon-search">图表</el-button> -->
            </el-form-item>
          </el-form>
        </div>
        <el-table class="table" :data="tableData" @selection-change="handleSelectionChange">
          <!--          <el-table-column type="selection" width="50"></el-table-column>-->
          <el-table-column align="center" min-width="200" prop="ts" label="时间">
            <template slot-scope="scope">
              <span>{{formateDate(scope.row.ts)}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="200" prop="ae" label="正向有功电能示值">
          </el-table-column>
          <el-table-column align="center" min-width="200" prop="re" label="正向无功电能示值">
          </el-table-column>
          <el-table-column align="center" label="尖">
            <el-table-column align="center" prop="aeP" label="正向有功电能示值" width="150">
              <template slot-scope="scope">
                <!--                <span style="color: white;">{{ truncateNumber(scope.row.aeP, 2) }}</span>-->
                <span>{{ truncateNumber(scope.row.aeP, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="reP" label="正向无功电能示值" width="150">
              <template slot-scope="scope">
                <!--                <span style="color: rgb(19, 206, 102);">{{ truncateNumber(scope.row.reP, 2) }}</span>-->
                <span>{{ truncateNumber(scope.row.reP, 2) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="峰">
            <el-table-column align="center" prop="aeF" label="正向有功电能示值" width="150">
              <template slot-scope="scope">
                <!--                <span style="color: white;">{{ truncateNumber(scope.row.aeF, 2) }}</span>-->
                <span>{{ truncateNumber(scope.row.aeF, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="reF" label="正向无功电能示值" width="150">
              <template slot-scope="scope">
                <!-- <span style="color: rgb(19, 206, 102);">{{ scope.row.peakPowerPrice }}</span> -->
                <span>{{ truncateNumber(scope.row.reF, 2) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="平">
            <el-table-column align="center" prop="aeN" label="正向有功电能示值" width="150">
              <template slot-scope="scope">
                <!--                <span style="color: white;">{{ truncateNumber(scope.row.flatPower, 2) }}</span>-->
                <span>{{ truncateNumber(scope.row.aeN, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="reN" label="正向无功电能示值" width="150">
              <template slot-scope="scope">
                <!-- <span style="color: rgb(19, 206, 102);">{{ scope.row.reN }}</span> -->
                <span>{{ scope.row.reN }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="谷">
            <el-table-column align="center" prop="aeV" label="正向有功电能示值" width="150">
              <template slot-scope="scope">
                <!--                <span style="color: white;">{{ truncateNumber(scope.row.valleyPower, 2) }}</span>-->
                <span>{{ truncateNumber(scope.row.aeV, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="reV" label="正向无功电能示值" width="150">
              <template slot-scope="scope">
                <!-- <span style="color: rgb(19, 206, 102);">{{ scope.row.reV }}</span> -->
                <span>{{ scope.row.reV }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="formData.pageNum" :page-sizes="[10, 20, 30, 40]"
                         layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total"
                         background
                         @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model.number="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
                      @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {emissionsCarbonApi} from '@/api/emissionsCarbon.js'
import {deviceApi} from "@/api/device";
import moment from "moment/moment";
import download from "@/utils/download";
// import { time } from 'echarts';
export default {
  name: "",
  data() {
    return {
      treeChildren: [],
      pickerOptions: {
        onPick: obj => {
          this.pickTimeSave = new Date(obj.minDate).getTime()
          const start = obj.minDate
          const end = obj.maxDate
          obj.maxDate && (this.formData.time = [start, end])
        },
        disabledDate: time => {
          if (this.pickTimeSave) {
            let min = moment(this.pickTimeSave).subtract(1, 'months').format('x')
            let max = moment(this.pickTimeSave).clone().add(1, 'months').format('x')
            return Number(min) > Number(time.getTime()) || Number(time.getTime()) > Number(max)
          }
        }
      },
      pickTimeSave: '',
      loading: false,
      defaultProps: {
        children: "companyInfoList",
        id: "id",
        label: "companyName",
      },
      filterText: '',
      treeData: [],
      checkedNodes: [],
      valueP: '',
      optionsP: [],
      valueH: '',
      optionsH: [],
      tableSelectData: [],
      inputValue: '',
      total: 0,
      formData: {
        companyInfoId: '',
        time: null,
        stTime: null,
        enTime: null,
        deviceNumber: null,
        parentDeviceNumber: null,
        pageNum: 1,
        pageSize: 20,
      },
      tableData: [],
      select: 0,
      arrList: []
    };
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.$store.commit('increment', '用电分析')
    this.$store.commit('selectChild', '国网计量统计')
    this.$store.commit("selectChildren", "");
    let start = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
    let end = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.formData.time = [start, end];
    this.formData.stTime = start
    this.formData.enTime = end
  },
  mounted() {
    this.queryUnitName()
  },
  methods: {
    formateDate(val){
      return moment(val).format('YYYY-MM-DD HH:mm:ss');
    },
    getExport() {
      if (this.formData.deviceNumber == '' || this.formData.deviceNumber == null || this.formData.deviceNumber == undefined) {
        this.$message({
          message: '请选择计量点账户',
          type: 'warning',
          duration: 3000,
          customClass: "messageText",
        });
        return
      }

      const param = {
        companyInfoId: this.formData.companyInfoId,
        stTime: this.formData.stTime,
        enTime: this.formData.enTime,
        parentDeviceNumber: this.formData.parentDeviceNumber,
        deviceNumber: this.formData.deviceNumber,
        pageNum: this.formData.pageNum,
        pageSize: this.formData.pageSize,
      }
      deviceApi.queryExport(param).then(res => {
        let name = '计量统计' + moment(this.formData.stTime).format('YYYY-MM-DD') + '至' + moment(this.formData.enTime).format('YYYY-MM-DD')
        download.excel(res, name)
        this.$message({
          message: '导出成功',
          type: 'success',
          duration: 3000,
          customClass: "messageText",
        });
      })
    },
    selectChange(val) {
      this.formData.deviceNumber = this.valueP.deviceNumber
      this.formData.parentDeviceNumber = this.valueP.parentDeviceNumber
    },
    selectChangeTwo() {
      this.formData.deviceNumber = this.valueH.deviceNumber
    },
    clearSelect() {
      this.formData.deviceNumber = null
      this.formData.parentDeviceNumber = null
      this.valueP = {}
    },
    clearSelect2() {
      this.formData.deviceNumber = null
      this.valueH = {}
    },
    queryUser(id) {
      this.optionsP = []
      const param = {
        companyInfoId: id,
      }
      deviceApi.queryOptionsOne(param).then((res) => {
        if (res.code === 200) {
          if (res.data.length != 0) {
            this.optionsP = res.data
            this.valueP = this.optionsP[0]
            this.formData.deviceNumber = this.valueP.deviceNumber
            this.formData.parentDeviceNumber = this.valueP.parentDeviceNumber
            this.getPeriodList();
          }else {
            this.optionsP = res.data
            this.valueP = {}
            this.formData.deviceNumber = null
            this.formData.parentDeviceNumber = null
            this.getPeriodList();
          }
        }
      });
    },
    timeChange() {
      if (this.formData.time == '' || this.formData.time == null || this.formData.time == undefined) {
        this.formData.stTime = null
        this.formData.enTime = null
      } else {
        this.formData.stTime = this.formData.time[0]
        this.formData.enTime = this.formData.time[1]
      }
    },
    queryUnitName() {
      this.loading = true;
      deviceApi.queryUnitName().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.treeData = res.data;
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.treeData[0].companyInfoList[0].id); // 默认选中节点第一个
            this.treeChildren = [this.treeData[0].id]
            this.formData.companyInfoId = this.treeData[0].companyInfoList[0].id
            this.queryUser(this.formData.companyInfoId)
          });
        }
      });
    },
    handleCheck(checkedNodes, event) {
      this.valueP = {}
      this.valueH = {}
      this.optionsH = []
      this.formData.deviceNumber = null
      this.formData.parentDeviceNumber = null
      if (this.checkedNodes.id == checkedNodes.id) {
        this.checkedNodes = ''
        this.formData.companyInfoId = checkedNodes.id
        this.queryUser(checkedNodes.id)
        this.getPeriodList()
      } else {
        this.formData.companyInfoId = checkedNodes.id
        this.checkedNodes = checkedNodes;
        this.queryUser(checkedNodes.id)
        this.getPeriodList()
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },
    handleSelectionChange(val) {
      this.tableSelectData = val;
      let arr = []
      this.tableSelectData.forEach((item, index) => {
        arr.push(item.ts)
      })
      this.arrList = arr
    },
    searchList() {
      this.formData.pageNum = 1;
      this.getPeriodList()
    },
    truncateNumber(value, decimalPlaces) {
      value = parseFloat(value);
      // 检查转换后的值是否为有效数字
      if (isNaN(value)) return '';
      if (value === 0) return '0';
      // 将数值转换为字符串，然后根据指定位数保留小数
      if (decimalPlaces === 4) {
        return 0
        // return value.toFixed(4);
      } else if (decimalPlaces === 2) {
        return value.toFixed(2);
      } else {
        return value.toString().slice(0, value.toString().indexOf('.') + 5);
      }
    },
    getPeriodList() {
      let obj = {
        companyInfoId: this.formData.companyInfoId,
        stTime: this.formData.stTime,
        enTime: this.formData.enTime,
        parentDeviceNumber: this.formData.parentDeviceNumber,
        deviceNumber: this.formData.deviceNumber,
        pageNum: this.formData.pageNum,
        pageSize: this.formData.pageSize,
      }
      deviceApi.queryMeteringPointData(obj).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    //分页
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.formData.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
            this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.formData.pageNum = 1;
      this.getPeriodList();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.formData.pageSize);
      this.formData.pageNum = lastPage;
      this.getPeriodList();
    },
    confirm() {
      this.formData.pageNum = this.inputValue;
      this.getPeriodList();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.formData.pageSize = pageSize;
      this.getPeriodList();
    },
    changeCurrent(pageNum) {
      this.formData.pageNum = pageNum;
      this.getPeriodList();
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 240px;
  background-color: #061b45;
  color: #aed6ff;
  border: 1px solid #09295b;
}

::v-deep .el-date-editor .el-range-input {
  background-color: #061b45;
}

::v-deep .el-date-editor .el-range-separator {
  color: #aed6ff;
  width: 10%;
}

::v-deep .el-date-table td.in-range div, .el-date-table td.in-range div:hover, .el-date-table.is-week-mode .el-date-table__row.current div, .el-date-table.is-week-mode .el-date-table__row:hover div {
  background-color: transparent;
}

#day-parting {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

/* tree */
.tree :deep() .el-tree {
  width: 220px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  //padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #03183f;
}

.main {
  min-width: 1280px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  margin: 18px 0 20px 0;
}

.query-form :deep() .el-form {
  height: 40px;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0;
  margin-right: 8px;
}

/* .form :deep().el-date-editor {
  width: 132px;
} */

.hour :deep() .el-select {
  width: 56px;
  padding: 0;
}

.hour span {
  color: #aed6ff;
  margin: 0 5px 0 8px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
  height: 40px;
  width: 100%;
}


.tip {
  color: #e8f4ff;
  font-size: 12px;
  margin-left: 10px;
}

/* 表格 */

/* 设置表头的背景颜色 */
.table :deep() thead.is-group th.el-table__cell {
  background: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() .el-table__cell {
  padding: 4px 0;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep .el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid rgb(19, 43, 83) !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}

/* 滚动条样式 */

/* 更改 el-table 横向滚动条的样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234e;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

::v-deep .el-table__body-wrapper {
  height: 72vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
